export const initialDoorData = {
	type: '',
	otherTypeDescription: '',
	width: '',
	height: '',
	costPrice: '',
	sellPrice: '',
	colour: '',
	no_doors: "",
	customerDoorRefList: []
}
