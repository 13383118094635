import {
	TOGGLE_LINEITME_RFA,
	TOGGLE_LINEITEMS_RFA_FOR_ONE_JOB,
	SET_LINEITEMS_FULL_IDS_FOR_ALL_JOBS
} from "context/actionTypes"

const ApplicationEligibleReducer = (state, action) => {

	const add = (pre, curr) => pre + curr

	switch (action.type) {
		// Set lineItemsFullIds for all jobs when page is loaded
		case SET_LINEITEMS_FULL_IDS_FOR_ALL_JOBS:
			state.lineItemsReadyForApplication = []
			action.payload.forEach(job => {
				state.lineItemsReadyForApplication.push({
					jobId: job.id.value,
					allSelected: true,
					lineItemsFullIds: job.lineItemsReadyForApplication.filter(lineItem => lineItem.isAlreadyApplied === false).map(lineItem => lineItem.fullId.asString)
				})
			})
			const jobsSO = action.payload.filter(job => job.areaCode === "SO")
			const jobsSOAlreadyAppliedButChanged= jobsSO.length ? jobsSO.map(
						job=>job.lineItemsReadyForApplication
								.filter(item => item.isAlreadyApplied)
								.map(lineItem => (lineItem.applicationValue-lineItem.previousApplicationValue))
								.reduce(add,0)).reduce(add,0)
								: 0
			state.SoTotalPrice = jobsSO.length > 0 ?
				jobsSO.map(job =>
					job.lineItemsReadyForApplication.filter(item => item.isAlreadyApplied === false).map(lineItem => lineItem.applicationValue).reduce(add,0)
				).reduce(add,jobsSOAlreadyAppliedButChanged) : 0

			const jobsNotSO = action.payload.filter(job => job.areaCode !== "SO")
			const jobsNotSOAlreadyAppliedButChanged= jobsNotSO.length ? jobsNotSO.map(
						job=>job.lineItemsReadyForApplication
								.filter(item => item.isAlreadyApplied)
								.map(lineItem => (lineItem.applicationValue-lineItem.previousApplicationValue))
								.reduce(add,0)).reduce(add,0)
								: 0
			state.NonSoTotalPrice = jobsNotSO.length > 0 ?
				jobsNotSO.map(job =>
					job.lineItemsReadyForApplication.filter(item => item.isAlreadyApplied === false).map(lineItem => lineItem.applicationValue).reduce(add,0)
				).reduce(add, jobsNotSOAlreadyAppliedButChanged) : 0
			return {
				...state
			}
		// Add Or Remove one lineItemFullId
		case TOGGLE_LINEITME_RFA:
			let jobLineItemsRFA = state.lineItemsReadyForApplication.find(item => item.jobId === action.payload.jobId).lineItemsFullIds
			if (jobLineItemsRFA.includes(action.payload.lineItemFullId)) {
				jobLineItemsRFA = jobLineItemsRFA.filter(item => item !== action.payload.lineItemFullId)
				state.lineItemsReadyForApplication.find(item => item.jobId === action.payload.jobId).lineItemsFullIds = jobLineItemsRFA
				if (action.payload.jobAreaCode === "SO") {
					state.SoTotalPrice -= action.payload.applicationValue
				} else {
					state.NonSoTotalPrice -= action.payload.applicationValue
				}
			} else {
				state.lineItemsReadyForApplication.find(item => item.jobId === action.payload.jobId).lineItemsFullIds.push(action.payload.lineItemFullId)
				if (action.payload.jobAreaCode === "SO") {
					state.SoTotalPrice += action.payload.applicationValue
				} else {
					state.NonSoTotalPrice += action.payload.applicationValue
				}
			}
			return {
				...state
			}
		// Add or Remove all lineItemFullIds for one job
		case TOGGLE_LINEITEMS_RFA_FOR_ONE_JOB:
			if (action.payload.applicationValueList) {
				if (action.payload.jobAreaCode === "SO") {
					if (action.payload.applicationValueList.length > 0) {
						state.SoTotalPrice += action.payload.applicationValueList.reduce(add)
					}
				} else {
					if (action.payload.applicationValueList.length > 0) {
						state.NonSoTotalPrice += action.payload.applicationValueList.reduce(add)
					}
				}
			}
			state.lineItemsReadyForApplication.find(item => item.jobId === action.payload.jobId).lineItemsFullIds = action.payload.lineItemFullIdList
			state.lineItemsReadyForApplication.find(item => item.jobId === action.payload.jobId).allSelected = action.payload.allSelected
			return {
				...state
			}
	}
}

export default ApplicationEligibleReducer
