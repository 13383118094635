import AddIcon from '@material-ui/icons/Add';
import CustomPopper from "components/CustomPopper/CustomPopper"
import CustomerLink from "views/Search/components/CustomerLink"

export const LEFT_TABLE_COLUMNS = [
	{
		Header: "Raise New Order For:",
		accessor: "customer_name"
	},
	{
		Header: "Last Job",
		accessor: "last_job"
	}
]

export const RIGHT_TABLE_COLUMNS = [
	{
		Header: "Existing Jobs",
		accessor: "job_id"
	},
	{
		Header: "For Customer",
		accessor: "customer_name"
	},
	{
		Header: "Site",
		accessor: "site"
	},
	{
		Header: "Stage",
		accessor: "stage"
	}
]
const description = []

export const actionButtons = [
	{ color: "", icon: AddIcon },
].map((prop, key) => {
	return (
		<CustomPopper key={key} description={description} />
	);
});
