import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { makeStyles } from "@material-ui/core/styles"
import SweetAlert from "react-bootstrap-sweetalert"

import { InputAdornment, Box } from '@material-ui/core'

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import OrderItemCollapse from "components/ApplicationsEligible/OrderItemCollapse"
import Button from "components/CustomButtons/Button.js"
import CustomInput from "components/CustomInput/CustomInput.js"

import ApiServices from "api/Api.js"
import { ApplicationEligibleContext } from "context/ApplicationsEligible/ApplicationsEligibleContext"

import styles from "assets/jss/material-dashboard-pro-react/views/applicationEligibleStyles.js"
import { UserRoleContext } from '../../context/UserRole/UserRoleContext'
import { checkUserPermission } from '../../utils/utils'

const useStyles = makeStyles(styles)

export default function ApplicationsEligible() {

	const classes = useStyles()

	const [jobs, setJobs] = useState([])
	const [alert, setAlert] = React.useState(null)

	const {
		SoTotalPrice,
		NonSoTotalPrice,
		setLineItemsFullIdsOfAllJobs,
		lineItemsReadyForApplication
	} = useContext(ApplicationEligibleContext)

	const fetchByStatusForApplication = async () => {
		const res = await ApiServices.getByStatusReadyForApplication()
		setJobs(res.data)
		setLineItemsFullIdsOfAllJobs(res.data)
	}

	const generateAllApplications = async () => {

		let allIds = [
			...lineItemsReadyForApplication.flatMap(l => l.lineItemsFullIds).filter(l => l)
		]
		jobs.map(job => job.lineItemsReadyForApplication.filter(item => item.isAlreadyApplied).map(item => allIds.push(item.fullId.asString)))

		await ApiServices.createApplications(allIds)
			.then( () => toast.success("Application for payment has been created."))
			.catch(error => {
				if (error.response && error.response.status === 422 && error.response.data) {
					const displayData = error.response.data.toString() || ""
					const displayLines= displayData.split("\n")
					setAlert(
						<SweetAlert
							warning
							style={{ display: "block", marginTop: "-100px" }}
							title="Not all applications were created."
							onCancel={() => { hideAlert() }}
							onConfirm={() => { hideAlert() }}
							confirmBtnCssClass={classes.button}
							confirmBtnText="Ok"
							showCancel={false}
							showConfirm={true}
						>
							{displayLines.map(l => <span>{l}<br /></span>)}
						</SweetAlert>)
				} else {
					setAlert(
						<SweetAlert
							warning
							style={{ display: "block", marginTop: "-100px" }}
							title="Something went wrong"
							onCancel={() => { hideAlert() }}
							onConfirm={() => { hideAlert() }}
							cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
							cancelBtnText="Cancel"
							showCancel
							showConfirm={false}
						>
							Please let the page reload and try again.
						</SweetAlert>)
				}
			})
		fetchByStatusForApplication();
	}

	const hideAlert = () => {
		setAlert(null);
		fetchByStatusForApplication();
	}

	useEffect(async () => {
		await fetchByStatusForApplication()
	}, [])

	const { userRole, permissions } = useContext(UserRoleContext)
	const [hasGeneratePermission, setHasGeneratePermission] = useState(false)

	useEffect(() => {
		setHasGeneratePermission(checkUserPermission(permissions, "CreateAndDeleteApplications", userRole))
	}, [userRole])


	return (
		<GridContainer>
			<GridItem xs={12}>
				{alert}
				<Card>
					<CardHeader color="rose" text>
						<CardText color="rose">
							<h4 className={classes.cardTitle}>Application Eligible</h4>
						</CardText>
					</CardHeader>
					<CardBody>
						<Box display="flex" alignItems="center" mt={3} justifyContent="end">
							<Box className={classes.totalPrices}>
								<CustomInput
									formControlProps={{
										className: classes.totalPriceFields
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										readOnly: true,
										value: SoTotalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
									}}
									startAdornment={<InputAdornment position="start">SO</InputAdornment>}
									endAdornment={<InputAdornment position="end">£</InputAdornment>}
								/>
							</Box>
							<Box className={classes.totalPrices}>
								<CustomInput
									formControlProps={{
										className: classes.totalPriceFields
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										readOnly: true,
										value: NonSoTotalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
									}}
									startAdornment={<InputAdornment position="start">All areas not SO</InputAdornment>}
									endAdornment={<InputAdornment position="end">£</InputAdornment>}
								/>
							</Box>
							<Button
								color="info"
								className={classes.actionBtn}
								disabled={!hasGeneratePermission || !(jobs.length > 0)}
								onClick={generateAllApplications}
							>
									Generate All
							</Button>
						</Box>
						{
							!!jobs && jobs.map((job, index) => (
								<OrderItemCollapse
									key={index}
									hasGeneratePermission={hasGeneratePermission}
									jobData={job}
									fetchByStatusForApplication={fetchByStatusForApplication}
								/>
							))
						}
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}
