import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import Button from "components/CustomButtons/Button.js";
import FormHelperText from "@material-ui/core/FormHelperText";
import MultiLineInput from "components/CustomInput/MultiLineInput.js";

import { verifySnaggingForm } from "utils/validations";

import styles from "assets/jss/material-dashboard-pro-react/components/doorsFormStyle";
import {Input} from "@material-ui/core";
import CustomInput from "../../../components/CustomInput/CustomInput";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(styles);

const EditSnaggingModal = (props) => {
  const classes = useStyles();

  const { open, toggleModal, snagging, handleSubmit } = props;

  const [formData, setFormData] = useState({
    raisedDate: null,
    expectedCloseDate: null,
    description: "",
    snaggingStatus: 0
  });
  const [formValidation, setFormValidation] = useState({
    raisedDate: true,
    expectedCloseDate: true,
    description: true,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChangeFormData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    const { verifyStatus, verifyResult, errors } = verifySnaggingForm(formData);
    setFormValidation(verifyResult);
    setFormErrors(errors);

    if (!verifyStatus) {
      toast.error(errorMessage(errors));
      return;
    }
    handleSubmit(formData);
  };

  useEffect(() => {
    if (snagging) {
      setFormData({
        ...snagging,
        raisedDate: new Date(snagging.raisedDate),
        expectedCloseDate: new Date(snagging.expectedCloseDate)
      });
      setFormErrors({});
      setFormValidation({
        raisedDate: true,
        expectedCloseDate: true,
        description: true,
      });
    }
  }, [open]);
  console.log(formData);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={toggleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card className={classes.editCard} variant="outlined">
          <CardHeader
            title="Edit Snagging"
            action={
              <IconButton aria-label="close" onClick={toggleModal}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormLabel>Raised Date *</FormLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={formData.raisedDate}
                    onChange={(date) =>
                      handleChangeFormData("raisedDate", date)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    inputVariant="outlined"
                    fullWidth
                    style={{ marginTop: "4px" }}
                    inputProps={{
                      style: { fontSize: 20 },
                      placeholder: "DD/MM/YYYY",
                      name: "raisedDate",
                    }}
                    error={!formValidation.raisedDate}
                  />
                </MuiPickersUtilsProvider>
                {formValidation.raisedDate !== undefined ? (
                  <FormHelperText
                    id="installationDate"
                    className={classes.errorLabel}
                  >
                    {formErrors.raisedDate}
                  </FormHelperText>
                ) : null}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormLabel>Expected close out date *</FormLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={formData.expectedCloseDate}
                    onChange={(date) =>
                      handleChangeFormData("expectedCloseDate", date)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    inputVariant="outlined"
                    fullWidth
                    style={{ marginTop: "4px" }}
                    inputProps={{
                      style: { fontSize: 20 },
                      placeholder: "DD/MM/YYYY",
                      name: "expectedCloseDate",
                    }}
                    error={!formValidation.expectedCloseDate}
                  />
                </MuiPickersUtilsProvider>
                {formValidation.expectedCloseDate !== undefined ? (
                  <FormHelperText
                    id="installationDate"
                    className={classes.errorLabel}
                  >
                    {formErrors.expectedCloseDate}
                  </FormHelperText>
                ) : null}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormLabel>Description *</FormLabel>
                <MultiLineInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.paddingTop,
                  }}
                  inputProps={{
                    type: "text",
                    style: { fontSize: 20 },
                    placeholder: "Description",
                    name: "description",
                    onChange: (e) =>
                      handleChangeFormData("description", e.target.value),
                    defaultValue: formData.description,
                  }}
                  rows={2}
                  helperText={formErrors.description ?? ""}
                  error={!formValidation.description}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormLabel>Raised By *</FormLabel>
                <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.paddingTop
                    }}
                    inputProps={{
                      type: "text",
                      style: { fontSize: 20 },
                      placeholder: "Raised By",
                      name: "raised-by",
                      value: formData.raisedBy,
                      onChange: (e) =>
                          handleChangeFormData("raisedBy", e.target.value),
                      defaultValue: formData.raisedBy
                    }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormLabel>Status *</FormLabel>
                <FormControl variant="outlined" fullWidth >
                  <Select
                      value={ formData.snaggingStatus }
                      className={ classes.select }
                      onChange= {(e) => 
                          handleChangeFormData("snaggingStatus", e.target.value)}
                  >
                    <MenuItem value={0}>Live</MenuItem>
                    <MenuItem value={1}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </CardContent>
          <CardActions className={classes.cardAction}>
            <Button color="info" size="lg" onClick={handleUpdate}>
              Update
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

export default EditSnaggingModal;
