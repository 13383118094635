import React, { useState, useEffect, useContext } from 'react'
import classNames from 'classnames'
import { makeStyles } from "@material-ui/core/styles"
import Check from "@material-ui/icons/Check"

import MoreVertIcon from '@material-ui/icons/MoreVert'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import ImageSearchIcon from '@material-ui/icons/ImageSearch'

import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { checkUserPermission, getVariantTypeText } from "utils/utils"
import {
	line_item_status_filter_list
} from "variables/dropdown.js"
import { LineItemContext } from 'context/LineItem/LineItemContext'

import styles from "assets/jss/material-dashboard-pro-react/views/orderItemCollapseStyle.js"

const useStyles = makeStyles(styles)

function LineItem(props) {

	const {
		orderId,
		lineItem,
		allCompleted,
		cancelItemIds,
		handleChangeCancel,
		lineItemTryStageRevert,
		handleUploadBtn,
		handleChangeComplete,
		stageCompleteIds
	} = props

	const { doorTypes } = useContext(LineItemContext)

	const [completed, setCompleted] = useState(false)
	const [canceled, setCanceled] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null);
	const [doorStage, setDoorStage] = useState('')

	const { userRole, permissions } = useContext(UserRoleContext)

	const [hasStageRevertPermission, setHasStageRevertPermission] = useState(false)

	useEffect(() => {
		setHasStageRevertPermission(checkUserPermission(permissions, "AdminScreens", userRole))
	}, [userRole])

	useEffect(() => {
		setCompleted(stageCompleteIds.indexOf(parseInt(lineItem.id)) !== -1 ? true : false)
		setCanceled(cancelItemIds.indexOf(parseInt(lineItem.id)) !== -1 ? true : false)
		setDoorStage(line_item_status_filter_list.find(item => lineItem.stage === item.value)?.text ?? "")
	}, [allCompleted, cancelItemIds, lineItem.stage, stageCompleteIds, lineItemTryStageRevert])

	const classes = useStyles()

	let itemCancelled = false;
	if (lineItem.isNonDoor) {
		if (lineItem.nonDoorVariation.type === 2) {
			itemCancelled = true
		} else {
			itemCancelled = false
		}
	}

	let disableCancel = false
	if (lineItem.isDoor) {
		if (lineItem.door.stage === 6) {
			disableCancel = true
		}
	} if (lineItem.isNonDoor) {
		if (lineItem.nonDoorVariation.stage === 6) {
			disableCancel = true
		}
	}

	const cancelledItemClasses = classNames({
		[classes.dNone]: itemCancelled,
		[classes.alignItemsCenter]: true
	})

	const isCancellationLine= lineItem.isNonDoor && (getVariantTypeText(lineItem.nonDoorVariation.type) === "Cancellation");

	return (
		<GridContainer className={classes.lineItem}>
			<GridItem xs={12} sm={12} md={2} className={classes.lineItemCell}>
				<p>
					{lineItem.fullId.asString}
				</p>
			</GridItem>
			<GridItem xs={12} sm={12} md={5} className={classes.lineItemCell}>
				{
					!lineItem.isVariation ?
						<p>
							{`${doorTypes.find(item => item.value === lineItem.door.type)?.text ?? ""} `}
							{lineItem.customerRef}
						</p>
						:
						<p>
							{[
								lineItem.isDoor ? doorTypes.find(item => item.value === lineItem.door.type)?.text??"" : lineItem.customDescription,
								lineItem.ascotRef,
								lineItem.nonDoorVariation.instructedBy,
								new Date(lineItem.nonDoorVariation.instructionDate).toLocaleDateString()
							].join(" ")
							}
						</p>
				}
			</GridItem>	
		
			<GridItem xs={12} sm={12} md={1}>
				<IconButton name="uploadDocument" className={classes.setBtnColor} onClick={() => handleUploadBtn(orderId, lineItem.id)}>
					<CloudUploadIcon />
				</IconButton>				
				{
					!!lineItem.design && 
					<IconButton className={classes.setBtnColor} onClick={() => window.open(`/api/jobs/${lineItem.isVariation ? 'lineitem' : 'door'}/documents/${orderId}/${lineItem.id}`, "_blank")}>
						<ImageSearchIcon />
					</IconButton>
				}
			</GridItem>
				

			<GridItem xs={12} sm={12} md={2} className={classes.lineItemCell}>
				<p className={classes.lineItemStage}>
					{ doorStage }
					<IconButton onClick={(event) => setAnchorEl(event.currentTarget)} disabled={!hasStageRevertPermission}>
						<MoreVertIcon />
					</IconButton>
				</p>
			</GridItem>

			<GridItem xs={12} sm={4} md={1} className={cancelledItemClasses}>
				<div
					className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal + " " + classes.checkbox}
				>
					<FormControlLabel
						control={
							<Checkbox
								tabIndex={-1}
								onChange={(e) => handleChangeComplete(e, lineItem.id)}
								checkedIcon={
									<Check className={classes.checkedIcon} />
								}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{
									checked: classes.checked,
									root: classes.checkRoot
								}}
								checked={completed}
							/>
						}
						classes={{
							label: classes.label,
							root: classes.labelRoot
						}}
						label="Done"
					/>
				</div>
			</GridItem>
			<GridItem xs={12} sm={4} md={1} className={cancelledItemClasses}>
				<div
					className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal + " " + classes.checkbox}
				>
					<FormControlLabel
						control={
							<Checkbox
								tabIndex={-1}
								onClick={(e) => {
									handleChangeCancel(e, lineItem.id)
								}}
								checkedIcon={
									<Check className={classes.checkedIcon} />
								}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{
									checked: classes.checked,
									root: classes.checkRoot
								}}
								checked={canceled}
								disabled={disableCancel}
							/>
						}
						classes={{
							label: classes.label,
							root: classes.labelRoot
						}}
						label="Cancel"
					/>
				</div>
			</GridItem>

			<Menu
				id={`${orderId}-${lineItem.id}`}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				<MenuItem onClick={() => {
					lineItemTryStageRevert(orderId, lineItem.id, isCancellationLine); setAnchorEl(null);
				}}>
					{ isCancellationLine ? "Undo cancellation" : "Revert to previous stage" }
				</MenuItem>
			</Menu>
		</GridContainer>
	)
}

export default LineItem
