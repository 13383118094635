import React from "react"
import cx from "classnames"
import { Switch, Route, Redirect } from "react-router-dom"
import { ToastContainer } from 'react-toastify'

import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Navbar from "components/Navbars/Navbar.js"
import Footer from "components/Footer/Footer.js"

import LineItemContextProvider from "context/LineItem/LineItemContext"
import ApplicationEligibleProvider from "context/ApplicationsEligible/ApplicationsEligibleContext"

import { mainRoutes } from "routes.js"

import styles from "assets/jss/material-dashboard-pro-react/layouts/mainStyle.js"


var ps;

const useStyles = makeStyles(styles);

export default function MainLayout(props) {

  const { ...rest } = props;

  const [miniActive, setMiniActive] = React.useState(false);

  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }

    };
  });
  // functions for changeing the states from components


  const getActiveRoute = routes => {
    let activeRoute = "Design Guide";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
        return (
          <Route
            path={ prop.path }
            component={ prop.component }
            key={ key }
          />
        );

    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };


  return (
    <div className={classes.wrapper}>      
      <LineItemContextProvider>
        <ApplicationEligibleProvider>
          <div className={ mainPanelClasses } ref={ mainPanel }>
            <Navbar
              sidebarMinimize={sidebarMinimize.bind(this)}
              miniActive={ miniActive }
              brandText={getActiveRoute(mainRoutes)}
              {...rest}
            />
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  { getRoutes(mainRoutes) }
                  {/* <Redirect from="/" to="/search" /> */}
                </Switch>
              </div>
            </div>
            <Footer fluid />
          </div>
          <ToastContainer autoClose={ 2000 } />
        </ApplicationEligibleProvider>
      </LineItemContextProvider>    
    </div>
  );
}
