import React, {createContext, useEffect, useState} from 'react'
import ApiServices from "api/Api.js"
import {useMsal} from '@azure/msal-react'
import jwt_decode from "jwt-decode";
import {feature} from "../../utils/feature";

export const UserRoleContext = createContext()

const UserRoleContextProvider = ({ children }) => {

  const { accounts,instance,inProgress } = useMsal();
	const [userRole, setUserRole] = useState("ReadOnly")
	const [rolesAvailableToUser, setRolesAvailableToUser] = useState([{value: "ReadOnly", text: "ReadOnly"}])
	const [permissions, setPermissions] = useState()
	const changeUserRole = (role) => setUserRole(role)

	const contextValue = {
		userRole,
		permissions,
		changeUserRole,
		rolesAvailableToUser
	}

	const useJWTTokenRoles= ! feature.useRolesFromAppsettings()

	const getPermissionList = async () => {

		try {
			const res = await ApiServices.getPermissionsAndRoles(accounts[0].username)
			if (res.status === 200) {
				console.log("UserRoleContext:Got Permission list and Backend roles:",res.data)
				setPermissions(res.data.permissions)
				if(!useJWTTokenRoles) {
					console.log("UserRoleContext:Using roles from server for ", accounts[0].username)
					let roles = res.data.roles;
					if(!roles.length){
						console.warn("UserRoleContext:Using roles from server: No roles found for user, falling back to readonly",
							accounts[0].username)
						roles=["ReadOnly"]
					}
					setUserRole(roles[0])
					setRolesAvailableToUser(roles.map(r => ({value: r, text: r})))
					//
					if(sessionStorage.getItem('ascotImpersonateRoles')){
						roles= JSON.parse(sessionStorage.getItem('ascotImpersonateRoles'))
						console.warn("Impersonating",roles)
						setRolesAvailableToUser(roles.map(r => ({value: r, text: r})))
						setUserRole(roles[0])
					}
					//
				}else{
					console.log("UserRoleContext:But not using roles from server")
				}
			}
			else {
				console.error("UserRole.getPermissionsAndRoles()",accounts[0], res.status, "accessToken", sessionStorage.getItem("accessToken"))
			}
		}
		catch(e){console.error("UserRole.getPermissionList:",e)	}
	}

	useEffect(async () => {
		let roles
		let defaultRole
		try {
			
			roles = jwt_decode(localStorage.getItem('accessToken')).roles || []
			if (sessionStorage.getItem('ascotImpersonateRoles')) {
				roles = JSON.parse( sessionStorage.getItem('ascotImpersonateRoles'))
			}
			if (roles.length) {
				console.info("roles from JwtToken", roles)
			} else {
				console.warn("no roles found for user")
				roles = ["ReadOnly"];
			}
			
			let defaultRoleIfAny= roles.filter((role) => role.startsWith('Default' || role.startsWith('default')));
			if (defaultRoleIfAny.length) {
				defaultRole = defaultRoleIfAny[0].substring(7)
			} else {
				console.warn("No default role defined in jwtToken, using first listed role from ", roles)
				defaultRole = roles[0];
			}

			if (!roles.includes(defaultRole)) { 
				roles.unshift(defaultRole)
			}
		}
		catch (e) {
			console.error("Getting userRoles from jwtToken | useJWTTokenRoles="+useJWTTokenRoles, e)
			if(useJWTTokenRoles){console.error("Falling back to readonly")}
			roles=["ReadOnly"]
			defaultRole="ReadOnly"
		}
		const allUserRoles = roles.filter((cell) => !cell.includes('Default')).map(r => ({
			value: r,
			text: r
		}));
		if (useJWTTokenRoles) {
			console.log("UserRoleContext:Got Default Role", defaultRole, "and all roles from jwt token", allUserRoles)
			setUserRole(defaultRole)
			setRolesAvailableToUser(allUserRoles)
		}
		await getPermissionList()
	}, [accounts,instance,inProgress])

	return (
		<UserRoleContext.Provider value={contextValue}>
			{
				children
			}
		</UserRoleContext.Provider>
	)
}

export default UserRoleContextProvider
