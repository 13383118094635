import React, { useState, useContext } from 'react'
import Datetime from "react-datetime"
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'

import { makeStyles } from "@material-ui/core/styles"

import AddIcon from '@material-ui/icons/Add'

import FormLabel from "@material-ui/core/FormLabel"
import FormHelperText from "@material-ui/core/FormHelperText"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"

import { areaCodeList } from "variables/dropdown"
import { LineItemContext } from 'context/LineItem/LineItemContext'

import styles from "assets/jss/material-dashboard-pro-react/views/jobStyle"

const useStyles = makeStyles(styles)

function InstallationDates(props) {

	const { enableNewJob, createNewJob, handleJobData, jobFormValidation, jobFormErrors, newJobId } = props	

	const [selectedDate, setSelectedDate] = useState(null)
	const [areaCode, setAreaCode] = useState("")

	const { vatCodes } = useContext(LineItemContext)	
	
	const handleDateChange = (date) => {
		setSelectedDate(date)
		handleJobData({ target: { name: "installationDateWc", value: date } })
	};

	const handleChangeAreaCode = (e) => {
		setAreaCode(e.target.value)
		handleJobData(e)
		if (e.target.value === "EX") {
			handleJobData({
				target: {
					name: "vatCode",
					value: "T14"
				}
			})
		}
	}

	const classes = useStyles()

	return (
		<GridContainer>
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					Date 1*
				</FormLabel>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						format="dd/MM/yyyy"
						margin="normal"
						value={selectedDate ?? null}
						onChange={handleDateChange}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
						inputVariant="outlined"
						fullWidth
						style={{ marginTop: "4px" }}
						inputProps={{
							style: { fontSize: 20 },
							placeholder: "DD/MM/YYYY",
							name: "installationDateWc",
						}}
						disabled={enableNewJob}
						error={!jobFormValidation.installationDateWc}
					/>
				</MuiPickersUtilsProvider>
				{jobFormValidation.installationDateWc !== undefined ? (
					<FormHelperText id="installationDateWc" className={classes.errorLabel}>
						{jobFormErrors.installationDateWc}
					</FormHelperText>
				) : null}
			</GridItem>
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					No. Visits*
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.paddingTop
					}}
					inputProps={{
						type: "number",
						style: { fontSize: 20 },
						placeholder: "No. Visits",
						disabled: enableNewJob,
						name: "numVisits",
						onChange: (e) => handleJobData(e)
					}}
					helperText={jobFormErrors.numVisits ?? ""}
					error={!jobFormValidation.numVisits}
				/>
			</GridItem>

			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					Area Code*
				</FormLabel>
				<CustomDropdown
					id="ddAreaCode"
					menuList={areaCodeList}
					fullWidth
					name="areaCode"
					classeNames={classes.dropdown}
					disabled={enableNewJob}
					handleDropdown={handleChangeAreaCode}
					error={!jobFormValidation.areaCode}
					helperText={jobFormErrors.areaCode ?? ""}
				/>
			</GridItem>

			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					VAT Code*
				</FormLabel>
				<CustomDropdown
					id="ddVatCodes"
					menuList={vatCodes}
					fullWidth
					name="vatCode"
					classeNames={classes.dropdown}
					defaultValue={areaCode === "EX" ? "T14" : "T21"}
					handleDropdown={handleJobData}
					disabled={enableNewJob || areaCode === "EX"}
					disableItems={["T14"]}
					error={!jobFormValidation.vatCode}
					helperText={jobFormErrors.vatCode ?? ""}
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					Documents*
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.paddingTop
					}}
					inputProps={{
						type: "file",
						style: { fontSize: 20 },
						placeholder: "Documents",
						disabled: enableNewJob,
						name: "documents",
						onChange: (e) => handleJobData(e),
						multiple: true
					}}
				/>
			</GridItem>
			<GridItem xs={12} className={classes.installationBtnGroup}>
				<Button
					justIcon
					round
					className={classes.marginRight}
					size="lg"
					disabled={enableNewJob}
				>
					<AddIcon className={classes.icons} />
				</Button>
				<Button
					size="lg"
					className={classes.saveBtn}
					disabled={enableNewJob}
					onClick={createNewJob}
				>
					{
						newJobId === "" ? <><AddIcon className={classes.icons} /> Save New Job</> : "Update job"
					}
				</Button>
			</GridItem>
		</GridContainer>
	)
}

export default InstallationDates
