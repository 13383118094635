export const doorTypeList = [
	{
		value: 0,
		text: 'Hinged Door'
	},
	{
		value: 1,
		text: 'Hinged door Fire rated'
	},
	{
		value: 2,
		text: 'Hinged door SR1'
	},
	{
		value: 3,
		text: 'Hinged door SR1 Fire rated'
	},
	{
		value: 4,
		text: 'Hinged door SR2'
	},
	{
		value: 5,
		text: 'Hinged door SR2 Fire rated'
	},
	{
		value: 6,
		text: 'Hinged door SR3'
	},
	{
		value: 7,
		text: 'Hinged door SR3 Fire rated'
	},
	{
		value: 8,
		text: 'Hinged door SR4'
	},
	{
		value: 9,
		text: 'Hinged door SR4 Fire rated'
	},
	{
		value: 10,
		text: 'Sectional overhead door manual'
	},
	{
		value: 11,
		text: 'Sectional overhead door electric'
	},
	{
		value: 12,
		text: 'Roller Shutter - manual'
	},
	{
		value: 13,
		text: 'Roller Shutter - electric'
	},
	{
		value: 14,
		text: 'Fire Shutter - manual'
	},
	{
		value: 15,
		text: 'Fire Shutter - Electric'
	},
	{
		value: 16,
		text: 'Roller Shutter SR1'
	},
	{
		value: 17,
		text: 'Roller Shutter SR2'
	},
	{
		value: 18,
		text: 'Roller Shutter SR3'
	},
	{
		value: 19,
		text: 'Roller Shutter SR4'
	},
	{
		value: 20,
		text: 'Fire Curtain (Ascot)'
	},
	{
		value: 21,
		text: 'Fire Curtain (bought in)',
	},
	{
		value: 22,
		text: 'Internal High speed door'
	},
	{
		value: 23,
		text: 'External High speed door'
	},
	{
		value: 24,
		text: 'Other bought in product'
	}
]

export const order_status_filter_list = [
	{
		value: 'all',
		text: 'All Statuses'
	},
	{
		value: 0,
		text: 'Awaiting Credit Approval'
	},
	{
		value: 1,
		text: 'In Progress'
	},
	{
		value: 2,
		text: 'Complete'
	}
]

export const line_item_status_filter_list = [
	{
		value: 0,
		text: 'Drawing Office'
	},
	{
		value: 1,
		text: 'Camming'
	},
	{
		value: 2,
		text: 'Manufacture'
	},
	{
		value: 3,
		text: 'Delivery'
	},
	{
		value: 4,
		text: 'Installation'
	},
	{
		value: 5,
		text: 'Completed'
	}
]

export const areaCodeList = [
	{
		value: "1",
		text: "1"
	},
	{
		value: "2",
		text: "2"
	},
	{
		value: "3",
		text: "3"
	},
	{
		value: "4",
		text: "4"
	},
	{
		value: "5",
		text: "5"
	},
	{
		value: "6",
		text: "6"
	},
	{
		value: "7",
		text: "7"
	},
	{
		value: "8",
		text: "8"
	},
	{
		value: "9",
		text: "9"
	},
	{
		value: "10",
		text: "10"
	},
	{
		value: "11",
		text: "11"
	},
	{
		value: "12",
		text: "12"
	},
	{
		value: "EX",
		text: "EX"
	},
	{
		value: "SO",
		text: "SO"
	},
]

export const attendancesRadios = [
	{
		value: "0",
		label: "Ascot"
	},
	{
		value: "1",
		label: "Client"
	},
	{
		value: "2",
		label: "Assist"
	}
]

export const variantTypes = [
	{
		value: "non_door_variation",
		text: "Non Door Variation"
	},
	{
		value: "new_door_variation",
		text: "New Door Variation"
	},
	{
		value: "installation_variation",
		text: "Installation Variation"
	}
]

export const filterAfps = [
	{
		value: "all",
		text: "All"
	},
	{
		value: "downloaded",
		text: "Downloaded"
	},
	{
		value: "not downloaded",
		text: "Not Downloaded"
	}
]

export const userRoles = [
	{
		value: "ReadOnly",
		text: "Read Only"
	},
	{
		value: "Sales",
		text: "Sales"
	},
	{
		value: "Accounts",
		text: "Accounts"
	},
	{
		value: "Production",
		text: "Production"
	},
	{
		value: "Installation",
		text: "Installation"
	}
]

export const vatCodes = [
	{
		value: "T1",
		text: "T1 Standard Rate"
	},
	{
		value: "T14",
		text: "T14 Export"
	},
	{
		value: "T21",
		text: "T21 Reverse Charge"
	}
]
export const discountList = [
	{
		value: '2.5',
		text: '2.5%'
	},
	{
		value: '5',
		text: '5%'
	},
	{
		value: 'other',
		text: 'Other'
	}
]
