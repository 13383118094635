import React, { useState, useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { toast } from 'react-toastify'

import AddIcon from '@material-ui/icons/Add'
import FormLabel from "@material-ui/core/FormLabel"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button.js"

import {
	initialAwaitingNewDoorFormValidation,
	verifyAwaitingNewDoorForm
} from "utils/validations"
import { errorMessage } from "utils/utils"
import { LineItemContext } from 'context/LineItem/LineItemContext'

import styles from "assets/jss/material-dashboard-pro-react/views/orderItemCollapseStyle.js"

const useStyles = makeStyles(styles)

const AddDooItemForm = (props) => {

	const { handleAddDoor, handleChangeNewDoorCostPrice, handleChangeNewDoorSellPrice } = props
	
	const { doorTypes } = useContext(LineItemContext)

	const [newDoorForm, setNewDoorForm] = useState({})	
	const [doorType, setDoorType] = useState('')
	const [formValidation, setFormValidation] = useState(initialAwaitingNewDoorFormValidation)
	const [formErrors, setFormErrors] = useState({})
	const [rerenderView, setRerenderView] = useState(false)	

	const handleChangeForm = (e) => {
		setNewDoorForm(prev => {
			prev[e.target.name] = e.target.value
			return prev
		})
	}	

	const handleDoorType = (e) => {
		handleChangeForm(e)
		setDoorType(e.target.value)
	}

	const handleSubmitNewDoor = (e) => {
		e.preventDefault()
		const { verifyStatus, verifyResult, errors } = verifyAwaitingNewDoorForm(newDoorForm)
		setFormValidation(verifyResult)
		setFormErrors(errors)

		if (!verifyStatus) {
			toast.error(errorMessage(errors))
		}

		if (verifyStatus) {
			let newDoorItem;
			
			newDoorItem = {
				stage: 0,
				costPrice: newDoorForm.costPrice,
				sellPrice: newDoorForm.sellPrice,
				customerRef: newDoorForm.customerRef,
				customDescription: newDoorForm.otherTypeDescription,
				door: {
					type: newDoorForm.type,
					width: newDoorForm.width,
					height: newDoorForm.height,
					colour: newDoorForm.colour
				}				
			}			
			handleAddDoor(newDoorItem, "")		
		}
		setRerenderView(!rerenderView)
	}

	const classes = useStyles()

	return (
		<GridContainer className={classes.variantFormWrapper}>
			
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					Door Type *
				</FormLabel>
				<CustomDropdown
					menuList={ doorTypes }
					fullWidth
					name="type"
					classeNames={classes.dropdown}
					handleDropdown={(e) => handleDoorType(e)}
					error={!formValidation.type}
					helperText={formErrors.type ?? ""}
				/>
			</GridItem>
				
			{
				 doorType === 24 ?
					<GridItem xs={12} sm={6} md={4}>
						<FormLabel>
							Other Type Description *
						</FormLabel>
						<CustomInput
							formControlProps={{
								fullWidth: true,
								className: classes.textField
							}}
							inputProps={{
								type: "text",
								style: { fontSize: 20 },
								placeholder: "Other Type Description",
								name: "otherTypeDescription",
								onChange: (e) => handleChangeForm(e)
							}}
							error={!formValidation.otherTypeDescription}
							helperText={formErrors.otherTypeDescription ?? ""}
						/>
					</GridItem>
					: <></>
			}
			<GridItem xs={12} sm={6} md={2}>
				<FormLabel>
					Cost Price *
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.textField,
					}}
					inputProps={{
						type: "number",
						style: { fontSize: 20 },
						placeholder: "Cost Price",
						name: "costPrice",
						onChange: (e) => { handleChangeNewDoorCostPrice(e.target.value); handleChangeForm(e); }
					}}
					error={!formValidation.costPrice}
					helperText={formErrors.costPrice ?? ""}
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={2}>
				<FormLabel>
					Sell Price *
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.textField
					}}
					inputProps={{
						type: "number",
						style: { fontSize: 20 },
						placeholder: "Sell Price",
						name: "sellPrice",
						onChange: (e) => { handleChangeNewDoorSellPrice(e.target.value); handleChangeForm(e);}
					}}
					error={!formValidation.sellPrice}
					helperText={formErrors.sellPrice ?? ""}
				/>
			</GridItem>										
		
			<GridItem xs={12} sm={6} md={2}>
				<FormLabel>
					Width *
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.textField
					}}
					inputProps={{
						type: "number",
						style: { fontSize: 20 },
						placeholder: "Width",
						name: "width",
						onChange: (e) => handleChangeForm(e)
					}}
					error={!formValidation.width}
					helperText={formErrors.width ?? ""}
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={2}>
				<FormLabel>
					Height *
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.textField
					}}
					inputProps={{
						type: "number",
						style: { fontSize: 20 },
						placeholder: "Height",
						name: "height",
						onChange: (e) => handleChangeForm(e)
					}}
					error={!formValidation.height}
					helperText={formErrors.height ?? ""}
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={2}>
				<FormLabel>
					Colour *
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.textField
					}}
					inputProps={{
						type: "text",
						style: { fontSize: 20 },
						placeholder: "Colour",
						name: "colour",
						onChange: (e) => handleChangeForm(e)
					}}
					error={!formValidation.colour}
					helperText={formErrors.colour ?? ""}
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={2}>
				<FormLabel>
					Customer Ref *
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.textField
					}}
					inputProps={{
						type: "text",
						style: { fontSize: 20 },
						placeholder: "Customer Ref",
						name: "customerRef",
						onChange: (e) => handleChangeForm(e)
					}}
					error={!formValidation.customerRef}
					helperText={formErrors.customerRef ?? ""}
				/>
			</GridItem>
			<GridItem xs={12} md={2} className={classes.variantBtnWrapper}>
				<Button
					color="info"
					size="lg"
					className={classes.variantBtn}
					onClick={(e) => handleSubmitNewDoor(e)}
				>
					<AddIcon className={classes.icons} /> Confirm Door
				</Button>
			</GridItem>
		</GridContainer>
	)
}

export default AddDooItemForm
