import {
    cardTitle,
    whiteColor,
} from "assets/jss/material-dashboard-pro-react.js"
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js"
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js"
const applicationEligibleStyles = {
    ...customCheckboxRadioSwitch,
    cardTitle: {
        ...cardTitle,
        color: whiteColor
    },
    dropdown: {
        marginTop: "6px"
    },
    actionBtn: {
        fontSize: '18px',
        display: "flex"    ,
        height: "60px"    
    },
    totalPrices: {
        "& .MuiFormControl-root": {
            padding: "0",
            margin: "0",
            marginRight: "1rem"
        }        
    },
    totalPriceFields: {
        "& .MuiInputBase-input": {
            textAlign: "right"    
        },
        "& .MuiOutlinedInput-notchedOutline": {
            // border: "0",
            borderColor: '#00d3ee !important'
        }
    },
    ...buttonStyle
}

export default applicationEligibleStyles
