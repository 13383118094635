import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import SweetAlert from "react-bootstrap-sweetalert"

import Box from "@material-ui/core/Box"

import VariantForm from "components/JobList/VariantForm"
import DoorLineItem from "./DoorLineItem"
import NonDoorLineItem from "./NonDoorLineItem"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import GridContainer from "components/Grid/GridContainer.js"
import Button from "components/CustomButtons/Button.js"
import FormLabel from "@material-ui/core/FormLabel"

import AddDooItemForm from "./AddDooItemForm"
import CheckDoorsForm from "./CheckDoorsForm"
import CustomInput from "components/CustomInput/CustomInput.js"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { checkUserPermission } from "utils/utils"

import styles from "assets/jss/material-dashboard-pro-react/views/creditCheckStyle"

const useStyles = makeStyles(styles)

const DoorsSection = (props) => {

	const classes = useStyles()

	const {
		jobData,
		addVariantDoor,
		removeLineItem,
		creditNumber,
		handleEditAction,
		handleUploadFileBtn,
		addDoorLineItem,
		updateNettOrDiscount
	} = props

	const [alert, setAlert] = useState(null)
	const [showVariantForm, setShowVariantForm] = useState(false)

	const [newDoorCostPrice, setNewDoorCostPrice] = useState(0)
	const [newDoorSellPrice, setNewDoorSellPrice] = useState(0)

	const [priceFields, setPriceFields] = useState({
		totalCostPrice: 0,
		totalSellPrice:0,
		totalContractValue: 0,
		discountedPrice: 0
	})

	const openVariantForm = (e) => {
		e.preventDefault()
		setShowVariantForm(true)
	}

	const handleConfirmAlert = (variant, type) => {
		setAlert(
			<SweetAlert
				warning
				style={{ display: "block", marginTop: "-100px" }}
				title="Are you sure?"
				onConfirm={() => {
					hideAlert();
					if (jobData.stage === 0) {
						addDoorLineItem(variant)
						setNewDoorSellPrice(0)
						setNewDoorCostPrice(0)
					} else {
						addVariantDoor(variant, type);
					}
					setShowVariantForm(false)
				}}
				onCancel={() => { hideAlert() }}
				confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.alertButton}
				cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
				confirmBtnText="Yes"
				cancelBtnText="Cancel"
				showCancel
			>
			</SweetAlert>
		)
	}

	const hideAlert = () => {
		setAlert(null)
	}

	const { userRole, permissions } = useContext(UserRoleContext)

	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "RaiseOrder", userRole))
	}, [userRole, permissions])

	useEffect(() => {
		if (jobData.lineItems && (jobData.stage !== "0")) {
			let totalSellPrice = jobData.lineItems.map(lineItem => lineItem.sellPrice).reduce((prev, curr) => prev + curr, 0);
			let totalContractValue = jobData.lineItems.filter(li=>li.isDoor).map(lineItem => lineItem.sellPrice).reduce((prev, curr) => prev + curr, 0);
			setPriceFields({
				totalCostPrice: jobData.lineItems.map(lineItem => lineItem.costPrice).reduce((prev, curr) => prev + curr, 0),
				totalSellPrice: totalSellPrice,
				totalContractValue: totalContractValue,
				discountedPrice: jobData.nettOrDiscount.isDiscount ? totalSellPrice * jobData.nettOrDiscount.multipier : 0
			})
		}
	}, [jobData.lineItems])

	return (
		<GridItem xs={12} sm={12} md={12}>
			<Card>
				<CardHeader color="rose" text>
					<CardText color="rose">
						<h4 className={classes.cardTitle}>Doors</h4>
					</CardText>
				</CardHeader>
				<CardBody className={classes.cardBodyPadding}>
					<GridContainer className={classes.justifyCenter}>
						{
							!!(jobData.lineItems) ?
								jobData.lineItems.length > 0 ?
									jobData.lineItems.map(lineItem => (
										!lineItem.isVariation ?
											<DoorLineItem
												lineItem={lineItem}
												removeLineItem={removeLineItem}
												key={`door-${lineItem.id}`}
												creditNumber={creditNumber}
												handleEditAction={handleEditAction}
												handleUploadFileBtn={handleUploadFileBtn}
											/>
											:
											<NonDoorLineItem
												lineItem={lineItem}
												removeLineItem={removeLineItem}
												key={`variation-${lineItem.id}`}
												creditNumber={creditNumber}
												handleEditAction={handleEditAction}											
												jobData={jobData}
												handleUploadFileBtn={handleUploadFileBtn}
											/>
									))
									: <></>
								: <></>
						}
						<GridItem xs={12}>
							{
								jobData.stage !== 0 && showVariantForm ? <VariantForm handleAddVariant={handleConfirmAlert} /> : <></>
							}
							{
								jobData.stage === 0 && showVariantForm ?
									<AddDooItemForm
										handleAddDoor={handleConfirmAlert}
										handleChangeNewDoorCostPrice={(costPrice) => setNewDoorCostPrice(costPrice)}
										handleChangeNewDoorSellPrice={(sellPrice) => setNewDoorSellPrice(sellPrice)}
									/> : <></>
							}
						</GridItem>
					</GridContainer>
					<GridContainer>
						{/* Display when order status isn't Awaiting Credit Approval */}
						{
							!!jobData.stage && (
								<>
									<GridItem xs={12} sm={12} md={3}>
										<FormLabel>
											Total Cost Price £
										</FormLabel>
										<CustomInput
											formControlProps={{
												fullWidth: true,
												className: classes.paddingTop
											}}
											inputProps={{
												type: "number",
												style: { fontSize: 20 },
												placeholder: "Total Cost Price",
												name: "total-cost-price",
												readOnly: true,
												value: priceFields.totalCostPrice ? priceFields.totalCostPrice.toFixed(2):""
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={3}>
										<FormLabel>
											Total Sell Price £
										</FormLabel>
										<CustomInput
											formControlProps={{
												fullWidth: true,
												className: classes.paddingTop
											}}
											inputProps={{
												type: "number",
												style: { fontSize: 20 },
												placeholder: "Total Sell Price",
												name: "total-sell-price",
												readOnly: true,
												value: priceFields.totalSellPrice ? priceFields.totalSellPrice.toFixed(2):""
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={3}>
										<FormLabel>
											Total Contract Value £
										</FormLabel>
										<CustomInput
											formControlProps={{
												fullWidth: true,
												className: classes.paddingTop
											}}
											inputProps={{
												type: "number",
												style: { fontSize: 20 },
												placeholder: "Total Contract Price",
												name: "total-contract-price",
												readOnly: true,
												value: priceFields.totalContractValue ? priceFields.totalContractValue.toFixed(2) :""
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={3}>
										<FormLabel>
											Discounted Sell Price £
										</FormLabel>
										<CustomInput
											formControlProps={{
												fullWidth: true,
												className: classes.paddingTop
											}}
											inputProps={{
												type: "number",
												style: { fontSize: 20 },
												placeholder: "Discounted Contract Price",
												name: "discounted-value",
												readOnly: true,
												value: priceFields.discountedPrice ? priceFields.discountedPrice.toFixed(2):""
											}}
										/>
									</GridItem>
								</>
							)
						}

						<GridItem xs={12} sm={12} md={3} >
							<Button
								onClick={(e) => openVariantForm(e)}
								color="info"
								size="lg"
								fullWidth
								disabled={disabled}
							>
								{
									jobData.stage === 0 ? "Add Door" : "Add Variant ..."
								}
							</Button>
						</GridItem>
					</GridContainer>
					{
						jobData.stage === 0 &&
						<Box mt={2}>
							<CheckDoorsForm
								jobData={jobData}
								newDoorCostPrice={newDoorCostPrice}
								newDoorSellPrice={newDoorSellPrice}
								updateNettOrDiscount={updateNettOrDiscount}
							/>
						</Box>
					}
				</CardBody>
			</Card>
			{alert}
		</GridItem>
	)
}

export default DoorsSection
