import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormLabel from "@material-ui/core/FormLabel"
import CustomInput from "components/CustomInput/CustomInput.js"
import IconButton from '@material-ui/core/IconButton'
import Button from "components/CustomButtons/Button.js"
import MultiLineInput from "components/CustomInput/MultiLineInput.js"

import { initialEditCustomerValidation, verifyEditCustomerForm } from "utils/validations"
import { errorMessage } from "utils/utils"

import styles from "assets/jss/material-dashboard-pro-react/views/adminSettingsStyles"

const useStyles = makeStyles(styles)

const CustomerEditModal = (props) => {

	const classes = useStyles();

	const { open, toggleModal, customer, handleUpdateCustomer } = props

	const [formData, setFormData] = useState({})
	const [formValidation, setFormValidation] = useState(initialEditCustomerValidation)
	const [formErrors, setFormErrors] = useState({})

	const handleChangeForm = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = () => {
		
		const { verifyStatus, verifyResult, errors } = verifyEditCustomerForm(formData)
		setFormValidation(verifyResult)
		setFormErrors(errors)

		if (!verifyStatus) {
			toast.error(errorMessage(errors))
		}
		if (verifyStatus) {
			handleUpdateCustomer(formData)
		}
	}

	useEffect(() => {
		if (customer) {
			setFormData({
				id: customer.id,
				city: customer.address.city,
				postcode: customer.address.postcode.value,
				name: customer.name,
				phone: customer.phone?.value ?? "",
				address_lines: customer.address.lines
			})
		}
	}, [customer, open])

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={toggleModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card className={classes.editCard} variant="outlined">
					<CardHeader
						title="Edit Customer"
						action={
							<IconButton aria-label="settings" onClick={toggleModal}>
								<CloseIcon />
							</IconButton>
						}
					/>
					<CardContent>
						<GridContainer>
							<GridItem xs={12}>
								<FormLabel>
									Name*
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.inputField
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Name",
										name: "name",
										defaultValue: formData.name ?? "",
										onChange: e => handleChangeForm(e)
									}}
									error={!formValidation.name}
									helperText={formErrors.name ?? ''}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={12}>
								<FormLabel>
									Address Lines*
								</FormLabel>
								<MultiLineInput
									formControlProps={{
										fullWidth: true,
										className: classes.paddingTop
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Address lines",
										name: "address_lines",
										defaultValue: formData.address_lines,
										onChange: (e) => handleChangeForm(e)
									}}
									rows={4}
									error={!formValidation.address_lines}
									helperText={formErrors.address_lines ?? ''}								
								/>
							</GridItem>
							<GridItem xs={12}>
								<FormLabel>
									City*
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.inputField
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "City",
										name: "city",
										defaultValue: formData.city ?? "",
										onChange: e => handleChangeForm(e)
									}}
									error={!formValidation.city}
									helperText={formErrors.city ?? ''}
								/>
							</GridItem>
							<GridItem xs={12}>
								<FormLabel>
									Postcode
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.inputField
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Postcode",
										name: "postcode",
										defaultValue: formData.postcode ?? "",
										onChange: e => handleChangeForm(e)
									}}
								/>
							</GridItem>
							<GridItem xs={12}>
								<FormLabel>
									Phone Number
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.inputField
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "phone",
										name: "phone",
										defaultValue: formData.phone ?? "",
										onChange: e => handleChangeForm(e)
									}}
								/>
							</GridItem>
						</GridContainer>
					</CardContent>
					<CardActions className={classes.cardAction}>
						<Button color="info" size="lg" onClick={handleSubmit}>
							Submit
						</Button>
					</CardActions>
				</Card>
			</Fade>
		</Modal>
	);
}

export default CustomerEditModal
