/*
		New page
*/
export const ADD_LINEITEM = "ADD_LINEITEM"
export const CHANGE_LINEITEM = "CHANGE_LINEITEM"
export const CHANGE_LINEITEM_REF = "CHANGE_LINEITEM_REF"
export const REMOVE_LINEITEM = "REMOVE_LINEITEM"
export const CHANGE_USERROLE = "CHANGE_USERROLE"
export const FORMAT_LINEITEMS = "FORMAT_LINEITEMS"
export const RESET_DOOR_TYPES = "RESET_DOOR_TYPES"
export const RESET_DISCOUNTS = "RESET_DISCOUNTS"
export const RESET_VATCODES = "RESET_VATCODES"
/*
		Application eligible page
*/
export const SET_LINEITEMS_FULL_IDS_FOR_ALL_JOBS = "SET_LINEITEMS_FULL_IDS_FOR_ALL_JOBS"
export const TOGGLE_LINEITME_RFA = "TOGGLE_LINEITME_RFA"
export const TOGGLE_LINEITEMS_RFA_FOR_ONE_JOB = "TOGGLE_LINEITEMS_RFA_FOR_ONE_JOB"
