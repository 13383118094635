import React, { useContext } from "react"
import cx from "classnames"
import { Switch, Route, Redirect } from "react-router-dom"
import { ToastContainer } from 'react-toastify'

import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

import Sidebar from "components/Sidebar"
import AdminNavbar from "components/Navbars/AdminNavbar"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { adminRoutes } from "routes.js"

import { userRoles } from "variables/dropdown"
import { UserRoleContext } from "context/UserRole/UserRoleContext"

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js"

var ps;

const useStyles = makeStyles(styles);

export default function AdminLayout(props) {

  const { ...rest } = props;
  
  const {
    changeUserRole,
    userRole,
    rolesAvailableToUser
  } = useContext(UserRoleContext)

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);  
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
 
  
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
 
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={adminRoutes}          
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}        
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(adminRoutes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
       
        <div className={classes.content}>
            <div className={classes.container}>
                <Switch>
                    {getRoutes(adminRoutes)}
                    <Redirect from="/admin" to="/admin/setting" />
                </Switch>
            </div>
            <div>
              <CustomDropdown 
                id="user-dropdown" 
                menuList={ rolesAvailableToUser??userRoles }
                name="userRole"
                classeNames={ classes.userRole }
                defaultValue = {userRole ?? "ReadOnly"}
                handleDropdown={ (e) => changeUserRole(e.target.value) }
              />
            </div>
        </div>          
      </div>
      <ToastContainer autoClose={ 2000 } />
    </div>
  );
}
