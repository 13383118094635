import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import WorkIcon from '@material-ui/icons/Work'
import {Description} from '@material-ui/icons'

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import FormLabel from "@material-ui/core/FormLabel"
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import styles from "assets/jss/material-dashboard-pro-react/views/reportsViewStyle"
import Button from '../../components/CustomButtons/Button'

const useStyles = makeStyles(styles)

const Reports = () => {

  const classes = useStyles()

  let fromDateDefault = new Date(new Date() - 30 * 24 * 3600000)
  fromDateDefault.setHours(0,0,0,0)

  let toDateDefault = new Date()
  toDateDefault.setHours(0,0,0,0)

  const [selectedFromDate, setSelectedFormDate] = useState(fromDateDefault)
  const [selectedToDate, setSelectedToDate] = useState(toDateDefault)

  const handleFromDateChange = (date) => {
    setSelectedFormDate(date)
  }
  const handleToDateChange = (date) => {
    setSelectedToDate(date)
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>Reports</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={8} />
              <GridItem xs={12} sm={6} md={2}>
                <FormLabel>
                  From *
                </FormLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yy"
                    margin="normal"
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    inputVariant="outlined"
                    fullWidth
                    style={{ marginTop: "4px" }}
                    inputProps={{
                      style: { fontSize: 20 },
                      placeholder: "DD/MM/YY",
                      name: "installationDateWc",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </GridItem>
              <GridItem xs={12} sm={6} md={2}>
                <FormLabel>
                  To *
                </FormLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yy"
                    margin="normal"
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    inputVariant="outlined"
                    fullWidth
                    style={{ marginTop: "4px" }}
                    inputProps={{
                      style: { fontSize: 20 },
                      placeholder: "DD/MM/YY",
                      name: "installationDateWc",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </GridItem>
              <GridItem xs={12}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" align="left">
                          <Link target="_blank" href={ `/reports/sage?from=${selectedFromDate.toISODateString()}&to=${selectedToDate.toISODateString()}` }>
                            <MonetizationOnIcon />
                            Sage Sales Report
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" align="left">
                          <Link target="_black" href={ `/reports/applied-value?from=${selectedFromDate.toISODateString()}&to=${selectedToDate.toISODateString()}` }>
                            <Description />
                            Applied Value In Period Report
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" align="left">
                          <Link target="_blank" href={ `/reports/margin?from=${selectedFromDate.toISODateString()}&to=${selectedToDate.toISODateString()}` }>
                            <Button justIcon size={"sm"} round><span style={{fontWeight:"900"}}>%</span> </Button>
                            <span> &nbsp;</span> Margin Report
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" align="left">
                          <Link target="_blank" href={ `/reports/jobs?from=${selectedFromDate.toISODateString()}&to=${selectedToDate.toISODateString()}` }>
                            <WorkIcon />
                            All Jobs Data
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" align="left">
                          <Link target="_black" href={ `/reports/applications?from=${selectedFromDate.toISODateString()}&to=${selectedToDate.toISODateString()}` }>
                            <Description />
                            Applications
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" align="left">
                          <Link target="_blank" href={ `/reports/sage-v1?from=${selectedFromDate.toISODateString()}&to=${selectedToDate.toISODateString()}` }>
                            <MonetizationOnIcon />
                            (Sage Sales Report - old version 2021)
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" align="left">
                          <Link target="_blank" href={ `/reports/snaggings` }>
                            <Description />
                            All snagging items
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" align="left">
                          <Link target="_blank" href={ `/reports/snaggings?liveOnly=true` }>
                            <Description />
                            Live snagging items
                          </Link>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default Reports
