import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/orderItemCollapseStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(styles);

const SnaggingItem = (props) => {
  const { data, handleEditSnagging } = props;
  const classes = useStyles();

  const [ snaggingStatus, setSnaggingStatus] = useState(data.snaggingStatus)
  const handleChangeStatus = (event)=>{
      setSnaggingStatus(event.target.value)
      handleEditSnagging(data.jobId.value, {...data, snaggingStatus:event.target.value})
  }
  
  return (
    <GridContainer className={classes.lineItem}>
      <GridItem xs={12} sm={12} md={2} className={classes.lineItemCell}>
        <p>Snagging {data.id}</p>
      </GridItem>
      <GridItem xs={12} sm={12} md={2} className={classes.lineItemCell}>
        <p>raised {data.raisedDate ? new Date(data.raisedDate).toLocaleDateString() : ""}</p>
      </GridItem>
      <GridItem xs={12} sm={12} md={2} className={classes.lineItemCell}>
        <p>e/c {data.expectedCloseDate ? new Date(data.expectedCloseDate).toLocaleDateString() : ""}</p>
      </GridItem>
      <GridItem xs={12} sm={12} md={3} className={classes.lineItemCell}>
        <p>{data.description}</p>
      </GridItem>
      <GridItem xs={12} sm={12} md={1} className={classes.lineItemCell}>
        <p>{data.raisedby}</p>
      </GridItem>
      <GridItem xs={12} sm={12} md={2} className={classes.lineItemCell}>
          <FormControl variant="outlined" >
              <Select
                  id={ `ddSnaggingStatus-${data.jobId.value}-${data.id}`}
                  value={ snaggingStatus }
                  onChange={ handleChangeStatus }
                  className={ classes.select }
                  name={ `SnaggingStatus-${data.jobId.value}-${data.id}` }
              >
                  <MenuItem value={0}>Live</MenuItem>
                  <MenuItem value={1}>Completed</MenuItem>
              </Select>
          </FormControl>
      </GridItem>
    </GridContainer>
  );
};

export default SnaggingItem;
