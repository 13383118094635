import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import CloseIcon from '@material-ui/icons/Close'

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'

import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormLabel from "@material-ui/core/FormLabel"
import CustomInput from "components/CustomInput/CustomInput.js"
import IconButton from '@material-ui/core/IconButton'
import Button from "components/CustomButtons/Button.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"

import { LineItemContext } from 'context/LineItem/LineItemContext'
import styles from "assets/jss/material-dashboard-pro-react/components/doorsFormStyle"

const useStyles = makeStyles(styles)

export default function EditLineItemModal(props) {

	const classes = useStyles();

	const {
		open,
		toggleModal,
		lineItem,
		handleUpdateLineItem
	} = props

	const { doorTypes } = useContext(LineItemContext)

	const [lineItemData, setLineItemData] = useState({})
	const [selectedDate, setSelectedDate] = useState(null)
	const [doorType, setDoorType] = useState("")

	const handleDateChange = (date) => {
		setSelectedDate(date)
		handleChange({ target: { name: "instructionDate", value: date } })
	}

	const handleChange = (e) => {
		if (lineItemData.isDoor) {
			setLineItemData(prev => {
				if (e.target.name === "type" || e.target.name === "width" || e.target.name === "height" || e.target.name === "colour") {
					prev.door[e.target.name] = e.target.value
				} else {
					prev[e.target.name] = e.target.value
				}
				return prev
			})
		} else {
			setLineItemData(prev => {
				if (e.target.name === "instructedBy" || e.target.name === "instructionDate" || e.target.name === "type") {
					prev.nonDoorVariation[e.target.name] = e.target.value
				} else {
					prev[e.target.name] = e.target.value
				}
				return prev
			})
		}
	}

	const handleChangeDoorType = (e) => {
		setDoorType(e.target.value)
		handleChange(e)
	}

	useEffect(() => {
		!!lineItem && setLineItemData(lineItem)
		!!lineItem && lineItem.isVariation && setSelectedDate(lineItem.isNonDoor.instructionDate)
		!!lineItem && lineItem.isDoor && setDoorType(lineItem.door.type)
	}, [lineItem])

	if (Object.keys(lineItemData) === 0) {
		return (<></>)
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={toggleModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card className={classes.editCard} variant="outlined">
					<CardHeader
						title={lineItemData.isDoor ? "Edit Door" : "Edit Variation"}
						action={
							<IconButton aria-label="close" onClick={toggleModal}>
								<CloseIcon />
							</IconButton>
						}
					/>
					<CardContent>
						<GridContainer>
							{
								lineItemData.isDoor && (
									<>
										<GridItem xs={12} sm={12} md={12}>
											<FormLabel>
												Door Type *
											</FormLabel>
											<CustomDropdown
												id="dropdown"
												menuList={ doorTypes }
												fullWidth
												name="type"
												classeNames={classes.dropdown}
												defaultValue={lineItemData.door.type}
												handleDropdown={handleChangeDoorType}
											/>
										</GridItem>
										{
											doorType === 24 && (
												<GridItem xs={12} sm={12} md={12}>
													<FormLabel>
														Other Type Description *
													</FormLabel>
													<CustomInput
														formControlProps={{
															fullWidth: true,
															className: classes.paddingTop
														}}
														inputProps={{
															type: "text",
															style: { fontSize: 20 },
															placeholder: "Other Type Description",
															name: "customDescription",
															onChange: handleChange,
															defaultValue: lineItemData.customDescription ?? ""
														}}
													/>
												</GridItem>
											)
										}
										<GridItem xs={12} sm={12} md={6}>
											<FormLabel>
												Width *
											</FormLabel>
											<CustomInput
												formControlProps={{
													fullWidth: true,
													className: classes.paddingTop
												}}
												inputProps={{
													type: "number",
													style: { fontSize: 20 },
													placeholder: "Width",
													name: "width",
													onChange: handleChange,
													defaultValue: lineItemData.door.width
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={6}>
											<FormLabel>
												Height *
											</FormLabel>
											<CustomInput
												formControlProps={{
													fullWidth: true,
													className: classes.paddingTop
												}}
												inputProps={{
													type: "number",
													style: { fontSize: 20 },
													placeholder: "Height",
													name: "height",
													onChange: handleChange,
													defaultValue: lineItemData.door.height
												}}
											/>
										</GridItem>
									</>
								)
							}
							{
								lineItemData.isNonDoor && !lineItemData.isDoor && (
									<>
										<GridItem xs={12} sm={12} md={12}>
											<FormLabel>
												Description *
											</FormLabel>
											<CustomInput
												formControlProps={{
													fullWidth: true,
													className: classes.paddingTop
												}}
												inputProps={{
													type: "text",
													style: { fontSize: 20 },
													placeholder: "Description",
													name: "customDescription",
													onChange: handleChange,
													defaultValue: lineItemData.customDescription
												}}
											/>
										</GridItem>
									</>
								)
							}

							<GridItem xs={12} sm={12} md={6}>
								<FormLabel>
									Price(Cost) *
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.paddingTop,
									}}
									inputProps={{
										type: "number",
										style: { fontSize: 20 },
										placeholder: "Price(Cost)",
										name: "costPrice",
										onChange: handleChange,
										defaultValue: lineItemData.costPrice
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={6}>
								<FormLabel>
									Price(Sell) *
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.paddingTop
									}}
									inputProps={{
										type: "number",
										style: { fontSize: 20 },
										placeholder: "Price(Sell)",
										name: "sellPrice",
										onChange: handleChange,
										defaultValue: lineItemData.sellPrice
									}}
								/>
							</GridItem>

							{
								lineItemData.isDoor && (
									<>
										<GridItem xs={12} sm={6} md={6}>
											<FormLabel>
												Colour *
											</FormLabel>
											<CustomInput
												formControlProps={{
													fullWidth: true,
													className: classes.paddingTop
												}}
												inputProps={{
													type: "text",
													style: { fontSize: 20 },
													placeholder: "Colour",
													name: "colour",
													onChange: handleChange,
													defaultValue: lineItemData.door.colour
												}}
											/>
										</GridItem>
									</>
								)
							}
							{
								!lineItemData.isVariation && (
									<GridItem xs={12} sm={12} md={6}>
										<FormLabel>
											Customer Door Ref *
										</FormLabel>
										<CustomInput
											formControlProps={{
												fullWidth: true,
												className: classes.paddingTop
											}}
											inputProps={{
												type: "text",
												style: { fontSize: 20 },
												placeholder: "Customer Door Ref",
												name: "customerRef",
												onChange: handleChange,
												defaultValue: lineItemData.customerRef ?? ""
											}}
										/>
									</GridItem>
								)
							}

							{
								lineItemData.isVariation && (
									<>
										<GridItem xs={12} sm={12} md={6}>
											<FormLabel>
												Instructed By *
											</FormLabel>
											<CustomInput
												formControlProps={{
													fullWidth: true,
													className: classes.paddingTop
												}}
												inputProps={{
													type: "text",
													style: { fontSize: 20 },
													placeholder: "Instructed By",
													name: "instructedBy",
													onChange: handleChange,
													defaultValue: lineItemData.nonDoorVariation.instructedBy
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={6}>
											<FormLabel>
												Instruction Date *
											</FormLabel>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													disableToolbar
													variant="inline"
													format="dd/MM/yyyy"
													margin="normal"
													value={selectedDate}
													onChange={handleDateChange}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
													inputVariant="outlined"
													fullWidth
													style={{ marginTop: "4px" }}
													inputProps={{
														style: { fontSize: 20 },
														placeholder: "DD/MM/YYYY",
														name: "instructionDate",
													}}
												/>
											</MuiPickersUtilsProvider>
										</GridItem>
										<GridItem xs={12} sm={12} md={6}>
											<FormLabel>
												Ascot Ref *
											</FormLabel>
											<CustomInput
												formControlProps={{
													fullWidth: true,
													className: classes.paddingTop
												}}
												inputProps={{
													type: "text",
													style: { fontSize: 20 },
													placeholder: "Ascot Ref",
													name: "ascotRef",
													onChange: handleChange,
													defaultValue: lineItemData.ascotRef
												}}
											/>
										</GridItem>
									</>
								)
							}
						</GridContainer>
					</CardContent>
					<CardActions className={classes.cardAction}>
						<Button color="info" size="lg" onClick={() => handleUpdateLineItem(lineItemData)}>
							Update
						</Button>
					</CardActions>
				</Card>
			</Fade>
		</Modal>
	)
}
