import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'

import AddIcon from '@material-ui/icons/Add';

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { Box } from '@material-ui/core';

import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"

import EnhancedTableHead from "./components/EnhancedTableHead"
import VatRateAddModal from "./components/VatRateAddModal"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { checkUserPermission } from "utils/utils"
import ApiServices from "api/Api.js"

import styles from "assets/jss/material-dashboard-pro-react/views/adminSettingsStyles"

const useStyles = makeStyles(styles)

const headCells = [
	{ id: 'code', numeric: false, disablePadding: true, label: 'Code' },
	{ id: 'percent', numeric: false, disablePadding: false, label: 'Rate' },
	{ id: 'startDate', numeric: false, disablePadding: false, label: 'Start Date' },
	{ id: 'description', numeric: false, disablePadding: false, label: 'Description' }
];

const VatRates = () => {

	const classes = useStyles()

	const { userRole, permissions } = useContext(UserRoleContext)
	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "AdminScreens", userRole))
	}, [userRole])

	const [vatRates, setVatRates] = useState([])
	const [openAddModal, setOpenAddModal] = useState(false)
	
	const getVatRates = async () => {
		const res = await ApiServices.getVatRates()
		if (res.status === 200) {
			setVatRates(res.data)
		}
	}

	useEffect(() => {
		getVatRates()
	}, [])

	return (
		<GridItem xs={12}>
			<Card>
				<CardHeader color="rose" text>
					<CardText color="rose">
						<h4 className={classes.cardTitle}>VAT Rates</h4>
					</CardText>
				</CardHeader>
				<CardBody>
					<Box textAlign="right">
						<Button
							size="lg"
							startIcon={<AddIcon />}
							disabled={disabled}
							onClick={() => setOpenAddModal(true)}
						>
						Add
						</Button>
					</Box>
					<TableContainer>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							size="medium"
							aria-label="enhanced table"
						>
							<EnhancedTableHead
								headCells={headCells}
								classes={classes}
								rowCount={vatRates.length}
							/>
							<TableBody>
								{
									vatRates									
										.map((row, index) => {
											const labelId = `enhanced-table-checkbox-${index}`;
											return (
												<TableRow
													hover
													tabIndex={-1}
													key={`${index}-${row.code}`}
												>
													<TableCell align="left" width="10%">
														{index + 1}
													</TableCell>
													<TableCell component="th" id={labelId} scope="row">
														{row.code}
													</TableCell>
													<TableCell align="left" >
														{ row.percent === 0 ? row.percent : `${ row.percent }%` }
													</TableCell>
													<TableCell align="left" >
														{ row.startDate }
													</TableCell>
													<TableCell align="left" >
														{ row.description }
													</TableCell>
												</TableRow>
											);
										})}
							</TableBody>
						</Table>
					</TableContainer>				
				</CardBody>
			</Card>	
			<VatRateAddModal 
				open={ openAddModal }
				closeModal={ () => setOpenAddModal(false) }
				handleSubmit={(vatRate) => {
					console.log(vatRate)
					ApiServices.addNewVatRate(vatRate)
						.then(() => {							
							toast.success("Vat Rate created!")
							getVatRates()
						})
						.catch((err) => {							
							toast.error(err.response.data)
						})
						.finally(() => {
							setOpenAddModal(false)
						})
				}}
			/>		
		</GridItem>
	)
}

export default VatRates
