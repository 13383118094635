import {
    cardTitle,
    whiteColor,
} from "assets/jss/material-dashboard-pro-react.js"
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js"
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js"

const downloadViewStyles = theme => ({
    ...customCheckboxRadioSwitch,
    cardTitle: {
        ...cardTitle,
        color: whiteColor
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        display: "none"
    },
    highlight: {
        color: "rgba(0, 0, 0, 0.87)",
        display: "flex"
    },
    title: {
        flex: '1 1 100%',
        fontSize: "20px"
    },
    tablePaper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: "unset",
        "& th, td": {
            fontSize: "20px"
        },
        "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
            background: "rgba(0, 0, 0, 0.04)"
        },
        "& tbody tr": {
            cursor: "pointer",
            height: "61px"
        }
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    ...buttonStyle
})

export default downloadViewStyles
