import React from 'react'
import PropTypes from 'prop-types'

import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

export default function EnhancedTableHead(props) {
	const { headCells } = props

	return (
		<TableHead>
			<TableRow>
				<TableCell align="left" padding="normal">

				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding="normal"
					>
						{headCell.label}
					</TableCell>
				))}
				<TableCell align="right" padding="normal">
					Actions
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	rowCount: PropTypes.number.isRequired
}
