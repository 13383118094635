import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'
import SweetAlert from "react-bootstrap-sweetalert"

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';

import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"

import EnhancedTableHead from "./EnhancedTableHead"
import DoorEditModal from "./DoorEditModal"

import ApiServices from "api/Api.js"
import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { checkUserPermission } from "utils/utils"

import styles from "assets/jss/material-dashboard-pro-react/views/adminSettingsStyles"

const useStyles = makeStyles(styles)

const headCells = [
	{ id: 'value', numeric: false, disablePadding: false, label: 'Description' }
];

export default function Doors() {

	const classes = useStyles()

	const { userRole, permissions } = useContext(UserRoleContext)
	const [disabled, setDisabled] = useState(false)
	const [isCreate, setIsCreate] = useState(true)
	const [alert, setAlert] = useState(null)

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "AdminScreens", userRole))
	}, [userRole])

	const [doorsList, setDoorsList] = useState([])

	const [openEditModal, setOpenEditModal] = useState(false)
	const [doorKey, setDoorKey] = useState("")

	const toggleEditModal = () => {
		setOpenEditModal(!openEditModal)
	}

	const handleOpenEditModal = (key) => {
		setIsCreate(false)
		setDoorKey(key)
		toggleEditModal()
	}

	const getCofigLists = async () => {
		const res = await ApiServices.getConfigLists()
		console.log(res)
		if (res.status === 200) {
			setDoorsList(res.data.doorTypes)
		}
	}

	const handleSubmit = async (door) => {
		if (isCreate) {
			ApiServices.addNewDoorType(door.value).then(() => {
				toast.success("Door added.")
				getCofigLists();
			}).catch(() => {
				toast.error("Failed")
			})
		} else {
			const res = await ApiServices.editDoorType({ doorTypeKey: doorKey, doorType: door.value })
			if (res.status === 200) {
				toast.success("Door type changed.")
				setDoorsList(res.data)
			} else {
				toast.error("Something went wrong. Reload the page and try again?")
			}
		}

		toggleEditModal()
	}

	const handleRemoveDoorType = async (doorTypeKey) => {

		setAlert(
			<SweetAlert
				warning
				style={{ display: "block", marginTop: "-100px" }}
				title="Are you sure?"
				onConfirm={() => removeDoorType(doorTypeKey)}
				onCancel={() => setAlert(null)}
				confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.alertButton}
				cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
				confirmBtnText="Yes"
				cancelBtnText="Cancel"
				showCancel
			>
			</SweetAlert>
		)
	}

	const removeDoorType = async (doorTypeKey) => {
		const res = await ApiServices.removeDoorType(doorTypeKey)
		if (res.status === 200) {
			toast.success("Door type removed.")
			setDoorsList(res.data)
		} else {
			toast.error("Something went wrong. Reload the page and try again?")
		}
		setAlert(null)
	}

	useEffect(() => {
		getCofigLists()
	}, [])

	return (
		<GridItem xs={12}>
			<Card>
				<CardHeader color="rose" text>
					<CardText color="rose">
						<h4 className={classes.cardTitle}>Door Types Available</h4>
					</CardText>
				</CardHeader>
				<CardBody>
					<Box textAlign="right">
						<Button
							size="lg"
							startIcon={<AddIcon />}
							disabled={disabled}
							onClick={() => { setIsCreate(true); toggleEditModal() }}
						>
							Add
						</Button>
					</Box>
					<TableContainer>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							size="medium"
							aria-label="enhanced table"
						>
							<EnhancedTableHead
								headCells={headCells}
								classes={classes}
								rowCount={doorsList.length}
							/>
							<TableBody>
								{
									doorsList
										.map((row, index) => {
											const labelId = `enhanced-table-checkbox-${index}`;
											return (
												<TableRow
													hover
													tabIndex={-1}
													key={`${row.key}`}
												>
													<TableCell align="left" width="10%">
														{index + 1}
													</TableCell>
													<TableCell align="left" >
														{
															row.value
														}
													</TableCell>
													<TableCell align="right">
														<IconButton onClick={() => handleOpenEditModal(row.key)} disabled={disabled}>
															<EditIcon className={classes.editIcon} />
														</IconButton>
														{
															row.key !== "OtherBoughtInProduct" && (
																<IconButton onClick={() => handleRemoveDoorType(row.key)} disabled={disabled}>
																	<DeleteIcon color="secondary" />
																</IconButton>
															)
														}
													</TableCell>
												</TableRow>
											);
										})}
							</TableBody>
						</Table>
					</TableContainer>
				</CardBody>
			</Card>
			<DoorEditModal
				open={openEditModal}
				toggleModal={toggleEditModal}
				door={doorsList.find(door => door.key === doorKey)}
				handleSubmit={handleSubmit}
				isCreate={isCreate}
			/>
			{alert}
		</GridItem>
	)
}
