import React, { createContext, useReducer } from 'react'

import {
	TOGGLE_LINEITME_RFA,
	TOGGLE_LINEITEMS_RFA_FOR_ONE_JOB,
	SET_LINEITEMS_FULL_IDS_FOR_ALL_JOBS
} from "context/actionTypes"
import ApplicationEligibleReducer from "./ApplicationsEligibleReducer"

export const ApplicationEligibleContext = createContext()

const initialState = {
	SoTotalPrice: 0,
	NonSoTotalPrice: 0,
	lineItemsReadyForApplication: []
}

const ApplicationEligibleProvider = ({ children }) => {

	const [state, dispatch] = useReducer(ApplicationEligibleReducer, initialState)

	const setLineItemsFullIdsOfAllJobs = (readyJobs) => dispatch(
		{
			type: SET_LINEITEMS_FULL_IDS_FOR_ALL_JOBS,
			payload: readyJobs
		})

	const toggleLineItemRFA = (jobId, lineItemFullId, applicationValue, jobAreaCode) => dispatch({
		type: TOGGLE_LINEITME_RFA,
		payload: {
			jobId: jobId,
			lineItemFullId: lineItemFullId,
			applicationValue: applicationValue,
			jobAreaCode: jobAreaCode			
		}
	})

	const toggleAllLineItemsRFA = (jobId, lineItemFullIds, jobAreaCode, applicationValueList, allSelected) => dispatch({
		type: TOGGLE_LINEITEMS_RFA_FOR_ONE_JOB,
		payload: {
			jobId: jobId,
			lineItemFullIdList: lineItemFullIds,
			applicationValueList: applicationValueList,
			jobAreaCode: jobAreaCode,
			allSelected: allSelected
		}
	})

	const contextValue = {
		...state,
		setLineItemsFullIdsOfAllJobs,
		toggleLineItemRFA,
		toggleAllLineItemsRFA
	}

	return (
		<ApplicationEligibleContext.Provider value={contextValue}>
			{children}
		</ApplicationEligibleContext.Provider>
	)
}

export default ApplicationEligibleProvider
