import customerJobStyle from "assets/jss/material-dashboard-pro-react/views/customerJobStyle"

const jobStyle = {
	...customerJobStyle,
	attendancesWrapper: {
		overflowX: "auto"
	},
	installationBtns: {
		height: "100%"
	},
	installationBtnGroup: {
		// display: "flex",
		// flexDirection: "column",
		// justifyContent: "space-between",
		// paddingTop: "21px !important",
		// paddingBottom: "14px !important"
		textAlign: "right"
	},
	saveBtn: {
		width: "fit-content",
		marginLeft: "16px"
	},
	dropdown: {
		marginTop: "4px"
	},
	fieldset: {
		display: "flex",
		flexWrap: "wrap",
		marginBottom: "16px",
		borderWidth: "1px",
		borderColor: "rgba(0, 0, 0, 0.23)"
	},
	errorLabel: {
		color: "#f44336 !important",
		marginTop: "-6px"
	},
	documentItem: {
		"& .MuiBox-root": {
			width: "calc(100% - 56px)",
			"& p": {
				fontSize: "20px"
			}
		},
		"& .MuiSvgIcon-root": {
			color: "#00acc1"
		}
	},
	documentCard: {
		marginBottom: "1rem",
		"& .MuiCardContent-root": {
			padding: "1.5rem 1rem",		
		}
	},
	oldJobId: {
		"& .MuiFormLabel-root": {
			color: "rgba(0, 0, 0, 0.2)"
		}
	}
}

export default jobStyle
