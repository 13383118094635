import React, { useState, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import { makeStyles } from "@material-ui/core/styles"

import FormLabel from "@material-ui/core/FormLabel"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup"
import Button from "components/CustomButtons/Button.js"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { LineItemContext } from "context/LineItem/LineItemContext"
import { checkUserPermission, errorMessage } from "utils/utils"
import { verifyNettOrDiscountForm, initialNettOrDiscountValidation } from "utils/validations"

import styles from "assets/jss/material-dashboard-pro-react/components/doorsFormStyle"

const useStyles = makeStyles(styles)

function CheckDoorsForm(props) {

	const {
		jobData,
		newDoorCostPrice,
		newDoorSellPrice,
		updateNettOrDiscount
	} = props

	const classes = useStyles()

	const [correctPrice, setCorrectPrice] = useState("yes")
	const [nettOrDiscount, setNettOrDiscount] = useState(jobData.nettOrDiscount.isNett ? 'nett' : 'less')
	const [discount, setDiscount] = useState(!!jobData.nettOrDiscount.other ? "other" : jobData.nettOrDiscount.percentDiscount)
	const [otherDiscount, setOtherDiscount] = useState(jobData.nettOrDiscount.other ?? "")
	const [discountedSellPrice, setDiscountedSellPrice] = useState(0)
	const [totalCostPrice, setTotalCostPrice] = useState(0)
	const [totalSellPrice, setTotalSellPrice] = useState(0)

	const [formValidation, setFormValidation] = useState(initialNettOrDiscountValidation)


	const is_total_price_correct_radios = [
		{
			value: 'yes',
			label: 'Yes'
		},
		{
			value: 'no',
			label: 'No'
		}
	]
	const nett_less_discount_radios = [
		{
			value: 'nett',
			label: 'NETT'
		},
		{
			value: 'less',
			label: 'LESS'
		}
	]

	const { userRole, permissions } = useContext(UserRoleContext)
	const { discounts } = useContext(LineItemContext)

	const [disabled, setDisabled] = useState(false)

	const handleChangeNettOrDisscount = (e) => {
		setNettOrDiscount(e.target.value)
		setDiscount("")
		setOtherDiscount("")
		setDiscountedSellPrice("")
	}

	const handleSubmitNettOrDiscount = () => {

		const { status, verifyResult, errors } = verifyNettOrDiscountForm({
			total_price_correct: correctPrice,
			nett_less_discount: nettOrDiscount,
			discount: discount,
			other_discount: otherDiscount
		})

		setFormValidation(verifyResult)
		if (!status) {
			toast.error(errorMessage(errors))
		}
		if (status) {
			let formBody = {}
			if (nettOrDiscount === "nett") {
				formBody = {
					"isNettOrDiscount": 0
				}
			} else if (nettOrDiscount === "less") {
				if (discount !== "other") {
					formBody = {
						"isNettOrDiscount": 1,
						"percentDiscount": parseFloat(discount)
					}
				}
				else if (discount === "other") {
					formBody = {
						"isNettOrDiscount": 1,
						"other": otherDiscount
					}
				}
			}
			updateNettOrDiscount(formBody)
		}

	}

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "RaiseOrder", userRole))
	}, [userRole, permissions])


	useEffect(() => {
		let costPrice = 0;
		let sellPrice = 0;

		jobData.lineItems.forEach(lineItem => {
			costPrice += lineItem.costPrice;
			sellPrice += lineItem.sellPrice;
		})

		setTotalCostPrice(costPrice + parseInt(newDoorCostPrice))
		setTotalSellPrice(sellPrice + parseInt(newDoorSellPrice))

		if (nettOrDiscount === "less") {
			if (parseFloat(discount)) {
				setDiscountedSellPrice((sellPrice + parseInt(newDoorSellPrice)) * (1 - parseFloat(discount) * 0.01))
			} else if (discount === "other" && otherDiscount === "") {
				setDiscountedSellPrice(totalSellPrice)
			} else if (discount === "other" && parseFloat(otherDiscount) ) {
				setDiscountedSellPrice((sellPrice + parseInt(newDoorSellPrice)) * (1 - parseFloat(otherDiscount) * 0.01))
			}
		}
	}, [newDoorCostPrice, newDoorSellPrice, jobData, discount, otherDiscount])

	return (
		<GridContainer>
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					Total Cost Price
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.paddingTop
					}}
					inputProps={{
						type: "text",
						style: { fontSize: 20 },
						placeholder: "Total Price(Cost)",
						name: "total_price_cost",
						readOnly: true,
						value: isNaN(totalCostPrice) ? "" : totalCostPrice.toFixed(2),
						onChange: () => {}
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					Total Sell Price
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.paddingTop
					}}
					inputProps={{
						type: "text",
						style: { fontSize: 20 },
						placeholder: "Total Price(Sell)",
						name: "total_price_sell",
						readOnly: true,
						value: isNaN(totalSellPrice) ? "" : totalSellPrice.toFixed(2)
					}}
				/>
			</GridItem>
			{
				nettOrDiscount === "less" ? (
					<GridItem xs={12} sm={6} md={4}>
						<FormLabel>
							Discounted Sell Price
						</FormLabel>
						<CustomInput
							formControlProps={{
								fullWidth: true,
								className: classes.paddingTop
							}}
							inputProps={{
								type: "text",
								style: { fontSize: 20 },
								placeholder: "Discounted Sell Price",
								name: "discountedSellPrice",
								readOnly: true,
								value: (nettOrDiscount !== "less") ? "" : discountedSellPrice && discountedSellPrice.toFixed(2) || "",
								onChange: () => {}
							}}
						/>
					</GridItem>
				) : <GridItem xs={12} sm={6} md={4} />
			}

			<GridItem xs={12} sm={12} md={6} lg={3}>
				<CustomRadioGroup
					title="Is Total Price Correct? *"
					name="total_price_correct"
					radioItems={is_total_price_correct_radios}
					value={ correctPrice }
					handleRadio={(e) => { setCorrectPrice(e.target.value) }}
					error={!formValidation.total_price_correct}
					disabled={disabled}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={6} lg={3}>
				<CustomRadioGroup
					title="Is this NETT or Less Discount?*"
					name="nett_less_discount"
					radioItems={nett_less_discount_radios}
					disabled={disabled}
					handleRadio={(e) => handleChangeNettOrDisscount(e)}
					value={ nettOrDiscount }
				/>
			</GridItem>
			{
				nettOrDiscount === 'less' ?
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<FormLabel>
							Discount *
						</FormLabel>
						<CustomDropdown
							menuList={ discounts }
							fullWidth
							name="discount"
							classeNames={classes.dropdown}
							defaultValue={discount}
							handleDropdown={(e) => {
								setDiscount(e.target.value);
							}}
							error={!formValidation.discount}
						/>
					</GridItem>
					: <></>
			}
			{
				discount === "other" ?
					<GridItem xs={12} sm={6} md={6} lg={3} >
						<FormLabel>
							Other Discount *
						</FormLabel>
						<CustomInput
							formControlProps={{
								fullWidth: true,
								className: classes.paddingTop
							}}
							inputProps={{
								type: "number",
								style: { fontSize: 20 },
								placeholder: "Other Discount",
								name: "other_discount",
								value: otherDiscount,
								onChange: (e) => {
									setOtherDiscount(e.target.value);
								}
							}}
							error={!formValidation.other_discount}
						/>
					</GridItem>
					: <></>
			}
			<GridItem xs={12} >
				<Button
					onClick={ handleSubmitNettOrDiscount }
					color="info"
					size="lg"
				>
					All Done
				</Button>
			</GridItem>
		</GridContainer>
	)
}

export default CheckDoorsForm
