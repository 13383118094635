import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { toast } from "react-toastify";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import AddIcon from "@material-ui/icons/Add";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MultiLineInput from "components/CustomInput/MultiLineInput.js";
import Input from "components/CustomInput/CustomInput.js";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "components/CustomButtons/Button.js";

import { verifySnaggingForm } from "utils/validations";
import { errorMessage } from "utils/utils";

import styles from "assets/jss/material-dashboard-pro-react/views/orderItemCollapseStyle.js";

const useStyles = makeStyles(styles);
const SnaggingForm = (props) => {
  const { handleAddSnagging } = props;
  const classes = useStyles();

  const [formData, setFormData] = useState({
    raisedDate: null,
    expectedCloseDate: null,
    description: "",
  });
  const [formValidation, setFormValidation] = useState({
    raisedDate: true,
    expectedCloseDate: true,
    description: true,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChangeFormData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitSnagging = () => {
    const { verifyStatus, verifyResult, errors } = verifySnaggingForm(formData);
    setFormValidation(verifyResult);
    setFormErrors(errors);

    if (!verifyStatus) {
      toast.error(errorMessage(errors));
      return;
    }
    handleAddSnagging(formData);
  };

  return (
    <GridContainer className={classes.variantFormWrapper}>
      <GridItem xs={12} sm={6} md={4}>
        <FormLabel>Raised Date *</FormLabel>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={formData.raisedDate}
            onChange={(date) => handleChangeFormData("raisedDate", date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            inputVariant="outlined"
            fullWidth
            style={{ marginTop: "4px" }}
            inputProps={{
              style: { fontSize: 20 },
              placeholder: "DD/MM/YYYY",
              name: "raisedDate",
            }}
            error={!formValidation.raisedDate}
          />
        </MuiPickersUtilsProvider>
        {formValidation.raisedDate !== undefined ? (
          <FormHelperText id="installationDate" className={classes.errorLabel}>
            {formErrors.raisedDate}
          </FormHelperText>
        ) : null}
      </GridItem>
      <GridItem xs={12} sm={6} md={4}>
        <FormLabel>Expected close out date *</FormLabel>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={formData.expectedCloseDate}
            onChange={(date) => handleChangeFormData("expectedCloseDate", date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            inputVariant="outlined"
            fullWidth
            style={{ marginTop: "4px" }}
            inputProps={{
              style: { fontSize: 20 },
              placeholder: "DD/MM/YYYY",
              name: "expectedCloseDate",
            }}
            error={!formValidation.expectedCloseDate}
          />
        </MuiPickersUtilsProvider>
        {formValidation.expectedCloseDate !== undefined ? (
          <FormHelperText id="installationDate" className={classes.errorLabel}>
            {formErrors.expectedCloseDate}
          </FormHelperText>
        ) : null}
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={12}
        className={classes.snaggingDescriptionInput}
      >
        <FormLabel>Description *</FormLabel>
        <MultiLineInput
          formControlProps={{
            fullWidth: true,
            className: classes.paddingTop,
          }}
          inputProps={{
            type: "text",
            style: { fontSize: 20 },
            placeholder: "Description",
            name: "description",
            onChange: (e) =>
              handleChangeFormData("description", e.target.value),
            value: formData.description,
          }}
          rows={2}
          helperText={formErrors.description ?? ""}
          error={!formValidation.description}
        />
      </GridItem>
      <GridItem
          xs={12}
          sm={12}
          md={12}
          className={classes.snaggingDescriptionInput}
      >
        <FormLabel>Raised By</FormLabel>
        <Input
            formControlProps={{
              fullWidth: true,
              className: classes.paddingTop,
            }}
            inputProps={{
              type: "text",
              style: { fontSize: 20 },
              placeholder: "Raised By",
              name: "raisedBy",
              onChange: (e) =>
                  handleChangeFormData("raisedBy", e.target.value),
              value: formData.raisedBy,
            }}
            helperText="optional - who raised this"
        />
      </GridItem>
      <GridItem xs={12} md={3} className={classes.variantBtnWrapper}>
        <Button
          color="info"
          size="lg"
          className={classes.variantBtn}
          onClick={handleSubmitSnagging}
          fullWidth
        >
          <AddIcon className={classes.icons} /> Submit Snagging
        </Button>
      </GridItem>
    </GridContainer>
  );
};

export default SnaggingForm;
