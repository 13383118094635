import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";

import Box from "@material-ui/core/Box";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import FormLabel from "@material-ui/core/FormLabel";

import SnaggingLineItem from "./SnaggingLineItem";
import SnaggingForm from "components/JobList/SnaggingForm";

import styles from "assets/jss/material-dashboard-pro-react/views/creditCheckStyle";

const useStyles = makeStyles(styles);

const SnaggingSection = (props) => {
  const {
    jobData,
    handleAddSnagging,
    handleEditSnagging,
    handleDeleteSnagging,
  } = props;
  const classes = useStyles();

  const [alert, setAlert] = useState(null);
  const [showCreatingForm, setShowCreatingForm] = useState(false);

  const hideAlert = () => {
    setAlert(null);
  };

  const handleConfirmAlert = (snagging) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          hideAlert();
          handleAddSnagging(snagging);
          setShowCreatingForm(false);
        }}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnCssClass={
          classes.button + " " + classes.success + " " + classes.alertButton
        }
        cancelBtnCssClass={
          classes.button + " " + classes.danger + " " + classes.alertButton
        }
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4 className={classes.cardTitle}>Snagging</h4>
          </CardText>
        </CardHeader>
        <CardBody className={classes.cardBodyPadding}>
          <GridContainer className={classes.justifyCenter}>
            {jobData.snaggings &&
              jobData.snaggings.map((snagging) => (
                <SnaggingLineItem
                  key={snagging.fullId.asString}
                  snaggingItem={snagging}
                  handleEdit={() => handleEditSnagging(snagging.id)}
                  handleDelete={() => handleDeleteSnagging(snagging.id)}
                />
              ))}
            {showCreatingForm && (
              <GridItem xs={12}>
                <SnaggingForm handleAddSnagging={handleConfirmAlert} />
              </GridItem>
            )}
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Button
                color="info"
                size="lg"
                fullWidth
                onClick={() => setShowCreatingForm(true)}
              >
                ADD SNAGGING
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {alert}
    </GridItem>
  );
};

export default SnaggingSection;
