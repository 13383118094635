import React, {useContext} from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'

import styles from "assets/jss/material-dashboard-pro-react/views/searchStyle.js"
import {checkUserPermission} from '../../../utils/utils'
import {UserRoleContext} from '../../../context/UserRole/UserRoleContext'
import {toast} from 'react-toastify'

const useStyles = makeStyles(styles)

function CustomerLink(props) {

	const { name, id, history} = props
	const { userRole, permissions } = useContext(UserRoleContext)

	const handleLink = (e) => {
		e.preventDefault()
		if(checkUserPermission(permissions, "RaiseOrder", userRole)){
			history.push(`/new/${id}`)
		}
		else{
			toast.warn("Only sales can raise a new order.")
		}
	}

	const classes = useStyles()

	return checkUserPermission(permissions, "RaiseOrder", userRole)
			? (<Tooltip title={<p className={classes.toolTipTitle}>Raise new order for<br />{name}</p>}
					placement="right-start">
					<Link href="#" onClick={e => handleLink(e)}>
						{name}
					</Link>
				</Tooltip>)
			:(<p>{name}</p>)
}

export default withRouter(CustomerLink)
