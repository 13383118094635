import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MultiLineInput from "components/CustomInput/MultiLineInput.js";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/components/doorsFormStyle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "../../../components/CustomInput/CustomInput";

const useStyles = makeStyles(styles);

const SnaggingLineItem = (props) => {
  const { snaggingItem, handleEdit, handleDelete } = props;
  const classes = useStyles();

  return (
    <GridItem xs={12}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={2} className={classes.disableEvent}>
          <FormLabel>Raised date</FormLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              value={snaggingItem.raisedDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              inputVariant="outlined"
              fullWidth
              style={{ marginTop: "4px" }}
              inputProps={{
                style: { fontSize: 20 },
                placeholder: "DD/MM/YYYY",
                name: "raisedDate",
              }}
            />
          </MuiPickersUtilsProvider>
        </GridItem>
        <GridItem xs={12} sm={6} md={2} className={classes.disableEvent}>
          <FormLabel>Exp. close date</FormLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              value={snaggingItem.expectedCloseDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              inputVariant="outlined"
              fullWidth
              style={{ marginTop: "4px" }}
              inputProps={{
                style: { fontSize: 20 },
                placeholder: "DD/MM/YYYY",
                name: "expectedCloseDate",
              }}
            />
          </MuiPickersUtilsProvider>
        </GridItem>
        <GridItem xs={12} sm={6} md={2} className={classes.disableEvent}>
          <FormLabel>Raised by</FormLabel>
          <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.paddingTop
              }}
              inputProps={{
                type: "text",
                style: { fontSize: 20 },
                name: "raised-by",
                value: snaggingItem.raisedBy
              }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={2} className={classes.disableEvent}>
          <FormLabel>Status</FormLabel>
          <FormControl variant="outlined" fullWidth style={{ marginTop: "4px" }}>
            <Select
                value={ snaggingItem.snaggingStatus }
                className={ classes.select }
            >
              <MenuItem value={snaggingItem.snaggingStatus}>
                {snaggingItem.snaggingStatus ? "Completed" : "Live" }
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={6} md={2} className={classes.actionBtns}>
          <IconButton
            aria-label="Edit"
            className={classes.editBtnColor}
            onClick={handleEdit}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormLabel>Description</FormLabel>
          <MultiLineInput
            formControlProps={{
              fullWidth: true,
              className: classes.paddingTop,
            }}
            inputProps={{
              type: "text",
              style: { fontSize: 20 },
              placeholder: "Description",
              name: "description",
              value: snaggingItem.description,
              readOnly: true,
            }}
            rows={2}
          />
        </GridItem>
      </GridContainer>
    </GridItem>
  );
};

export default SnaggingLineItem;
