import { MeetingRoom } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import SettingsIcon from '@material-ui/icons/Settings';

import {
  DesignGuide,
  Search,
  CustomerJob,
  JobList,
  Job,
  ApplicationsEligible,
  AfpFolders,
  DownloadView,
  Reports  
} from "./views"

import {
  VatRates,
  Customers,
  Doors,
  Discounts,
  OpsProcesses
} from "./views/Admin"

export const mainRoutes = [
  {
    path: "/design-guide",
    name: "Design Guide",
    component: DesignGuide,
  },

  {
    path: "/new/:customerId",
    name: "CustomerJob",
    component: CustomerJob,
  },
  {
    path: "/new",
    name: "CustomerJob",
    component: CustomerJob,
  },
  {
    path: "/job/:jobId",
    name: "Job",
    component: Job,
  },
  {
    path: "/job-list",
    name: "JobList",
    component: JobList,
  },
  {
    path: "/applications-eligible",
    name: "ApplicationsEligible",
    component: ApplicationsEligible
  },
  {
    path: "/applications-ready/:afpDate",
    name: "DownloadView",
    component: DownloadView
  },
  {
    path: "/applications-ready",
    name: "AfpFolders",
    component: AfpFolders
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports
  }, 
  {
    path: "/",
    name: "Search",
    component: Search,
  }
];

export const adminRoutes = [
  {
    path: "/doors",
    name: "Doors",
    icon: MeetingRoom,
    component: Doors,
    layout: "/admin"
  },
  {
    path: "/discounts",
    name: "Discounts",
    icon: "%",
    component: Discounts,
    layout: "/admin"
  },
  {
    path: "/vat-rates",
    name: "VatRates",
    icon: MonetizationOnIcon,
    component: VatRates,
    layout: "/admin"
  },
  {
    path: "/customers",
    name: "Customers",
    icon: PersonIcon,
    component: Customers,
    layout: "/admin"
  },
  {
    path: "/ops-processes",
    name: "Ops Processes",
    icon: SettingsIcon,
    component: OpsProcesses,
    layout: "/admin"
  },
]
