import React, { useEffect, useState } from 'react'

import { makeStyles } from "@material-ui/core/styles"

import SearchIcon from '@material-ui/icons/Search'

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import FormLabel from "@material-ui/core/FormLabel"
import InputAdornment from '@material-ui/core/InputAdornment'
import FilterDropDown from "./FilterDropDown"

import { order_status_filter_list, line_item_status_filter_list } from "variables/dropdown.js"

import styles from "assets/jss/material-dashboard-pro-react/views/jobListStyle.js"

const useStyles = makeStyles(styles)

function SearchFilter(props) {

	const {
		handleSearch,
		handleChangeSearchForm,
		handleFilterOrderStatus,
		handleFilterLineItemsStatus,
		searchKey,
		doorStage,
		jobStage
	} = props

	const [jobStageValue, setJobStageValue] = useState('')

	const handleEnterKey = (e) => {
		if (e.charCode === 13) {
			handleSearch()
		}
	}

	useEffect(() => {
		setJobStageValue(jobStage)
	}, [jobStage])

	const classes = useStyles()

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={5}>
				<FormLabel></FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.paddingTop
					}}
					inputProps={{
						type: "text",
						style: { fontSize: 20 },
						placeholder: "Search for Order",
						name: "searchKey",
						onChange: (e) => handleChangeSearchForm(e),
						onKeyPress: (e) => handleEnterKey(e),
						value: searchKey
					}}
					startAdornment={<InputAdornment position="start"><SearchIcon style={{ color: "grey" }} /></InputAdornment>}
				/>
			</GridItem>
			<GridItem xs={12} md={1}></GridItem>
			<GridItem xs={12} sm={6} md={3}>
				<FormLabel>
					Filter by Order Status *
				</FormLabel>
				<FilterDropDown
					id="ddJobStage"
					menuList={order_status_filter_list}
					fullWidth
					name="job_type"
					classeNames={classes.dropdown}
					value={jobStageValue}
					handleDropdown={(e) => handleFilterOrderStatus(e)}
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={3}>
				<FormLabel>
					Filter by Line Item Status *
				</FormLabel>
				<FilterDropDown
					id="ddDoorStage"
					menuList={[
						...line_item_status_filter_list,
						{
							text: "All Statuses",
							value: "all"
						}
					]}
					fullWidth
					name="door_type"
					classeNames={classes.dropdown}
					value={doorStage}
					handleDropdown={(e) => handleFilterLineItemsStatus(e)}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default SearchFilter
