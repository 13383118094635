import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import SweetAlert from "react-bootstrap-sweetalert"

import GridContainer from "components/Grid/GridContainer.js"
import Customer from "./components/Customer"
import Job from "./components/Job"
import Doors from "./components/Doors"

import { LineItemContext } from 'context/LineItem/LineItemContext'
import { UserRoleContext } from "context/UserRole/UserRoleContext"

import ApiServices from "api/Api.js"

import {
	initialNewJobFormValidation,
	initialNewDoorFormValidation,
	verifyNewJobForm,
	verifyNewDoorForm
} from "utils/validations.js"

import styles from "assets/jss/material-dashboard-pro-react/views/customerJobStyle.js"

import { errorMessage } from "utils/utils"

const useStyles = makeStyles(styles)

function CustomerJob() {

	const { customerId } = useParams()

	const { userRole } = useContext(UserRoleContext)
	const history = useHistory()

	const [alert, setAlert] = React.useState(null)
	const [enableNewJob, setEnableNewJob] = useState(false)
	const [customerData, setCustomerData] = useState({})

	const [jobData, setJobData] = useState({ vatCode: "T21" })
	const [documentFile, setDocumentFile] = useState(undefined)

	const [jobFormValidation, setJobFormValidation] = useState(initialNewJobFormValidation)
	const [jobFormErrors, setJobFormErrors] = useState({})
	const [newCustomerId, setNewCustomerId] = useState('')

	const [newJobId, setNewJobId] = useState('')
	const [enableCreateDoor, setEnablecreateDoor] = useState(false)

	const classes = useStyles()

	useEffect(async () => {

		if (customerId !== undefined) {

			const res = await ApiServices.getCustomer(customerId)
			const customer = res.data
			if (res.status === 200) {
				setCustomerData({
					"id": customer.id,
					"name": customer.name,
					"city": customer.address.city,
					"postcode": customer.address.postcode.value,
					"address_lines": customer.address.lines
				})
			}
			else {
				setAlert(
					<SweetAlert
						style={{ display: "block", marginTop: "-100px" }}
						title="Something went wrong. Please reload the page or logout and back in again."
						onConfirm={() => history.push('/')}
						onCancel={() => history.push('/')}
						confirmBtnCssClass={classes.button + " " + classes.success}
					/>
				);
			}
		}
	}, [userRole])

	const handleAddCustomer = async (customer) => {
		setCustomerData(customer)

		let newCustomer = {
			"name": customer.name,
			"address": {
				"lines": customer.address_lines,
				"city": customer.city,
				"postcode": {
					"value": customer.postcode
				}
			}
		}

		if (customerId !== undefined) {
			newCustomer['id'] = customerId
			const result = await ApiServices.putCustomer(newCustomer)
			if (result.status === 201) {
				toast.success("Existing customer confirmed.")
				setNewCustomerId(result.data.id)
				setEnableNewJob(true)
			}
		} else {
			const result = await ApiServices.createCustomer(newCustomer)
			if (result.status === 201) {
				toast.success("New customer added.")
				setNewCustomerId(result.data.id)
				setEnableNewJob(true)
			}
		}
	}


	const handleJobData = (e) => {
		setJobData(prev => {
			prev[e.target.name] = e.target.value
			if (e.target.name === "documents") {
				setDocumentFile(e.target.files[0])
			}
			return prev
		})
	}

	const addNewJob = async () => {
		const { verifyStatus, verifyResult, errors } = verifyNewJobForm(jobData)
		setJobFormValidation(verifyResult)

		if (!verifyStatus) {
			toast.error(errorMessage(errors))
		}

		setJobFormErrors(errors)
		if (verifyStatus) {
			const postData = {
				"id": jobData.jobId ? { value: parseInt(jobData.jobId) } : null,
				"siteContact": jobData.siteContact,
				"siteContactPhone": jobData.siteContactPhone,
				"siteEmail": jobData.siteEmail ? jobData.siteEmail : "",
				"siteAddress": {
					"lines": jobData.address_lines,
					"city": jobData.city,
					"postcode": {
						"value": jobData.postcode
					}
				},
				"qsContact": jobData.qsContact,
				"qsContactPhone": jobData.qsContactPhone,
				"qsEmail": jobData.qsEmail ? jobData.qsEmail : "",
				"attendanceAgreed": {
					"offload": parseInt(jobData.offload),
					"distribution": parseInt(jobData.distribution),
					"plant": parseInt(jobData.plant),
					"protection": parseInt(jobData.protection),
					"hoisting": parseInt(jobData.hoisting),
					"power": parseInt(jobData.power),
					"parkingOnSite": parseInt(jobData.parkingOnSite),
				},
				"numVisits": parseInt(jobData.numVisits),
				"areaCode": jobData.areaCode,
				"vatCode": jobData.vatCode,
				"installationDateWc": jobData.installationDateWc,
				"customerRef": jobData.customerRef
			}

			if (newJobId === "") {
				let res = await ApiServices.createJob({ customerId: newCustomerId, job: postData })
				if (res.status === 201) {
					toast.success("Order has been raised.")
					setNewJobId(res.data.id.value)
					setEnablecreateDoor(true)
					if (documentFile !== undefined) {
						await ApiServices.uploadDocuments({ jobId: res.data.id.value, document: documentFile })
					}
				}
			} else {
				ApiServices.updateJob({ jobId: newJobId, job: postData }).then(() => {
					toast.success("Order has been updated.")
				}).catch(() => {
					toast.error("Upated failed")
				})
			}
		}
	}

	/*******************
	 * handle doors part
	 * *******************/
	const initialDoorData = {
		type: '',
		otherTypeDescription: '',
		width: '',
		height: '',
		costPrice: '',
		sellPrice: '',
		colour: '',
		no_doors: "",
		customerDoorRefList: []
	}

	const { lineItems, addLiniItem, formatLineItems } = useContext(LineItemContext)

	const [newDoorValidation, setNewDoorValidation] = useState(initialNewDoorFormValidation)
	const [newDoorFormErrors, setNewDoorFormErrors] = useState({})
	
	useEffect(() => {		
		formatLineItems(initialDoorData)	
	}, [])

	const handleConfirmDoor = async () => {
		const { status, verifyResult, errors } = verifyNewDoorForm(lineItems[lineItems.length - 1])

		if (!status) {
			toast.error(errorMessage(errors))
		}
		setNewDoorValidation(verifyResult)
		setNewDoorFormErrors(errors)
		if (status && (newJobId !== "")) {
			addLiniItem(initialDoorData)
		}
	}

	return (
		<GridContainer>
			<Customer
				customerData={customerData}
				handleAddCustomer={handleAddCustomer}
				customerId={newCustomerId}
			/>
			<Job
				enableNewJob={enableNewJob}
				handleJobData={handleJobData}
				createNewJob={addNewJob}
				jobFormValidation={jobFormValidation}
				addNewJob={addNewJob}
				jobFormErrors={jobFormErrors}
				newJobId={newJobId}
			/>
			<Doors
				handleConfirmDoor={handleConfirmDoor}
				newDoorValidation={newDoorValidation}
				newDoorFormErrors={newDoorFormErrors}
				enableCreateDoor={enableCreateDoor}
				newJobId={newJobId}
			/>
			{alert}
		</GridContainer>
	)
}

export default CustomerJob
