import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { makeStyles } from "@material-ui/core/styles"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"

import SearchFilter from "./components/SearchFilter"
import OrderItemCollapse from "components/JobList/OrderItemCollapse"
import CancelLineItemModal from "components/JobList/CancelLineItemModal"
import FileUploadModal from "components/FileUploadModal"

import { UserRoleContext } from "context/UserRole/UserRoleContext"

import ApiServices from "api/Api.js"
import styles from "assets/jss/material-dashboard-pro-react/views/jobListStyle.js"

const useStyles = makeStyles(styles)

function JobList() {

	const [searchResult, setSearchResult] = useState([])
	const [searchKey, setSearchKey] = useState('')
	const [jobStage, setJobStage] = useState('all')
	const [doorStage, setDoorStage] = useState('all')
	const [rerenderView, setRerenderView] = useState(true)
	const [openCancelModal, setOpenCancelModal] = useState(false)
	const [cancelDoorIds, setCancelDoorIds] = useState([])
	const [cancelJobId, setCancelJobId] = useState("")

	const [jobId, setJobId] = useState("")
	const [designLineItemId, setDesignLineItemId] = useState("")
	const [openFileUploadModal, setOpenFileUploadModal] = useState(false)

	const classes = useStyles()

	const { userRole } = useContext(UserRoleContext)

	const handleChangeSearchForm = (e) => {
		setSearchKey(e.target.value)
	}

	const handleFilter = (_jobStage = jobStage, _doorStage = doorStage) => {
		setJobStage(_jobStage)
		setDoorStage(_doorStage)
		ApiServices.getJobsByStatus({ jobStage: _jobStage, doorStage: _doorStage }).then(res => {
			console.log(res)
			if (searchKey === '' || searchKey === undefined) {
				setSearchResult(res.data)
			} else {
				let newFilteredJobList = []
				newFilteredJobList = res.data.filter(item => item.customer.name.toLowerCase().includes(searchKey) ||
					item.id.value == searchKey)
				setSearchResult(newFilteredJobList)
			}
			setRerenderView(!rerenderView)
		}).catch(err => {
			console.log(err)
		})
	}

	const updateLineItemsStage = async (orderId, lineItemIds, type) => {

		if (type === "done") {
			for (var i = 0; i < lineItemIds.length; i++) {
				await ApiServices.putLineItemComplete({ jobId: orderId, lineNumber: lineItemIds[i] })
			}
		}

		handleFilter()
		toast.success("Stage updated.")
	}
	const addVariantDoor = async (variant, type, orderId) => {
		if (type === "non_door") {
			let postData = {
				ascotRef: variant.ascotRef,
				costPrice: variant.costPrice,
				customDescription: variant.description,
				sellPrice: variant.sellPrice,
				stage: variant.stage,
				nonDoorVariation: {
					instructedBy: variant.instructedBy,
					instructionDate: variant.instructionDate,
					type: variant.variantType
				}
			}
			const res = await ApiServices.putNonDoorVariation(orderId, postData)
			if (res.status === 201) {
				handleFilter()
				toast.success("Variant added.")
			}
		} else if (type === "door") {
			let postData = {
				ascotRef: variant.ascotRef,
				costPrice: variant.costPrice,
				customDescription: variant.otherTypeDescription,
				sellPrice: variant.sellPrice,
				stage: variant.stage,
				nonDoorVariation: {
					instructedBy: variant.instructedBy,
					instructionDate: variant.instructionDate,
					type: 3
				},
				door: {
					type: variant.type,
					width: variant.width,
					height: variant.height,
					colour: variant.colour
				}
			}
			const res = await ApiServices.putDoorVariation(orderId, postData)
			if (res.status === 201) {
				handleFilter()
				toast.success("Door added.")
			}
		}
	}

	const handleRerenderView = () => {
		setRerenderView(!rerenderView)
	}

	const toggleCancelModal = () => {
		setOpenCancelModal(!openCancelModal)
	}

	const handleCancelDoors = (jobId, doorIds) => {
		setCancelJobId(jobId)
		setCancelDoorIds(doorIds)
		toggleCancelModal()
	}

	const handleCompleteDoors = async (jobId, doorIds) => {
		await ApiServices.putLineItemsStageComplete({ jobId: jobId, lineNumbers: doorIds }).then(async (res) => {
			console.log(res)
			handleFilter()
			toast.success("Stage updated.")
		}).catch(() => {
			toast.error("Failed stage update.")
		})
	}

	const cancelLineItems = async (instructedBy, instructionDate) => {
		const formData = {
			"lineNumbers": cancelDoorIds,
			"instructedBy": instructedBy,
			"instructionDate": instructionDate
		}
		const res = await ApiServices.putLineItemCancelled(cancelJobId, formData)
		if (res.status === 200) {
			toast.success("A cancellation line item has been raised.")
		} else {
			toast.error("Something went wrong. Reload the page and try again?")
		}
		toggleCancelModal()
		handleFilter()
	}

	const lineItemTryStageRevert = async (jobId, lineNumber, isCancellation) => {
		if(isCancellation){
			await ApiServices.undoCancellation(jobId, lineNumber)
				.then(() => {
					toast.success("Cancellation Undone")
					handleFilter()
				})
				.catch((err) => toast.error(err.response.data))
		}else {
			await ApiServices.postLineItemTryStageRevert(jobId, lineNumber)
				.then(() => {
					toast.success("LineItem Reverted")
					handleFilter()
				})
				.catch((err) => {
					if (err.response.status === 422) {
						toast.error(err.response.data)
					}
				})
		}
	}

	const handleCreateSnagging = async (orderId, snagging) => {
		const res = await ApiServices.createSnaggingItem(orderId, snagging);
    if (res.status === 201) {
      toast.success("Snagging created.");
    } else {
      toast.error("Something went wrong. Reload the page and try again?!");
    }
    handleFilter();
	}
	const handleEditSnagging = async (orderId, snagging) => {

		await ApiServices.editSnaggingItem(orderId, snagging.id, snagging)
		toast.success("Status changed.")
		handleFilter();
	}

	useEffect(async () => {
		let defaultJobStage = 'all'
		let defaultDoorStage = 'all'
		if (userRole === "DrawingOffice") {
			defaultDoorStage = '0'
			defaultJobStage = '1'
		} else if (userRole === "Camming") {
			defaultDoorStage = "1"
			defaultJobStage = '1'
		} else if (userRole === "Production") {
			defaultDoorStage = '2'
			defaultJobStage = '1'
		} else if (userRole === "Installation") {
			defaultDoorStage = '4'
			defaultJobStage = '1'
		} else if (userRole === "Sales" || userRole === "Accounts") {
			defaultDoorStage = 'all'
			defaultJobStage = '0'
		} else {
			defaultJobStage = 'all'
			defaultDoorStage = 'all'
		}
		setJobStage(defaultJobStage)
		setDoorStage(defaultDoorStage)
		// await fetchAllJobs(defaultJobStage, defaultDoorStage)
		handleFilter(defaultJobStage, defaultDoorStage)
	}, [userRole])

	const handleUploadBtn = (jobId, lineItemId) => {
		setJobId(jobId)
		setDesignLineItemId(lineItemId)
		setOpenFileUploadModal(true)
	}

	const uploadDesignFile = async (files) => {
		const _lineItem = searchResult.find(job => job.id.value === jobId).lineItems.find(lineItem => lineItem.id === designLineItemId)

		if (!_lineItem.isVariation) {
			ApiServices.uploadDoorDocuments(jobId, designLineItemId, files[0].file)
			.then((res) => {
				if (res.status === 201) {
					toast.success("Design document successfully uploaded!")
				}
			})
			.catch(() => {
				toast.error("Faild to upload!")
			})
			.finally(() => {
				handleFilter()
			})
		} else {
			ApiServices.uploadVariantDocuments(jobId, designLineItemId, files[0].file)
			.then((res) => {
				if (res.status === 201) {
					toast.success("Design document successfully uploaded!")
				}
			})
			.catch(() => {
				toast.error("Faild to upload!")
			})
			.finally(() => {
				handleFilter()
			})
		}		
	}

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color="rose" text>
						<CardText color="rose">
							<h4 className={classes.cardTitle}>List of Jobs</h4>
						</CardText>
					</CardHeader>
					<CardBody>
						<SearchFilter
							searchKey={searchKey}
							jobStage={jobStage}
							doorStage={doorStage}
							handleSearch={handleFilter}
							handleChangeSearchForm={handleChangeSearchForm}
							handleFilterOrderStatus={(e) => handleFilter(e.target.value, doorStage)}
							handleFilterLineItemsStatus={(e) => handleFilter(jobStage, e.target.value)}
						/>
						{
							searchResult.map((job, index) => (
								<OrderItemCollapse
									key={index}
									jobData={job}
									updateLineItemsStage={updateLineItemsStage}
									handleRerenderView={handleRerenderView}
									addVariantDoor={addVariantDoor}
									handleCancelDoors={handleCancelDoors}
									lineItemTryStageRevert={lineItemTryStageRevert}
									jobStage={jobStage}
									doorStage={doorStage}
									handleUploadBtn={handleUploadBtn}
									handleCompleteDoors={handleCompleteDoors}
									handleCreateSnagging={handleCreateSnagging}
									handleEditSnagging={handleEditSnagging}
								/>
							))
						}
					</CardBody>
				</Card>
			</GridItem>
			<CancelLineItemModal
				toggleModal={toggleCancelModal}
				open={openCancelModal}
				cancelLineItems={cancelLineItems}
			/>
			<FileUploadModal
				open={openFileUploadModal}
				handleClose={() => setOpenFileUploadModal(false)}
				handleSubmit={(files) => {
					uploadDesignFile(files)
					setOpenFileUploadModal(false)
				}}
			/>
		</GridContainer>
	)
}

export default JobList
