import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'
import classnames from "classnames"

import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';

import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { Box } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead'
import IconButton from '@material-ui/core/IconButton';

import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import CustomerEditModal from "./CustomerEditModal"

import styles from "assets/jss/material-dashboard-pro-react/views/adminSettingsStyles"

import ApiServices from 'api/Api.js'
import { checkUserPermission } from "utils/utils"
import { UserRoleContext } from "context/UserRole/UserRoleContext"

const useStyles = makeStyles(styles)

const Customers = () => {

	const classes = useStyles()

	const { userRole, permissions } = useContext(UserRoleContext)

	const [searchKey, setSearchKey] = useState("")
	const [customers, setCustomers] = useState([])
	const [disabled, setDisabled] = useState(false)
	const [openEidtModal, setOpenEditModal] = useState(false)
	const [customerId, setCustomerId] = useState("")

	const searchCustomers = () => {
		if (searchKey !== "") {
			ApiServices.getSearchResult(searchKey).then(res => {
				console.log(res)
				setCustomers(res.data.customers)
			}).catch(err => {
				console.log(err)
			})
		}
	}

	const handleSearch = (e) => {
		if (e.charCode === 13) {
			searchCustomers()
		}
	}

	const handleOpenEditModal = (customerId) => {
		console.log(customerId)
		setCustomerId(customerId)
		setOpenEditModal(true)
	}

	const handleUpdateCustomer = (updatedCustomer) => {
		console.log(updatedCustomer)
		let newCustomer = {
			"id": parseInt(updatedCustomer.id),
			"name": updatedCustomer.name,
			"address": {
				"lines": updatedCustomer.address_lines,
				"city": updatedCustomer.city,
				"postcode": {
					"value": updatedCustomer.postcode
				}
			},
			"phone": {
				"value": updatedCustomer.phone
			}
		}
		ApiServices.putCustomer(newCustomer).then(res => {
			console.log(res)
			setOpenEditModal(false)
			toast.success("Updated successfully!")
			searchCustomers()
		}).catch(err => {
			console.log(err)
			setOpenEditModal(false)
			searchCustomers()
		})
	}

	useEffect(() => {		
		console.log(!checkUserPermission(permissions, "AdminScreens", userRole))
		setDisabled(!checkUserPermission(permissions, "AdminScreens", userRole))
	}, [userRole])

	return (
		<GridItem xs={12}>
			<Card>
				<CardHeader color="rose" text>
					<CardText color="rose">
						<h4 className={classes.cardTitle}>Customers</h4>
					</CardText>
				</CardHeader>
				<CardBody>
					<Box>
						<CustomInput
							id="search-customers"
							formControlProps={{
								fullWidth: true,
								className: classes.searchForm
							}}
							inputProps={{
								type: "text",
								style: { fontSize: 20 },
								placeholder: "Search...",
								onChange: (e) => setSearchKey(e.target.value),
								onKeyPress: (e) => handleSearch(e),							
							}}
							startAdornment={
								<InputAdornment position="start" className={classes.searchIcon}>
									<SearchIcon />
								</InputAdornment>
							}
						/>
					</Box>
					<TableContainer>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							size="medium"
							aria-label="enhanced table"
						>
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										ID
									</TableCell>
									<TableCell align="left" padding="normal">
										Name
									</TableCell>
									<TableCell align="left" padding="normal">
										Address Lines
									</TableCell>
									<TableCell align="left" padding="normal">
										City
									</TableCell>
									<TableCell align="left" padding="normal">
										Postcode
									</TableCell>
									<TableCell align="left" padding="normal">
										Phone Number
									</TableCell>
									<TableCell align="center" padding="normal">
										Edit
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									customers
										.map((row, index) => {
											const labelId = `enhanced-table-checkbox-${index}`;
											return (
												<TableRow
													hover
													tabIndex={-1}
													key={`${row.id}`}
												>
													<TableCell align="left" width="10%">
														{ row.id }
													</TableCell>
													<TableCell component="th" id={labelId} scope="row">
														{ row.name }
													</TableCell>
													<TableCell align="left" >
														{ row.address.lines }
													</TableCell>
													<TableCell align="left" >
														{ row.address.city }
													</TableCell>
													<TableCell align="left" >
														{ row.address.postcode.value }
													</TableCell>
													<TableCell align="left" >
														{ row.phone?.value ?? "" }
													</TableCell>
													<TableCell align="right">
														<IconButton 
															onClick={() => handleOpenEditModal(row.id)} 
															disabled={disabled} 
															className={classnames({[classes.disableEdit]: disabled})}
														>
															<EditIcon className={classes.editIcon} />
														</IconButton>													
													</TableCell>
												</TableRow>
											);
										})}
							</TableBody>
						</Table>
					</TableContainer>
				</CardBody>
			</Card>
			<CustomerEditModal 
				open={openEidtModal}
				toggleModal={() => setOpenEditModal(!openEidtModal)}
				customer={customers.find(customer => customer.id === customerId)}
				handleUpdateCustomer={ handleUpdateCustomer }
			/>
		</GridItem>
	)
}

export default Customers
