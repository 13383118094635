import React, { useEffect, useState, useContext } from 'react'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { toast } from 'react-toastify'
import { useParams } from "react-router-dom"

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import ImageSearchIcon from '@material-ui/icons/ImageSearch'

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'

import { makeStyles } from "@material-ui/core/styles"
import Divider from '@material-ui/core/Divider'
import FormLabel from "@material-ui/core/FormLabel"
import IconButton from '@material-ui/core/IconButton'

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { LineItemContext } from 'context/LineItem/LineItemContext'
import { checkUserPermission, getVariantTypeText } from "utils/utils"

import styles from "assets/jss/material-dashboard-pro-react/components/doorsFormStyle"

const useStyles = makeStyles(styles)

function NonDoorLineItem(props) {

	const {
		lineItem,
		removeLineItem,
		creditNumber,
		handleEditAction,
		jobData,
		handleUploadFileBtn
	} = props

	const classes = useStyles()
	
	const { jobId } = useParams();

	const { userRole, permissions } = useContext(UserRoleContext)
	const { doorTypes } = useContext(LineItemContext)

	const [disabled, setDisabled] = useState(false)

	const handleEditInprogress = () => {
		if (!!jobData.lineItemsReadyForApplication) {
			if (jobData.lineItemsReadyForApplication.map(item => item.id).includes(lineItem.id)) {
				toast.warning("This variation appears on an application for payment")
			} else {
				handleEditAction(lineItem.id)
			}
		} else {
			handleEditAction(lineItem.id)
		}
	}

	const handleDeleteInprogress = () => {
		if (!!jobData.lineItemsReadyForApplication) {
			if (jobData.lineItemsReadyForApplication.map(item => item.id).includes(lineItem.id)) {
				toast.warning("This variation appears on an application for payment")
			} else {
				removeLineItem(lineItem.id)
			}
		} else {
			removeLineItem(lineItem.id)
		}
	}

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "RaiseOrder", userRole))
	}, [userRole, permissions])

	return (
		<GridItem xs={12}>
			<GridContainer>
				<GridItem xs={12} sm={6} md={3}>
					<FormLabel>
						Variant Type *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "text",
							style: { fontSize: 20 },
							placeholder: "Variant Type",
							name: "type",
							readOnly: true,
							value: getVariantTypeText(lineItem.nonDoorVariation.type)
						}}
					/>
				</GridItem>

				{
					lineItem.isNonDoor ? (
						<GridItem xs={12} sm={6} md={3}>
							<FormLabel>
								Description *
							</FormLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true,
									className: classes.paddingTop
								}}
								inputProps={{
									type: "text",
									style: { fontSize: 20 },
									placeholder: "Description",
									name: "description",
									readOnly: true,
									value: lineItem.customDescription
								}}
							/>
						</GridItem>
					) : (
						<>
							<GridItem xs={12} sm={6} md={3}>
								<FormLabel>
									Door Type *
								</FormLabel>
								<CustomDropdown
									id="dropdown"
									menuList={doorTypes}
									fullWidth
									name="type"
									classeNames={classes.dropdown}
									defaultValue={lineItem.door.type}
									readOnly
								/>
							</GridItem>
							{
								lineItem.door.type === 24 ?
									<GridItem xs={12} sm={6} md={3}>
										<FormLabel>
											Other Type Description *
										</FormLabel>
										<CustomInput
											formControlProps={{
												fullWidth: true,
												className: classes.paddingTop
											}}
											inputProps={{
												type: "text",
												style: { fontSize: 20 },
												placeholder: "Other Type Description",
												name: "otherTypeDescription",
												value: lineItem.customDescription
											}}
										/>
									</GridItem>
									: <></>
							}
						</>
					)
				}

				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Price(Cost) *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop,
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Price(Cost)",
							name: "costPrice",
							readOnly: true,
							value: lineItem.costPrice
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Price(Sell) *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Price(Sell)",
							name: "sellPrice",
							readOnly: true,
							value: lineItem.sellPrice
						}}
					/>
				</GridItem>

				{
					lineItem.isDoor && (
						<>
							<GridItem xs={12} sm={6} md={2}>
								<FormLabel>
									Width *
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.paddingTop
									}}
									inputProps={{
										type: "number",
										style: { fontSize: 20 },
										placeholder: "Width",
										name: "width",
										readOnly: true,
										value: lineItem.door.width
									}}
								/>
							</GridItem>

							<GridItem xs={12} sm={6} md={2}>
								<FormLabel>
									Height *
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.paddingTop
									}}
									inputProps={{
										type: "number",
										style: { fontSize: 20 },
										placeholder: "Height",
										name: "height",
										readOnly: true,
										value: lineItem.door.height
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={6} md={2}>
								<FormLabel>
									Colour *
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.paddingTop
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Colour",
										name: "colour",
										readOnly: true,
										value: lineItem.door.colour
									}}
								/>
							</GridItem>
						</>
					)
				}


				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Instructed By *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "text",
							style: { fontSize: 20 },
							placeholder: "Instructed By",
							name: "instructedBy",
							readOnly: true,
							value: lineItem.nonDoorVariation.instructedBy
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2} className={classes.disableEvent}  >
					<FormLabel>
						Instruction Date *
					</FormLabel>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="dd/MM/yyyy"
							margin="normal"
							value={lineItem.nonDoorVariation.instructionDate}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							fullWidth
							style={{ marginTop: "4px" }}
							inputProps={{
								style: { fontSize: 20 },
								placeholder: "DD/MM/YYYY",
								name: "instructionDate",
							}}
						/>
					</MuiPickersUtilsProvider>
				</GridItem>
				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Ascot Ref *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "text",
							style: { fontSize: 20 },
							placeholder: "Ascot Ref",
							name: "ascotRef",
							readOnly: true,
							value: lineItem.ascotRef
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2} className={classes.actionBtns}>
					<IconButton name="uploadDocument" disabled={disabled} className={classes.defaultBtnColor} onClick={() => handleUploadFileBtn(lineItem.id)}>
						<CloudUploadIcon />
					</IconButton>
					{
						!!lineItem.design &&
						<IconButton disabled={disabled} onClick={() => window.open(`/api/jobs/lineitem/documents/${jobId}/${lineItem.id}`, "_blank")}>
							<ImageSearchIcon />
						</IconButton>
					}
					{/* If job stage is In Progress, then it appear edit button */}
					{
						jobData.stage === 1 && (
							<>
								<IconButton
									aria-label="Edit"
									className={classes.editBtnColor}
									onClick={handleEditInprogress} disabled={disabled}
								>
									<EditIcon />
								</IconButton>
								<IconButton color="secondary" aria-label="Delete" onClick={handleDeleteInprogress} disabled={disabled}>
									<DeleteIcon />
								</IconButton>
							</>
						)
					}
					{
						!creditNumber && (
							<>
								<IconButton aria-label="Edit" className={classes.editBtnColor} onClick={() => handleEditAction(lineItem.id)} disabled={disabled}>
									<EditIcon />
								</IconButton>
								<IconButton color="secondary" aria-label="Delete" onClick={() => removeLineItem(lineItem.id)} disabled={disabled}>
									<DeleteIcon />
								</IconButton>
							</>
						)
					}
				</GridItem>
			</GridContainer>
			<Divider className={classes.divider} />
		</GridItem>
	)
}

export default NonDoorLineItem
