import {
	cardTitle,
	whiteColor,
	successColor
} from "assets/jss/material-dashboard-pro-react.js"
import customerJobStyle from "assets/jss/material-dashboard-pro-react/views/customerJobStyle"

const adminSettingsStyles = (theme) => ({
	...customerJobStyle,
	cardTitle: {
		...cardTitle,
		color: whiteColor
	},
	table: {
		"& th": {
			fontSize: "20px"
		},
		"& td": {
			fontSize: "20px"
		},
		"& tbody td": {
			paddingBottom: "0",
			paddingTop: "0"
		}
	},
	editIcon: {
		color: successColor[0]
	},

	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	editCard: {
		maxWidth: "500px",
		"& .MuiCardContent-root": {
			paddingBottom: "0"
		}
	},
	inputField: {
		paddingTop: "4px"
	},
	cardAction: {
		paddingLeft: "16px",
		paddingRight: "16px",
		justifyContent: "flex-end"
	},
	errorLabel: {
		color: "#f44336 !important",
		marginTop: "-3px"
	},
	searchIcon: {
		"& .MuiSvgIcon-root": {
			color: "rgba(0, 0, 0, 0.54)"
		}
	},
	disableEdit: {
		"& .MuiSvgIcon-root": {
			color: "rgba(0, 0, 0, 0.12)"
		}
	},
	fieldset: {
		display: "flex",
		flexWrap: "wrap",
		marginBottom: "16px",
		borderWidth: "1px",
		borderColor: "rgba(0, 0, 0, 0.23)"
	},
	actionBtnWrapper: {
		display: "flex",
		"& .MuiButtonBase-root": {
			marginTop: "auto",
			marginBottom: "17px"
		}
	}
})

export default adminSettingsStyles
