import React, { useState, useContext, useEffect } from 'react'

import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from "@material-ui/core/styles"
import Divider from '@material-ui/core/Divider'
import FormLabel from "@material-ui/core/FormLabel"
import IconButton from '@material-ui/core/IconButton';

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"
import CustomInput from "components/CustomInput/CustomInput.js"

import { LineItemContext } from 'context/LineItem/LineItemContext'

import styles from "assets/jss/material-dashboard-pro-react/components/doorsFormStyle"

const useStyles = makeStyles(styles)

function DoorsForm(props) {

	const {
		index,
		newDoor,
		newDoorValidation,
		lineItem,
		newDoorFormErrors
	} = props

	const classes = useStyles()

	const { changeLineItemRef, changeLineItem, removeLineItem, doorTypes } = useContext(LineItemContext)

	const [doorType, setDoorType] = useState('')
	const [noDoors, setNoDoors] = useState(0)
	const [refDomList, setRefDomList] = useState([])

	const handleChangeDoorType = (e) => {
		setDoorType(e.target.value)
		changeLineItem(e, index)
	}

	const handleChangeNoDoors = (e) => {
		setNoDoors(e.target.value)
		changeLineItem(e, index)
	}

	let customerRefElements = []
	for (let i = 0; i < parseInt(lineItem.no_doors); i++) {
		customerRefElements.push(
			<GridItem xs={12} sm={6} md={2} key={i}>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.paddingTop
					}}
					inputProps={{
						type: "text",
						style: { fontSize: 20 },
						placeholder: "Customer Door Ref",
						name: `customerDoorRef${i}`,
						onChange: (e) => changeLineItemRef(e, index, i),
						value: lineItem.customerDoorRefList[i]
					}}
					error={newDoor && (newDoorValidation.customerDoorRefList.length === noDoors ? !newDoorValidation.customerDoorRefList[i] : false)}
				// helperText={ newDoor && (newDoorValidation.customerDoorRefList.length === noDoors) && !newDoorValidation.customerDoorRefList[i] && (newDoorFormErrors.customerDoorRef ?? '') }
				/>
			</GridItem>
		)
	}

	// const generateRefElements = () => {
	//     console.log("dd")
	//     let customerRefElements = []
	//     for (let i = 0; i < parseInt(lineItem.no_doors); i++) {
	//         customerRefElements.push(
	//             <GridItem xs={12} sm={6} md={2} key={ i }>
	//                 <CustomInput                                   
	//                     formControlProps={{
	//                         fullWidth: true,
	//                         className: classes.paddingTop
	//                     }}
	//                     inputProps={{
	//                         type: "text",
	//                         style: { fontSize: 20 },
	//                         placeholder: "Customer Door Ref",
	//                         name: `customerDoorRef${ i }`,
	//                         onChange: (e) => changeLineItemRef(e, index, i),
	//                         value: lineItem.customerDoorRefList[i]
	//                     }}
	//                     error={ newDoor && (newDoorValidation.customerDoorRefList.length === noDoors ? !newDoorValidation.customerDoorRefList[i] : false) }
	//                     helperText={ newDoor && (newDoorValidation.customerDoorRefList.length === noDoors) && !newDoorValidation.customerDoorRefList[i] && (newDoorFormErrors.customerDoorRef ?? '') }
	//                 />
	//             </GridItem>
	//         )
	//     } 
	//     setRefDomList(customerRefElements)
	// }       

	return (
		<GridItem xs={12}>
			<GridContainer>
				<GridItem xs={12} sm={6} md={4}>
					<FormLabel>
						Door Type *
					</FormLabel>
					<CustomDropdown
						id="dropdown"
						menuList={ doorTypes }
						fullWidth
						name="type"
						classeNames={classes.dropdown}
						handleDropdown={(e) => handleChangeDoorType(e)}
						error={newDoor && (!newDoorValidation.type)}
						value={lineItem.type}
						helperText={newDoor && (newDoorFormErrors.type ?? '')}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Width *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Width",
							name: "width",
							onChange: (e) => changeLineItem(e, index),
							value: lineItem.width
						}}
						error={newDoor && (!newDoorValidation.width)}
						helperText={newDoor && (newDoorFormErrors.width ?? '')}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Height *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Height",
							name: "height",
							onChange: (e) => changeLineItem(e, index),
							value: lineItem.height
						}}
						error={newDoor && (!newDoorValidation.height)}
						helperText={newDoor && (newDoorFormErrors.height ?? '')}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Price(Cost) *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop,
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Price(Cost)",
							name: "costPrice",
							onChange: (e) => changeLineItem(e, index),
							value: lineItem.costPrice
						}}
						error={newDoor && (!newDoorValidation.costPrice)}
						helperText={newDoor && (newDoorFormErrors.costPrice ?? '')}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Price(Sell) *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Price(Sell)",
							name: "sellPrice",
							onChange: (e) => changeLineItem(e, index),
							value: lineItem.sellPrice
						}}
						error={newDoor && (!newDoorValidation.sellPrice)}
						helperText={newDoor && (newDoorFormErrors.sellPrice ?? '')}
					/>
				</GridItem>
				{
					doorType === 24 ?
						<GridItem xs={12} sm={6} md={4}>
							<FormLabel>
								Other Type Description *
							</FormLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true,
									className: classes.paddingTop
								}}
								inputProps={{
									type: "text",
									style: { fontSize: 20 },
									placeholder: "Other Type Description",
									name: "otherTypeDescription",
									onChange: (e) => changeLineItem(e, index),
									value: lineItem.otherTypeDescription
								}}
								error={newDoor && (!newDoorValidation.otherTypeDescription)}
								helperText={newDoor && (newDoorFormErrors.otherTypeDescription ?? '')}
							/>
						</GridItem>
						: <></>
				}

				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Colour *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "text",
							style: { fontSize: 20 },
							placeholder: "Colour",
							name: "colour",
							onChange: (e) => changeLineItem(e, index),
							value: lineItem.colour
						}}
						error={newDoor && (!newDoorValidation.colour)}
						helperText={newDoor && (newDoorFormErrors.colour ?? '')}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						No.Doors *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "No.Doors",
							name: "no_doors",
							onChange: (e) => handleChangeNoDoors(e),
							value: lineItem.no_doors
						}}
						error={newDoor && (!newDoorValidation.no_doors)}
						helperText={newDoor && (newDoorFormErrors.no_doors ?? '')}
					/>
				</GridItem>

				<GridItem xs={12} sm={6} md={2} className={classes.deleteBtnWrapper}>
					<IconButton aria-label="delete" color="secondary" onClick={() => removeLineItem(index)}>
						<DeleteIcon />
					</IconButton>
				</GridItem>
			</GridContainer>
			<GridContainer>
				{
					noDoors > 0 ?
						<GridItem xs={12}>
							<FormLabel>
								Customer Door Ref *
							</FormLabel>
						</GridItem>
						: <></>
				}

				{customerRefElements}
				{/* { 
                    lineItem.no_doors > 0 && [...Array(lineItem.no_doors)].map((e, i) =>
                        <GridItem xs={12} sm={6} md={2} key={ i }>
                            <CustomInput
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.paddingTop
                                }}
                                inputProps={{
                                    type: "text",
                                    style: { fontSize: 20 },
                                    placeholder: "Customer Door Ref",
                                    name: `customerDoorRef${ i }`,
                                    onChange: (e) => changeLineItemRef(e, index, i),
                                    value: lineItem.customerDoorRefList[i]
                                }}
                                error={ newDoor && (newDoorValidation.customerDoorRefList.length === noDoors ? !newDoorValidation.customerDoorRefList[i] : false) }
                                helperText={ newDoor && (newDoorValidation.customerDoorRefList.length === noDoors) && !newDoorValidation.customerDoorRefList[i] && (newDoorFormErrors.customerDoorRef ?? '') }
                            />
                        </GridItem>
                    )                
                } */}
			</GridContainer>
			<Divider className={classes.divider} />
		</GridItem>
	)
}

export default DoorsForm
