import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import { toast } from 'react-toastify'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"

import EnhancedTableToolbar from "./components/EnhancedTableToolbar"
import EnhancedTableHead from "./components/EnhancedTableHead"

import { filterAfps } from "variables/dropdown"

import styles from "assets/jss/material-dashboard-pro-react/views/downloadViewStyles"
import ApiServices from "api/Api.js"
import IconButton from '@material-ui/core/IconButton'
import { PictureAsPdf } from '@material-ui/icons'

const useStyles = makeStyles(styles)

const headCells = [
	{ id: 'applicationId', numeric: false, disablePadding: true, label: 'Select' },
	{ id: 'downloaded', numeric: false, disablePadding: false, label: 'Status' },
	{ id: 'preview', numeric: false, disablePadding: false, label: 'Preview' }
];


export default function DownloadView() {

	const classes = useStyles()

	const { afpDate } = useParams()

	const [selected, setSelected] = useState([]);

	const [afpsData, setAfpsData] = useState([])
	const [filteredData, setFilteredData] = useState([])

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = filteredData.map((n) => `${n.applicationId}`);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (_, applicationId) => {
		const selectedIndex = selected.indexOf(applicationId);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, applicationId);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const isSelected = (applicationId) => selected.indexOf(applicationId) !== -1;

	const handleFilterAfps = (e) => {
		if (e.target.value === 'all') {
			setFilteredData(afpsData)
		} else if (e.target.value === 'downloaded') {
			setFilteredData(afpsData.filter(afp => afp.downloaded !== null))
		} else if (e.target.value === 'not downloaded') {
			setFilteredData(afpsData.filter(afp => afp.downloaded === null))
		}
	}

	const getAfps = async () => {
		const res = await ApiServices.getAfpFiles(afpDate)
		setAfpsData(res.data)
		setFilteredData(res.data)
	}

	const handleDownloadAfps = () => {
		ApiServices.getZippedAfpPdfs(selected)
		.then((res) => {
			console.log(res)
			getAfps()
		})
	}

	useEffect(async () => {
		await getAfps()
	}, [])

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color="rose" text>
						<CardText color="rose">
							<h4 className={classes.cardTitle}>{`Applications ${afpDate}`}</h4>
						</CardText>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={12} sm={6} md={9} />
							<GridItem xs={12} sm={6} md={3}>
								<CustomDropdown
									id="dropdown"
									menuList={filterAfps}
									fullWidth
									name="door_type"
									classeNames={classes.dropdown}
									defaultValue='all'
									handleDropdown={(e) => handleFilterAfps(e)}
								/>
							</GridItem>
							<GridItem xs={12}>
								<Paper className={classes.tablePaper}>
									<EnhancedTableToolbar numSelected={selected.length} handleDownloadAfps={handleDownloadAfps} selected={selected} />
									<TableContainer>
										<Table
											className={classes.table}
											aria-labelledby="tableTitle"
											size="medium"
											aria-label="enhanced table"
										>
											<EnhancedTableHead
												headCells={headCells}
												classes={classes}
												numSelected={selected.length}
												onSelectAllClick={handleSelectAllClick}
												rowCount={filteredData.length}
											/>
											<TableBody>
												{
													filteredData
														.map((row, index) => {
															const isItemSelected = isSelected(row.applicationId);
															const labelId = `enhanced-table-checkbox-${index}`;

															return <TableRow
																hover
																onClick={(event) => handleClick(event, row.applicationId)}
																role="checkbox"
																aria-checked={isItemSelected}
																tabIndex={-1}
																key={`${row.applicationId}`}
																selected={isItemSelected}
															>
																<TableCell padding="checkbox">
																	<Checkbox
																		checked={isItemSelected}
																		inputProps={{ 'aria-labelledby': labelId }}
																		color="primary"
																	/>
																</TableCell>
																<TableCell component="th" id={labelId} scope="row" padding="none">
																	{`${row.applicationId}.pdf`}
																</TableCell>
																<TableCell align="left">
																	{
																		!!row.downloaded ?
																			<span>Downloaded</span>
																			:
																			<span>Not Downloaded</span>
																	}
																</TableCell>
																<TableCell align="left">
																	<a href={`/api/jobs/preview/application-for-payment-${ row.applicationId }.pdf`} target="_blank">
																		<IconButton aria-label="Download" >
																			<PictureAsPdf style={{ color: "#9c27b0" }} />
																		</IconButton>
																	</a>
																</TableCell>
															</TableRow>;
														})}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}
