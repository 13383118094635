import React, { useContext } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { withRouter } from "react-router-dom"

import Link from '@material-ui/core/Link'
import { makeStyles } from "@material-ui/core/styles"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"

import { userRoles } from "variables/dropdown"
import { UserRoleContext } from "context/UserRole/UserRoleContext"

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js"
import CustomInput from "../CustomInput/CustomInput";

const useStyles = makeStyles(styles);

function Footer(props) {
  const classes = useStyles();
  const { fluid, white } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
    [classes.right]: true,
    [classes.userRoleWrapper]: true
  });

  const handleLinks = (e, link) => {
    e.preventDefault();
    props.history.push(link)
  }

  const {
    userRole,
    changeUserRole,
    rolesAvailableToUser
  } = useContext(UserRoleContext)
 
  return (
    <footer className={ classes.footer } >
      <div className={ container }>
        <Link href="#" className={ classes.linkItem } onClick={ (e) => handleLinks(e, '/design-guide') }>
          Roles
        </Link>
        <CustomDropdown
          id="user-dropdown"
          menuList={ rolesAvailableToUser??userRoles }
          name="userRole"
          classeNames={ classes.userRole }
          defaultValue = {userRole ?? "ReadOnly"}
          handleDropdown={ (e) => changeUserRole(e.target.value) }
        />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
};

export default withRouter(Footer)

