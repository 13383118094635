import React, { useContext, useState } from 'react'
import SweetAlert from "react-bootstrap-sweetalert"
import { makeStyles } from "@material-ui/core/styles"
import { toast } from 'react-toastify'

import clsx from 'clsx';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import CardActions from '@material-ui/core/CardActions'

import GridItem from "components/Grid/GridItem.js"
import GridContainer from 'components/Grid/GridContainer'
import OrderItemHeader from "./OrderItemHeader"
import LineItem from "./LineItem"
import LineItemsHeader from "./LineItemsHeader"
import Button from "components/CustomButtons/Button.js"

import styles from "assets/jss/material-dashboard-pro-react/views/orderItemCollapseStyle.js"
import ApiServices from "api/Api.js"

import { ApplicationEligibleContext } from "context/ApplicationsEligible/ApplicationsEligibleContext"

const useStyles = makeStyles(styles)

function OrderItemCollapse(props) {

	const {
		jobData,
		hasGeneratePermission,
		fetchByStatusForApplication
	} = props

	const [alert, setAlert] = React.useState(null)
	const [expanded, setExpanded] = useState(false)

	const {
		lineItemsReadyForApplication,
		toggleAllLineItemsRFA
	} = useContext(ApplicationEligibleContext)

	const handleExpandClick = () => {
		setExpanded(!expanded);
	}

	const hideAlert = () => {
		setAlert(null);
	}

	/*
			Call ApplicationEligibleContext with jobId and lineItemFullIds of lineItemsReadyForApplication
	*/
	const handleSelectAllLineItems = (e) => {
		if (jobData.lineItemsReadyForApplication.filter(l => !l.isAlreadyApplied).length === 0) {
			return
		}
		let prevSelectedJobLineItems = lineItemsReadyForApplication.find(item => item.jobId === jobData.id.value).lineItemsFullIds;

		if (e.target.checked) {
			toggleAllLineItemsRFA(
				jobData.id.value,
				jobData.lineItemsReadyForApplication.filter(l => !l.isAlreadyApplied).map(l => l.fullId.asString),
				jobData.areaCode,
				jobData.lineItemsReadyForApplication.filter(l => !l.isAlreadyApplied).filter(item => !prevSelectedJobLineItems.includes(item.fullId.asString)).map(l => l.applicationValue),
				e.target.checked
			)
		} else {
			toggleAllLineItemsRFA(
				jobData.id.value,
				[],
				jobData.areaCode,
				jobData.lineItemsReadyForApplication.filter(l => !l.isAlreadyApplied).filter(item => prevSelectedJobLineItems.includes(item.fullId.asString)).map(l => l.applicationValue * -1),
				e.target.checked
			)
		}
	}

	const handleGenerateBtn = () => {
		if (lineItemsReadyForApplication.find(item => item.jobId === jobData.id.value).lineItemsFullIds.length > 0 ||
			jobData.lineItemsReadyForApplication.filter(item => item.isAlreadyApplied).length > 0) {
			setAlert(
				<SweetAlert
					warning
					style={{ display: "block", marginTop: "-100px" }}
					title="Do you want to create an Application for Payment Now?"
					onConfirm={() => { hideAlert(); handleCreateAFP() }}
					onCancel={() => { hideAlert() }}
					confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.alertButton}
					cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				>
				</SweetAlert>
			)
		}
	}

	const handleCreateAFP = async () => {

		const ids = [
			...lineItemsReadyForApplication.find(item => item.jobId === jobData.id.value).lineItemsFullIds,
			...jobData.lineItemsReadyForApplication.filter(item => item.isAlreadyApplied).map(item => item.fullId.asString)
		]

		ApiServices.createApplications(ids)
			.then(() => {
				toast.success("Application for payment has been created.")
			})
			.catch(() => {
					setAlert(
						<SweetAlert
							warning
							style={{ display: "block", marginTop: "-100px" }}
							title="No Application was created."
							onCancel={() => { hideAlert() }}
							onConfirm={() => { hideAlert() }}
							confirmBtnCssClass={classes.button}
							confirmBtnText="Ok"
							showCancel={false}
							showConfirm={true}
						>
							No application was created.<br/>Were all lines already at 100%?
						</SweetAlert>)
			})
			.finally(() => {
				fetchByStatusForApplication()
			})
	}

	const classes = useStyles()

	return (
		<Card className={classes.wrapper}>
			{alert}
			<CardContent className={classes.cardContent}>
				<OrderItemHeader
					handleExpandClick={handleExpandClick}
					expanded={expanded}
					classeNames={clsx(classes.expand, {
						[classes.expandOpen]: expanded,
					})}
					job={jobData}
				/>
			</CardContent>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				{
					jobData.lineItems === null ?
						<CardContent></CardContent>
						:
						<React.Fragment>
							<CardContent>
								{
									jobData.lineItems.length === 0 || jobData.stage === 0 ? <></> :
										<LineItemsHeader
											orderId={jobData.id.value}
											handleSelectAllLineItems={handleSelectAllLineItems}
											disabled={!hasGeneratePermission}
											allSelected={
												lineItemsReadyForApplication.find(item => item.jobId === jobData.id.value) ?
													lineItemsReadyForApplication.find(item => item.jobId === jobData.id.value).allSelected : true
											}
										/>
								}
								{
									jobData.lineItemsReadyForApplication.map((lineItem, index) => (
										<LineItem key={index}
											orderId={jobData.id.value}
											lineItem={lineItem}
											disabled={!hasGeneratePermission}
											jobAreaCode={jobData.areaCode}
										/>
									))
								}
							</CardContent>
							<CardActions>
								<GridContainer>
									<GridItem xs={12} md={9}></GridItem>
									<GridItem xs={12} md={3}>
										<Button
											color="info"
											fullWidth
											onClick={handleGenerateBtn}
											disabled={!hasGeneratePermission}
											className={classes.actionBtn}>Generate</Button>
									</GridItem>
								</GridContainer>
							</CardActions>
						</React.Fragment>
				}
			</Collapse>
		</Card>
	)
}

export default OrderItemCollapse
