import React, { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'

import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { toast } from 'react-toastify'

import Badge from '@material-ui/core/Badge';
import FormLabel from "@material-ui/core/FormLabel"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Link from "@material-ui/core/Link"
import Box from "@material-ui/core/Box"

import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"

import ApiServices from "api/Api.js"

import styles from "assets/jss/material-dashboard-pro-react/views/afpFoldersStyles"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

const useStyles = makeStyles(styles)

const AfpFolders = (props) => {

	const classes = useStyles()

	const { history } = props

	let fromDateOriginal = new Date()
	fromDateOriginal.setMonth(fromDateOriginal.getMonth() - 6)

	const [selectedFromDate, setSelectedFormDate] = useState(fromDateOriginal)
	const [selectedToDate, setSelectedToDate] = useState(new Date())
	const [fromDateError, setFromDateError] = useState(false)
	const [toDateError, setToDateError] = useState(false)

	const [folders, setFolders] = useState([])
	const [totalNewCount, setTotalNewCount] = useState(0)

	const handleFromDateChange = (date) => {
		setSelectedFormDate(date)
	}
	const handleToDateChange = (date) => {
		setSelectedToDate(date)
	}

	const validateSerachForm = () => {

		if ((selectedFromDate > selectedToDate)) {
			if (selectedFromDate > selectedToDate) {
				setFromDateError(true)
			}
			return false
		}
		setFromDateError(false)
		setToDateError(false)
		return true
	}

	const handleSearch = async () => {
		if (validateSerachForm()) {
			const fromDate = `${selectedFromDate.getDate()}-${selectedFromDate.getMonth() + 1}-${selectedFromDate.getFullYear()}`
			const endDate = `${selectedToDate.getDate()}-${selectedToDate.getMonth() + 1}-${selectedToDate.getFullYear()}`
			const res = await ApiServices.getFolders({ fromDate: fromDate, toDate: endDate })
			if (res.status === 200) {
				let folderList = []
				let totalCount = 0

				res.data.forEach(folder => {
					let date = new Date(folder.date)
					totalCount += folder.countNew
					folderList.push({ ...folder, url: `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}` })
				});
				setFolders(folderList)
				setTotalNewCount(totalCount)
			} else {
				setFolders([])
			}
		}
	}

	const handleDownloadFolders = () => setTimeout(() => handleSearch(), 3000)

	useEffect(async () => {

		await handleSearch()
	}, [])

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color="rose" text>
						<CardText color="rose">
							<h4 className={classes.cardTitle}>Folders</h4>
						</CardText>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<Box flex={1}></Box>
							<GridItem xs={12} sm={6} md={2}>
								<FormLabel>
									From *
								</FormLabel>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="dd/MM/yy"
										margin="normal"
										value={selectedFromDate}
										onChange={handleFromDateChange}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										inputVariant="outlined"
										fullWidth
										style={{ marginTop: "4px" }}
										inputProps={{
											style: { fontSize: 20 },
											placeholder: "DD/MM/YYYY",
											name: "installationDateWc",
										}}
										error={fromDateError}
									/>
								</MuiPickersUtilsProvider>
							</GridItem>
							<GridItem xs={12} sm={6} md={2}>
								<FormLabel>
									To *
								</FormLabel>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="dd/MM/yy"
										margin="normal"
										value={selectedToDate}
										onChange={handleToDateChange}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										inputVariant="outlined"
										fullWidth
										style={{ marginTop: "4px" }}
										inputProps={{
											style: { fontSize: 20 },
											placeholder: "DD/MM/YYYY",
											name: "installationDateWc",
										}}
										error={toDateError}
									/>
								</MuiPickersUtilsProvider>
							</GridItem>
							<GridItem xs={12} sm={4} md={2} className={classes.searchBtnWrapper}>
								<Button size="lg" color="info" onClick={handleSearch}>
									search
								</Button>
							</GridItem>
							<GridItem xs={12} sm={4} md={2} className={classes.searchBtnWrapper}>
								<Button 
									size="lg" 
									color="info" 
									fullWidth 
									onClick={ handleDownloadFolders } 
									disabled={ totalNewCount === 0 }
								>
									<a href='api/jobs/applications-zipped-pdfs/allnew' target="_blank" style={{ color: "#ffffff" }}>
										<CloudDownloadIcon style={{ color: "#ffffff" }} /> All New
									</a>
								</Button>
							</GridItem>
							<GridItem xs={1} sm={1} md={1} className={classes.totalCountBadge}>
								<Badge badgeContent={totalNewCount} color="secondary" className={classes.badge} />
							</GridItem>
							<GridItem xs={12}>
								<TableContainer component={Paper}>
									<Table className={classes.table} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell align="left">Folder Name</TableCell>
												<TableCell align="left">New Files</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												folders && folders.map((row) => (
													<TableRow key={row.name}>
														<TableCell>
															<Link className={classes.dateField} align="left" onClick={() => { history.push(`/applications-ready/${row.url}`) }}>
																<PlayArrowIcon />
																{row.name}
															</Link>
														</TableCell>
														<TableCell align="left">
															<Link onClick={() => { history.push(`/applications-ready/${row.url}`) }} className={classes.badgeLink}>
																<Badge badgeContent={row.countNew} color="secondary" className={classes.badge} />
															</Link>
														</TableCell>
													</TableRow>
												))
											}
										</TableBody>
									</Table>
								</TableContainer>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default withRouter(AfpFolders)
