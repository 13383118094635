import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'
import SweetAlert from "react-bootstrap-sweetalert"

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton';

import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"

import EnhancedTableHead from "./EnhancedTableHead"
import DiscountEditModal from "./DiscountEditModal"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { checkUserPermission } from "utils/utils"
import ApiServices from "api/Api.js"

import styles from "assets/jss/material-dashboard-pro-react/views/adminSettingsStyles"

const useStyles = makeStyles(styles)

const headCells = [
	{ id: 'key', numeric: false, disablePadding: true, label: 'Amount' },
	{ id: 'value', numeric: false, disablePadding: false, label: 'Description' },
];

export default function Discounts() {

	const classes = useStyles()

	const { userRole, permissions } = useContext(UserRoleContext)
	const [disabled, setDisabled] = useState(false)
	const [alert, setAlert] = useState(null)

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "AdminScreens", userRole))
	}, [userRole])

	const [discounts, setDiscounts] = useState([])
	const [openEditModal, setOpenEditModal] = useState(false)
	const [discountKey, setDiscountKey] = useState("")

	const toggleEditModal = () => {
		setOpenEditModal(!openEditModal)
	}

	const handleOpenEditModal = (key) => {
		setDiscountKey(key)
		toggleEditModal()
	}

	const getCofigLists = async () => {
		const res = await ApiServices.getConfigLists()
		if (res.status === 200) {
			setDiscounts(res.data.discountPresets)
		}
	}

	const handleEditDiscountType = async (discount) => {
		const res = await ApiServices.editDiscountType({ discountTypeKey: discountKey, discountType: discount })
		if (res.status === 200) {
			toast.success("Discount updated.")
			setDiscounts(res.data)
		} else {
			toast.error("Something went wrong. Reload the page and try again?")
		}
		toggleEditModal()
	}

	const handleRemoveDiscountType = async (discountTypeKey) => {
		setAlert(
			<SweetAlert
				warning
				style={{ display: "block", marginTop: "-100px" }}
				title="Are you sure?"
				onConfirm={() => removeDiscountType(discountTypeKey) }
				onCancel={() => setAlert(null)}
				confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.alertButton}
				cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
				confirmBtnText="Yes"
				cancelBtnText="Cancel"
				showCancel
			>
			</SweetAlert>
		)
	}

	const removeDiscountType = async (discountTypeKey) => {
		const res = await ApiServices.removeDiscountType(discountTypeKey)
		if (res.status === 200) {
			toast.success("Discount removed.")
			setDiscounts(res.data)
		} else {
			toast.error("Something went wrong. Reload the page and try again?")
		}
		setAlert(null)
	}

	useEffect(() => {
		getCofigLists()
	}, [])

	return (
		<GridItem xs={12}>
			<Card>
				<CardHeader color="rose" text>
					<CardText color="rose">
						<h4 className={classes.cardTitle}>Discount</h4>
					</CardText>
				</CardHeader>
				<CardBody>
					<TableContainer>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							size="medium"
							aria-label="enhanced table"
						>
							<EnhancedTableHead
								headCells={headCells}
								classes={classes}
								rowCount={discounts.length}
							/>
							<TableBody>
								{
									discounts
										.map((row, index) => {
											const labelId = `enhanced-table-checkbox-${index}`;
											return (
												<TableRow
													hover
													tabIndex={-1}
													key={`${row.key}`}
												>
													<TableCell align="left" width="10%">
														{index + 1}
													</TableCell>
													<TableCell component="th" id={labelId} scope="row">
														{row.key}
													</TableCell>
													<TableCell align="left" >
														{
															row.value
														}
													</TableCell>
													<TableCell align="right">
														<IconButton onClick={() => handleOpenEditModal(row.key)} disabled={disabled}>
															<EditIcon className={classes.editIcon} />
														</IconButton>
														{
															row.key !== "Other" && (
																<IconButton onClick={() => handleRemoveDiscountType(row.key)} disabled={disabled}>
																	<DeleteIcon color="secondary" />
																</IconButton>
															)
														}
													</TableCell>
												</TableRow>
											);
										})}
							</TableBody>
						</Table>
					</TableContainer>
				</CardBody>
			</Card>
			<DiscountEditModal
				open={openEditModal}
				toggleModal={toggleEditModal}
				discount={discounts.find(discount => discount.key === discountKey)}
				handleEditDiscountType={handleEditDiscountType}
			/>
			{alert}
		</GridItem>
	)
}
