import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import CloseIcon from '@material-ui/icons/Close'

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'

import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormLabel from "@material-ui/core/FormLabel"
import CustomInput from "components/CustomInput/CustomInput.js"
import IconButton from '@material-ui/core/IconButton'
import Button from "components/CustomButtons/Button.js"

import styles from "assets/jss/material-dashboard-pro-react/views/jobListStyle.js"

const useStyles = makeStyles(styles)

export default function CancelLineItemModal(props) {

	const classes = useStyles()

	const { open, toggleModal, cancelLineItems } = props
	const [selectedDate, setSelectedDate] = useState(null)
	const [errors, setErrors] = useState({ instructedBy: false, instructionDate: false })
	const [instructedBy, setInstructedBy] = useState("")


	const handleSubmit = () => {
		setErrors({
			instructedBy: instructedBy === "" ? true : false,
			instructionDate: selectedDate === null ? true : false
		})
		if (!errors.instructedBy && !errors.instructionDate) {
			setInstructedBy("")
			setSelectedDate(null)
			cancelLineItems(instructedBy, selectedDate)
		}
	}

	const handleDateChange = (date) => {
		setSelectedDate(date)
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={toggleModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card className={classes.editCard} variant="outlined">
					<CardHeader
						title="Cancel Door"
						action={
							<IconButton aria-label="close" onClick={toggleModal}>
								<CloseIcon />
							</IconButton>
						}
					/>
					<CardContent>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<FormLabel>
									Instructed By *
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.paddingTop
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Instructed By",
										name: "instructedBy",
										onChange: (e) => setInstructedBy(e.target.value)
									}}
									error={errors.instructedBy}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={12}>
								<FormLabel>
									Instruction Date *
								</FormLabel>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="dd/MM/yyyy"
										margin="normal"
										value={selectedDate}
										onChange={handleDateChange}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										inputVariant="outlined"
										fullWidth
										style={{ marginTop: "4px" }}
										inputProps={{
											style: { fontSize: 20 },
											placeholder: "DD/MM/YYYY",
											name: "instructionDate",
										}}
										error={errors.instructionDate}
									/>
								</MuiPickersUtilsProvider>
							</GridItem>
						</GridContainer>
					</CardContent>
					<CardActions className={classes.cardAction}>
						<Button color="info" size="lg" onClick={handleSubmit}>
							Submit
						</Button>
					</CardActions>
				</Card>
			</Fade>
		</Modal>
	)
}
