import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from 'prop-types';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import IconButton from '@material-ui/core/IconButton';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import styles from "assets/jss/material-dashboard-pro-react/views/downloadViewStyles"
import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { checkUserPermission } from "utils/utils"

const useStyles = makeStyles(styles);


export default function EnhancedTableToolbar(props) {
	const classes = useStyles();
	const { numSelected, selected } = props;

	const { userRole, permissions } = useContext(UserRoleContext)
	const [hasDownloadPermission, setHasDownloadPermission] = useState(false)

	useEffect(() => {
		setHasDownloadPermission(checkUserPermission(permissions, "DownloadApplication", userRole))
	}, [userRole])

	return (
		<Toolbar
			className={clsx(classes.toolbar, {
				[classes.highlight]: hasDownloadPermission,
			})}
		>
			{
				numSelected > 0 &&
				<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			}
			{
				numSelected > 0 &&
				<a href={`/api/jobs/applications-zipped-pdfs?afpIds=${selected.join('&afpIds=')}`} target="_blank">
					<IconButton aria-label="Download" >
						<CloudDownloadIcon style={{ color: "#9c27b0" }} />
					</IconButton>
				</a>
			}
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
}
