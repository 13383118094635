import React, { useState, useEffect, useContext } from 'react'

import { makeStyles } from "@material-ui/core/styles"
import Check from "@material-ui/icons/Check"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import {	
	line_item_status_filter_list
} from "variables/dropdown.js"
import { ApplicationEligibleContext } from "context/ApplicationsEligible/ApplicationsEligibleContext"
import { LineItemContext } from 'context/LineItem/LineItemContext'
import styles from "assets/jss/material-dashboard-pro-react/views/orderItemCollapseStyle.js"

const useStyles = makeStyles(styles)

function LineItem(props) {

	const {
		orderId,
		lineItem,
		disabled,		
		jobAreaCode
	} = props

	const { doorTypes } = useContext(LineItemContext)

	const [checked, setChecked] = useState(false)

	const {
		toggleLineItemRFA,
		lineItemsReadyForApplication
	} = useContext(ApplicationEligibleContext)

	const handleCheck = (e) => {
		setChecked(e.target.checked)
		toggleLineItemRFA(orderId, lineItem.fullId.asString, lineItem.applicationValue, jobAreaCode)
	}

	useEffect(() => {
		if (!lineItem.isAlreadyApplied) {			
			lineItemsReadyForApplication.find(item => item.jobId === orderId).lineItemsFullIds.includes(lineItem.fullId.asString) ? setChecked(true) : setChecked(false)
		} else {
			setChecked(true)
		}
	}, [lineItemsReadyForApplication.find(item => item.jobId === orderId).lineItemsFullIds])

	const classes = useStyles()

	return (
		<GridContainer className={classes.lineItem}>
			<GridItem xs={12} sm={12} md={2} className={classes.lineItemCell}>
				<p>
					{lineItem.fullId.asString}
				</p>
			</GridItem>
			<GridItem xs={12} sm={12} md={6} className={classes.lineItemCell}>
				{
					lineItem.isDoor ?
						<p>
							{`${doorTypes.find(item => item.value === lineItem.door.type)?.text ?? ""} `}
							{lineItem.customerRef}
						</p>
						:
						<p>
							{[
								lineItem.customDescription,
								lineItem.ascotRef,
								lineItem.nonDoorVariation.instructedBy,
								new Date(lineItem.nonDoorVariation.instructionDate).toLocaleDateString()
							].join(" ")
							}
						</p>
				}
			</GridItem>
			<GridItem xs={12} sm={12} md={2} className={classes.lineItemCell}>
				<p>
					{line_item_status_filter_list.find(item => lineItem.stage === item.value).text}
				</p>
			</GridItem>

			<GridItem xs={12} sm={4} md={2} className={classes.alignItemsCenter}>
				<div
					className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal + " " + classes.checkbox}
				>
					<FormControlLabel
						control={
							<Checkbox
								tabIndex={-1}
								onChange={(e) => handleCheck(e)}
								checkedIcon={
									<Check className={classes.checkedIcon} />
								}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{
									checked: classes.checked,
									root: classes.checkRoot
								}}
								checked={checked}
								disabled={disabled || lineItem.isAlreadyApplied}
							/>
						}
						classes={{
							label: classes.label,
							root: classes.labelRoot
						}}
						label={!lineItem.isAlreadyApplied && "New"}
					/>
				</div>
			</GridItem>
		</GridContainer>
	)
}

export default LineItem
