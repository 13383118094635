import {
  cardTitle,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js"
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js"

const reportsViewStyle = (theme) => ({
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    color: whiteColor
  },
  table: {
    "& th": {
        fontSize: "20px"
    },
    "& a": {
        fontSize: "20px",
        cursor: "pointer"
    },
    "& tbody": {
        '& tr:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        }
    },
    "& .MuiTableCell-root": {
      "& a": {
        display: "flex",
        alignItems: "center",
      },      
      "& .MuiSvgIcon-root": {
        marginRight: "1rem",
        color: '#00acc1'
      }
    }
},
})

export default reportsViewStyle
