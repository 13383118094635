import {
	ADD_LINEITEM,
	CHANGE_LINEITEM,
	CHANGE_LINEITEM_REF,
	REMOVE_LINEITEM,
	FORMAT_LINEITEMS,
	RESET_DOOR_TYPES,
	RESET_DISCOUNTS,
	RESET_VATCODES
} from "../actionTypes"

import { initialDoorData } from "variables/form"

export const sumLineItems = (lineItems) => {
	let total_cost_price = 0
	let total_sell_price = 0
	if (lineItems.length > 0) {
		lineItems.forEach(lineItem => {
			total_cost_price += (lineItem.costPrice !== "") && (lineItem.no_doors !== "") ? parseFloat(lineItem.costPrice) * parseInt(lineItem.no_doors) : 0
			total_sell_price += (lineItem.sellPrice !== "") && (lineItem.no_doors !== "") ? parseFloat(lineItem.sellPrice) * parseInt(lineItem.no_doors) : 0
		});
	}
	return {
		totalCostPrice: total_cost_price,
		totalSellPrice: total_sell_price
	}
}

const lineItemReducer = (state, action) => {
	switch (action.type) {
		case FORMAT_LINEITEMS:
			state.lineItems = [action.payload];
			return {
				...state,
				...sumLineItems(state.lineItems)
			}
		case ADD_LINEITEM:
			state.lineItems.push(action.payload)
			return {
				...state,
				...sumLineItems(state.lineItems)
			}
		case CHANGE_LINEITEM:
			const eventName = action.payload.event.target.name
			const eventValue = action.payload.event.target.value
			const lineItemIndex = action.payload.index
			state.lineItems[lineItemIndex][eventName] = eventValue
			if (eventName === 'no_doors') {
				if (state.lineItems[lineItemIndex]['customerDoorRefList'].length === 0) {
					state.lineItems[lineItemIndex]['customerDoorRefList'] = new Array(parseInt(eventValue)).fill("")
				} else if (state.lineItems[lineItemIndex]['customerDoorRefList'].length > parseInt(eventValue)) {
					state.lineItems[lineItemIndex]['customerDoorRefList'].splice(parseInt(eventValue),
						state.lineItems[lineItemIndex]['customerDoorRefList'].length - parseInt(eventValue))
				} else if (state.lineItems[lineItemIndex]['customerDoorRefList'].length < parseInt(eventValue)) {
					for (var i = 0; i < parseInt(eventValue) - state.lineItems[lineItemIndex]['customerDoorRefList'].length; i++) {
						state.lineItems[lineItemIndex]['customerDoorRefList'].push("")
					}
				}
			}
			return {
				...state,
				...sumLineItems(state.lineItems)
			}
		case CHANGE_LINEITEM_REF:
			state.lineItems[action.payload.lineItemIndex]['customerDoorRefList'][action.payload.refIndex] = action.payload.event.target.value
			return {
				...state,
				...sumLineItems(state.lineItems)
			}
		case REMOVE_LINEITEM:
			state.lineItems.splice(action.payload.lineItemIndex, 1)
			if (state.lineItems.length === 0) {
				state.lineItems.push(initialDoorData)
			}
			return {
				...state,
				...sumLineItems(state.lineItems)
			}
		case RESET_DOOR_TYPES:
			state.doorTypes = action.payload.doorTypes
			return {
				...state
			}
		case RESET_DISCOUNTS:
			state.discounts = action.payload.discounts
			return {
				...state
			}
		case RESET_VATCODES:			
			return {
				...state,
				vatCodes: action.payload.vatCodes
			}
	}
}

export default lineItemReducer
