const fileUploadModalStyle = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,    
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    "& .MuiDropzoneArea-root": {
      minHeight: "200px",
      minWidth: "450px"
    }
  },
})

export default fileUploadModalStyle
