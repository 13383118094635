const configuredFeatures= (process.env.REACT_APP_FEATURES||'').toLowerCase().split(',')

console.log("Features:",configuredFeatures)
export const feature = {

    features : configuredFeatures,
    
    useRolesFromAppsettings : 
        ()=> configuredFeatures.includes("userolesfromappsettings"),
    
    snaggings : 
        ()=> configuredFeatures.includes("snaggings")
}