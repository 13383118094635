import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig'
import { InteractionStatus } from '@azure/msal-browser'

function truncate(str, len = 20) { return str && str.length > len && str.substr(0, len) + "..." || "(none)" }

function copyAccessTokenToSessionStorage(accessTokenResponse) {
  let accessToken = accessTokenResponse.accessToken;  
  console.warn("Auth.Got access token:", Date(), truncate(accessToken))
  localStorage["accessToken"] = accessToken
  window.location.href = "https://google.com"
}

function tryGetTokenFromMsal(instance, accounts, inProgress) {
  console.log("Auth.tryGetAccessTokenForApi(", `InProgress.${inProgress}`, `HasInstance.${(!!instance)},HasAccounts.${!!accounts && !!accounts[0]})`)
  if (inProgress === InteractionStatus.None) {
    const accessTokenRequest = { scopes: loginRequest.scopes, account: accounts[0] }
    instance.acquireTokenSilent(accessTokenRequest)
      .then(copyAccessTokenToSessionStorage)
      .catch(e1 => {
        console.warn(`Auth.After acquireTokenSilent()`, new Date(), e1)
        instance.acquireTokenRedirect(accessTokenRequest)
          .then(copyAccessTokenToSessionStorage)
          .catch(e2 => console.error("Auth.After acquireTokenSilent(), After acquireTokenRedirect():", e2))
      })
  }
}

const Auth = () => {

  const { instance, accounts, inProgress } = useMsal();

  const history = useHistory()

  useEffect(() => {
    instance
      .loginRedirect(loginRequest)
      .catch(e => console.error("Auth.loginRedirect", e))
      .then(() => tryGetTokenFromMsal(instance, accounts))
      .catch(e => console.error("Auth.tryGetToken", e))
      .finally(() => {
        console.log("Auth.finally"); history.push('/')
      }
      )
  }, [instance, accounts])  

  return (
    <></>
  )
}
export default Auth
