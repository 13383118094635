import {
    cardTitle,
    whiteColor,
} from "assets/jss/material-dashboard-pro-react.js"
import customerJobStyle from "assets/jss/material-dashboard-pro-react/views/customerJobStyle"
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js"

const afpListSectionStyles = {
    ...customCheckboxRadioSwitch,
    ...customerJobStyle,
    cardTitle: {
        ...cardTitle,
        color: whiteColor
    },
    afpTable: {
        padding: "0 20px",
        "& .MuiTableCell-root": {
            fontSize: "20px"
        }
    },
    downloadBtn: {
        "& svg": {
            marginRight: "10px"
        }
    },
    setMargin: {
        "& label": {
            margin: "0"
        }
    }
}

export default afpListSectionStyles
