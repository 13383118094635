import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import ImageSearchIcon from '@material-ui/icons/ImageSearch';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Box from "@material-ui/core/Box"

import styles from "assets/jss/material-dashboard-pro-react/views/jobStyle.js"

const useStyles = makeStyles(styles)

const DocumentItem = (props) => {

  const classes = useStyles()

  const { document } = props

  return (
    <Card className={classes.documentCard}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.documentItem}>
          <Box>
            <Typography variant="body1" component="p">
              { document.name }
            </Typography>
          </Box>
          <IconButton
            aria-label="add to favorites"
            onClick={() => window.open(`/api/jobs/documents/${document.jobId.value}/${document.name}`)}
          >
            <ImageSearchIcon />
          </IconButton>
        </Box>

      </CardContent>
    </Card>
  )
}

export default DocumentItem
