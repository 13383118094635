import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import jwt_decode from "jwt-decode";

import MainLayout from "layouts/MainLayout.js"
import AdminLayout from "layouts/AdminLayout"
import LogOut from "views/LogOut"
import UserRoleContextProvider from "context/UserRole/UserRoleContext"
import "assets/css/style.css"
import 'react-toastify/dist/ReactToastify.css'
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0"


//--------------------------------------------------------------------------
import { InteractionRequiredAuthError, InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from './authConfig'
import Auth from "views/Auth"
import api from "api/Api"

function WithToken({ children }) {

	const { instance, inProgress, accounts } = useMsal();
	const [apiPermissionsAndRoles, setApiPermissionsAndRoles] = useState(null);
	const [lastRefresh, setLastRefresh] = useState(localStorage["accessTokenLastRefresh"]);
	const about10Minutes = 10 * 60000

	function callApiWithNewTokenAndSaveToSession(accessToken) {
		localStorage["accessToken"] = accessToken
		localStorage["accessTokenLastRefresh"] = new Date();
		setLastRefresh(localStorage["accessTokenLastRefresh"])
		console.log(new Date().toTimeString(), "WithToken:putlocalStorage")				
		setApiPermissionsAndRoles(jwt_decode(accessToken).roles)		
	}

	function isFreshToken() { return !!lastRefresh && (new Date() - new Date(lastRefresh) < about10Minutes) || NaN }
	function timeLeft() { return lastRefresh ? about10Minutes - (new Date() - new Date(lastRefresh)) : NaN }

	function getFreshToken() {
		console.log(new Date().toTimeString(), "WithToken:getFreshToken:lastRefresh:", lastRefresh)
		if (apiPermissionsAndRoles && localStorage["accessToken"] && isFreshToken()) {
			console.log(new Date().toTimeString(), "WithToken:getFreshToken:already acquired")
			return
		}
		if (inProgress === InteractionStatus.None) {
			console.log(new Date().toTimeString(), "WithToken:getFreshToken:startacquireToken")
			const accessTokenRequest = {
				scopes: loginRequest.scopes,
				account: accounts[0]
			}
			instance
				.acquireTokenSilent(accessTokenRequest)
				.then(accessTokenResponse => {
					callApiWithNewTokenAndSaveToSession(accessTokenResponse.accessToken)					
				})
				.catch(e => {
					console.warn(new Date().toTimeString(), "WithToken:getFreshtoken:acquireTokenSilent:", e);
					if (e instanceof InteractionRequiredAuthError) {
						instance.acquireTokenRedirect(accessTokenRequest);
					}
				})
		}
		else { console.warn(new Date().toTimeString(), "WithToken:getFreshToken:Needed token but interaction in progress") }
	}

	function getFreshTokenOnTimerInterval() {
		getFreshToken()
		let timeout = about10Minutes
		let timer = setTimeout(getFreshTokenOnTimerInterval, timeout)
		console.log(new Date().toTimeString(), "WithToken:set next refresh in 10 minutes")
		return () => clearTimeout(timer);
	}

	useEffect(getFreshTokenOnTimerInterval, [instance, accounts, inProgress])

	if (isFreshToken()) {
		console.log(new Date().toTimeString(), "WithToken:fresh on render, last refresh:", lastRefresh)
		return (<>{children}</>)
	} else {
		console.log(new Date().toTimeString(), "WithToken:not fresh on  render, last refresh:", lastRefresh)
		return (<p>Waiting for access token from Microsoft … </p>)
	}
}
//--------------------------------------------------------------------------


const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
	<MsalProvider instance={msalInstance}>
		<UnauthenticatedTemplate>
			<p>Not logged in, redirecting to Microsoft login...</p>
			<Auth />
		</UnauthenticatedTemplate>
		<AuthenticatedTemplate>
			<WithToken>
				<UserRoleContextProvider>
					<Router>
						<Switch>
							<Route path="/admin" component={AdminLayout} />
							<Route path="/logout" component={LogOut} />
							<Route path="/" component={MainLayout} />
						</Switch>
					</Router>
				</UserRoleContextProvider>
			</WithToken>
		</ AuthenticatedTemplate>
	</MsalProvider>,
	document.getElementById("root")
);
