import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import SweetAlert from "react-bootstrap-sweetalert"

import DeleteIcon from '@material-ui/icons/Delete'
import Check from "@material-ui/icons/Check"
import { PictureAsPdf } from '@material-ui/icons'

import { IconButton } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"


import styles from "assets/jss/material-dashboard-pro-react/views/afpListSectionStyles"

import ApiServices from 'api/Api.js'
import { formatCurrency } from 'utils/utils'
import { checkUserPermission } from 'utils/utils'
import { UserRoleContext } from 'context/UserRole/UserRoleContext'


const useStyles = makeStyles(styles)

export default function AFPListSection() {

	const { jobId } = useParams()

	const classes = useStyles()

	const { userRole, permissions } = useContext(UserRoleContext)
	const [hasGeneratePermission, setHasGeneratePermission] = useState(false)

	const [alert, setAlert] = React.useState(null)
	const [afpList, setAfpList] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [isFirst, setIsFirst] = useState(true)

	const handleClickCheck = (e, index) => {
		if (selectedIds.length > 0) {
			return
		}
		let ids = []
		for (var i = index; i < afpList.length - 1; i++) {
			ids.push(afpList[i].applicationId)
		}	
		setSelectedIds(ids)
	}

	const handleClickDeleteBtn = () => {
		let text = selectedIds.length === 0 ? "Delete the most recent application?"
			: `Delete the last ${selectedIds.length + 1} applications?`
		if (isFirst) {
			confirmAlert(text)
		} else {
			deleteApplications()
		}

	}

	const deleteApplications = async () => {
		const lens = selectedIds.length > 0 ? selectedIds.length + 1 : 1
		const res = await ApiServices.deleteApplications(jobId, lens)
		if (res.status === 200) {
			toast.success("Applications deleted")
			setIsFirst(false)
			setAfpList(res.data)
			setSelectedIds([])
		} else {
			toast.error("Deleting Applications failed")
		}
	}

	const confirmAlert = (text) => {
		setAlert(
			<SweetAlert
				warning
				style={{ display: "block", marginTop: "-100px" }}
				title={text}
				onConfirm={() => { hideAlert(); deleteApplications(); }}
				onCancel={() => { hideAlert() }}
				confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.alertButton}
				cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
				confirmBtnText="Yes"
				cancelBtnText="Cancel"
				showCancel
			>
			</SweetAlert>
		)
	}

	const hideAlert = () => {
		setAlert(null);
	}

	useEffect(() => {
		ApiServices.getApplicationForPayments(jobId)
			.then(result => {
				setAfpList(result.data)
			})
			.catch(err => {
				setAfpList([])
			})
	}, [])

	useEffect(() => {
		setHasGeneratePermission(checkUserPermission(permissions, "CreateAndDeleteApplications", userRole))
	}, [userRole])

	return (
		<GridItem xs={12} sm={12} md={12}>
			{alert}
			<Card>
				<CardHeader color="rose" text>
					<CardText color="rose">
						<h4 className={classes.cardTitle}>Application For Payment</h4>
					</CardText>
				</CardHeader>
				<CardBody className={classes.cardBodyPadding}>
					<GridContainer>
						<TableContainer component={Paper}>
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell align="center">Application</TableCell>
										<TableCell align="center">Date</TableCell>
										<TableCell align="center">Value</TableCell>
										<TableCell align="center">Preview</TableCell>
										<TableCell align="center">Delete</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										afpList && afpList.map((row, index) => (
											<TableRow key={row.sequenceNumber}>
												<TableCell component="th" scope="row" align="center">
													{row.sequenceNumber}
												</TableCell>
												<TableCell align="center">{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short' }).format(Date.parse(row.timestamp))}</TableCell>
												<TableCell align="center">{formatCurrency(row.value)}</TableCell>
												<TableCell align="center">
													<a href={`/api/jobs/preview/application-for-payment-${jobId}/${row.sequenceNumber}.pdf`} target="_blank"><PictureAsPdf /></a>
												</TableCell>
												<TableCell align="center">
													{
														index === (afpList.length - 1) ?
															<IconButton
																color="secondary"
																disabled={!hasGeneratePermission}
																onClick={handleClickDeleteBtn}
															>
																<DeleteIcon />
															</IconButton>
															:
															<div
																className={classNames(classes.checkboxAndRadio, classes.checkboxAndRadioHorizontal, classes.checkbox, classes.setMargin)}
															>
																<FormControlLabel
																	control={
																		<Checkbox
																			tabIndex={-1}
																			onClick={(e) => {
																				handleClickCheck(e, index)
																			}}
																			checkedIcon={
																				<Check className={classes.checkedIcon} />
																			}
																			icon={<Check className={classes.uncheckedIcon} />}
																			classes={{
																				checked: classes.checked,
																				root: classes.checkRoot
																			}}
																			checked={selectedIds.includes(row.applicationId)}
																			disabled={!hasGeneratePermission}
																		/>
																	}
																	classes={{
																		label: classes.label,
																		root: classes.labelRoot
																	}}
																/>
															</div>
													}

												</TableCell>
											</TableRow>
										))
									}
								</TableBody>
							</Table>
						</TableContainer>
					</GridContainer>
				</CardBody>
			</Card>
		</GridItem>
	)
}
