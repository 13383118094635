import React from 'react';
import { DropzoneAreaBase } from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import styles from "assets/jss/material-dashboard-pro-react/components/fileUploadModalStyle"

const useStyles = makeStyles(styles)

const FileUploadModal = (props) => {

  const classes = useStyles()

  const { open, handleClose, handleSubmit } = props

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <DropzoneAreaBase
            onAdd={(fileObjs) => handleSubmit(fileObjs)}
            onDelete={(fileObj) => console.log('Removed File:', fileObj)}
            onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
          />
        </div>
      </Fade>
    </Modal>
  )
}

export default FileUploadModal
