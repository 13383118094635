import React, { createContext, useReducer, useEffect } from 'react'

import lineItemReducer, { sumLineItems } from "./LineItemReducer"
import {
	ADD_LINEITEM,
	CHANGE_LINEITEM,
	CHANGE_LINEITEM_REF,
	REMOVE_LINEITEM,
	FORMAT_LINEITEMS,
	RESET_DOOR_TYPES,
	RESET_DISCOUNTS,
	RESET_VATCODES
} from "../actionTypes"
import { initialDoorData } from "variables/form"
import { doorTypeList, discountList, vatCodes as initialVatCodes } from "variables/dropdown"

import ApiServices from "api/Api.js"
import {assertElseError} from '../../utils/utils'

export const LineItemContext = createContext()

const initialState = {
	lineItems: [],
	discounts: discountList,
	doorTypes: doorTypeList,
	vatCodes: initialVatCodes,
	...sumLineItems(initialDoorData)
}

const LineItemContextProvider = ({ children }) => {

	const [state, dispatch] = useReducer(lineItemReducer, initialState)

	const fetchDoorsAndDiscount = () =>
		ApiServices.getConfigLists().then(res => {
			console.log("LineItemContext:fetchDoorsAndDiscount", res && res.data || res)
			const lists=res.data
			if(assertElseError(lists.doorTypes && lists.doorTypes.length && lists.doorTypes.length>20,
															"expected at least 20 doors from server")){
				const doorTypes = lists.doorTypes.map(e=> ({"value":e.key, "text":e.value}))
				localStorage.setItem("doorTypes", JSON.stringify(doorTypes));
				dispatch({type: RESET_DOOR_TYPES, payload: {doorTypes:doorTypes}})
			}
			if(assertElseError(lists.vatCodes && lists.vatCodes.length && lists.vatCodes.length>1,
										"expected at least 2 vat codes from server")){
				const vatCodes = lists.vatCodes.map(e=> ({"value":e.key, "text":e.value}))
				localStorage.setItem("vatCodes", JSON.stringify(vatCodes));
				dispatch({type: RESET_VATCODES, payload: {vatCodes: vatCodes}})
			}
			if(assertElseError(lists.discountPresets && lists.discountPresets.length,
							"expected at least 1 discountPreset from server")){
				const discountPresets = lists.discountPresets.map(e=> ({"value":e.key, "text":e.value}))
				localStorage.setItem("discountPresets", JSON.stringify(discountPresets));
				dispatch({type: RESET_DISCOUNTS, payload: {discounts:discountPresets}})
			}

		}).catch(e=>{
			console.error("LineItemContext:fetchDoorsAndDiscount",e)
			const doorTypesCache = JSON.parse(localStorage.getItem("doorTypes"))
			if(doorTypesCache && doorTypesCache.length && doorTypesCache.length>20){
				dispatch({type: RESET_DOOR_TYPES, payload: {doorTypes:doorTypesCache}})
			}

			const vatCodesCache = JSON.parse(localStorage.getItem("vatCodes"))
			if(vatCodesCache && vatCodesCache.length && vatCodesCache.length>20){
				dispatch({type: RESET_VATCODES, payload: {vatCodes:vatCodesCache}})
			}

			const discountPresetsCache = JSON.parse(localStorage.getItem("discountPresets"))
			if(discountPresetsCache && discountPresetsCache.length) {
				dispatch({type: RESET_DISCOUNTS, payload: {discounts: discountPresetsCache}})
			}
		})

	useEffect(() => {
		fetchDoorsAndDiscount()
	}, [])

	const formatLineItems = (lineItem) => dispatch({ type: FORMAT_LINEITEMS, payload: lineItem })
	const addLiniItem = (lineItem) => dispatch({ type: ADD_LINEITEM, payload: lineItem })
	const changeLineItem = (event, index) => dispatch({ type: CHANGE_LINEITEM, payload: { event: event, index: index } })
	const changeLineItemRef = (event, lineItemIndex, refIndex) => dispatch({
		type: CHANGE_LINEITEM_REF,
		payload: {
			event: event,
			lineItemIndex: lineItemIndex,
			refIndex: refIndex
		}
	})

	const removeLineItem = (lineItemIndex) => dispatch({ type: REMOVE_LINEITEM, payload: { lineItemIndex: lineItemIndex } })

	const contextValue = {
		...state,
		addLiniItem,
		changeLineItem,
		changeLineItemRef,
		removeLineItem,
		formatLineItems
	}

	return (
		<LineItemContext.Provider value={contextValue}>
			{
				children
			}
		</LineItemContext.Provider>
	)
}

export default LineItemContextProvider
