import React, {useContext, useEffect, useState} from 'react'
import { toast } from 'react-toastify'
import SweetAlert from "react-bootstrap-sweetalert"

import { makeStyles } from '@material-ui/core/styles';

import Box from "@material-ui/core/Box"

import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer.js"
import CustomInput from "components/CustomInput/CustomInput.js"

import ChangeJobNumber from "./components/ChangeJobNumber"

import ApiServices from "api/Api.js"

import styles from "assets/jss/material-dashboard-pro-react/views/adminSettingsStyles"
import {checkUserPermission} from '../../../utils/utils'
import {UserRoleContext} from '../../../context/UserRole/UserRoleContext'

const useStyles = makeStyles(styles)

const OpsProcesses = () => {

	const { userRole, permissions } = useContext(UserRoleContext)
	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "AdminScreens", userRole))
	}, [userRole])


  const classes = useStyles()

  const [jobNumber, setJobNumber] = useState("")
  const [alert, setAlert] = useState(null)

  const handleDeleteJob = () => {

		setAlert(
			<SweetAlert
				warning
				style={{ display: "block", marginTop: "-100px" }}
				title={ `Delete Job ${jobNumber}?` }
				onConfirm={removeJob}
				onCancel={() => setAlert(null)}
				confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.alertButton}
				cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
				confirmBtnText="Yes"
				cancelBtnText="Cancel"
				showCancel
			>
			</SweetAlert>
		)
	}

  const removeJob = () => {
    setAlert(null)
    ApiServices.deleteJob(jobNumber).then(() => {
      toast.success("Job deleted")
    }).catch(() => {
      toast.error("Failed")
    })
  }

  return (
    <GridItem xs={12}>
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4 className={classes.cardTitle}>Ops Processes</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Box mt={3}></Box>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  className: classes.paddingTop
                }}
                inputProps={{
                  disabled:disabled,
                  type: "number",
                  style: { fontSize: 20 },
                  placeholder: "Job Number",
                  name: "jobNumber",
                  value: jobNumber,
                  onChange: (e) => setJobNumber(e.target.value)
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Button
                size="lg"
                onClick={handleDeleteJob}
                fullWidth
                disabled={!jobNumber}
              >
                Delete Job
              </Button>
            </GridItem>

          </GridContainer>
        </CardBody>
      </Card>
      { alert }
    </GridItem>
  )
}

export default OpsProcesses
