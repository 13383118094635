import http from "./Http"

class ApiServices {

	if401TryToNotLogoutOtherwiseRethrow(e){
		if(e.response && e.response.status === 401){
			console.warn(new Date().toTimeString(),"Got 401. Removing access tokens, trying to not logout.")
			localStorage.removeItem("accessTokenLastRefresh");
			localStorage.removeItem("accessToken");
			window.location.href=window.location.href
		}
		else{throw e}
	}

	async getJobById(jobId) {
		return http.get(`jobs/${jobId}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async getSearchResult(query) {
		const querysafe=encodeURIComponent(query)
		return http.get(`jobs/search/${querysafe}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async getCustomer(customerId) {
		return http.get(`jobs/customer/${customerId}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putCustomer(customer) {
		return http.put(`jobs/customer/${customer.id}`, customer).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async createCustomer(customer) {
		return http.post(`jobs/customer`, customer).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async createJob({ customerId, job }) {
		return http.post(`jobs/${customerId}`, job).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async updateJob({ jobId, job }) {
		return http.put(`jobs/${jobId}`, job).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async addDoors({ jobId, lineItem }) {
		return http.post(`jobs/door/${jobId}`, lineItem).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async uploadDocuments({ jobId, document }) {
		const formData = new FormData()
		formData.append('file', document)

		return http.post(`jobs/documents/${jobId}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			}
		}).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async getJobsByStatus({ jobStage = "all", doorStage = "all" }) {
		const jobStageSafe=encodeURIComponent(jobStage)
		const doorStageSafe=encodeURIComponent(doorStage)
		return http.get(`jobs/filter/${jobStageSafe}/${doorStageSafe}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async deleteLineItem(jobId, lineItemId) {
		return http.delete(`jobs/door/${jobId}/${lineItemId}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putDoorVariation(jobId, lineItem) {
		return http.post(`jobs/door/${jobId}/variation`, lineItem).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putNonDoorVariation(jobId, nonDoorVariation) {
		return http.post(`jobs/non-door/${jobId}`, nonDoorVariation).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putLineItemStage({ jobId, lineItemId, door }) {
		return http.put(`jobs/door/${jobId}/${lineItemId}`, door).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async deleteJob(jobId) {
		return http.delete(`jobs/${jobId}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putJobNettOrDiscount({ jobId, nettOrDiscount }) {
		return http.put(`jobs/${jobId}/net-or-discount`, nettOrDiscount).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putJobQsContact({ jobId, qsContact }) {
		return http.put(`jobs/${jobId}/qs-contact`, qsContact).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putJobSiteContact({ jobId, siteContact }) {
		return http.put(`jobs/${jobId}/site-contact`, siteContact).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putJobCreditCheck({ jobId, creditCheckNumber }) {
	  return http.put(`jobs/credit-check/${jobId}/${creditCheckNumber}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putLineItemsStageComplete({ jobId, lineNumbers }) {
		return http.put(`jobs/lineitems/complete/${jobId}`, lineNumbers).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putLineItemComplete({ jobId, lineNumber }) {
		return http.put(`jobs/door/complete/${jobId}/${lineNumber}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async putLineItemCancelled(jobId, formData) {
		return http.put(`jobs/door/cancelled/${jobId}`, formData).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	// async createApplicationForPayment(jobId) {
	//     const res = await http.post(`jobs/afp/${ jobId }`)
	//     return res
	// }

	async getApplicationForPayments(jobId) {
		return http.get(`jobs/afp/${jobId}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async downloadAfpAsPdf({ jobId, number: number }) {
		return http.get(`jobs/preview/application-for-payment-${jobId}/${number}.pdf`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async getByStatusReadyForApplication() {
		return http.get('jobs/ready-for-application').catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async createApplications(fullIds) {
		return http.post('jobs/ready-for-application', fullIds).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async getFolders({ fromDate, toDate }) {
		return http.get(`jobs/applications-ready/${fromDate}/${toDate}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async getAfpFiles(dateStr) {
		return http.get(`jobs/applications-ready/${dateStr}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	async getZippedAfpPdfs(afpIds) {
		return http.post('jobs/applications-zipped-pdfs', afpIds).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async editLineItem(jobId, lineItemId, lineItem) {
		return http.put(`jobs/door/${jobId}/${lineItemId}`, lineItem).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async deleteApplications(jobId, howMany = 1) {
		return http.delete(`jobs/applications-ready/${jobId}/${howMany}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async postLineItemTryStageRevert(jobId, lineNumber) {
		return http.post(`jobs/lineitem-try-stage-revert/${jobId}/${lineNumber}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async undoCancellation(jobId, lineNumber) {
		return http.delete(`jobs/door/cancelled/${jobId}/${lineNumber}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async uploadDoorDocuments(jobId, lineItemId, document) {
		const formData = new FormData()
		formData.append('file', document)

		return http.post(`jobs/door/documents/${jobId}/${lineItemId}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			}
		}).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async uploadVariantDocuments(jobId, lineItemId, document) {
		const formData = new FormData()
		formData.append('file', document)

		return http.post(`jobs/lineitem/documents/${jobId}/${lineItemId}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			}
		}).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async getJobDocuments(jobId) {
		return http.get(`jobs/documents/${jobId}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async deleteSnaggingItem(jobId, snaggingId) {
		return http.delete(`jobs/snagging/${jobId}/${snaggingId}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async editSnaggingItem(jobId, snaggingId, snagging) {
		return http.put(`jobs/snagging/${jobId}/${snaggingId}`, snagging).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async createSnaggingItem(jobId, snagging) {
		return http.post(`jobs/snagging/${jobId}`, snagging).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}

	/*
	*
	* Initial User Permissions
	*
	*/
	async getPermissionList() {
		return http.get('/admin/permission-lists')
	}
	async getPermissionsAndRoles(preferred_username) {
		let result
		preferred_username= preferred_username && preferred_username.replace("@","!!")
		for(let i=0; i<10 ; i++){
			try{
				result=await http.get(`/admin/permission-lists/${preferred_username}`);
				if(result.status===200){
					console.log("getPermissionsAndRoles.retry got 200 after retries",i)
					return result
				}
				console.warn("getPermissionsAndRoles.retry got",i, result.status)
			}
			catch(e){
				console.warn("getPermissionsAndRoles.retry got",i, e)
			}
		}
		return result
	}
	/*
					*       ******     *       *   *****   *       *
				 * *      *      *   * *   * *     *     * *     *
				* * *     *      *   *  * *  *     *     *   *   *
			 *     *    *      *   *       *     *     *     * *
			*       *   ******     *       *   *****   *       *
	*/
	async getConfigLists() {
		return http.get('admin/config-lists').catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async editDoorType({ doorTypeKey, doorType }) {
		return http.patch(`admin/edit-doortype/${doorTypeKey}`, JSON.stringify(doorType),
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
		).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async removeDoorType(doorTypeKey) {
		return http.delete(`admin/remove-doortype/${doorTypeKey}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async editDiscountType({ discountTypeKey, discountType }) {
		return http.patch(`admin/edit-discounttype/${discountTypeKey}`, discountType).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async removeDiscountType(discountTypeKey) {
		return http.delete(`admin/remove-discounttype/${discountTypeKey}`).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async getVatRates() {
		return http.get('/admin/vat-rates').catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async addNewVatRate(newVatRate) {
		return http.post('/admin/vat-rates', newVatRate).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
	async addNewDoorType(newDoorTypeDescription) {
		return http.put(`admin/doortype`, JSON.stringify(newDoorTypeDescription), {
			headers: {
				'Content-Type': 'application/json'
			}
		}).catch(this.if401TryToNotLogoutOtherwiseRethrow)
	}
}

export default new ApiServices()
