import React, { useState, useEffect, useContext } from 'react'
import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { toast } from 'react-toastify'

import AddIcon from '@material-ui/icons/Add'

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"

import DoorsForm from "components/DoorsForm/DoorsForm"
import CheckDoorsForm from "components/DoorsForm/CheckDoorsForm"

import { LineItemContext } from 'context/LineItem/LineItemContext'

import {
	initialNettOrDiscountValidation,
	verifyNettOrDiscountForm
} from "utils/validations"

import ApiServices from "api/Api.js"

import styles from "assets/jss/material-dashboard-pro-react/views/doorsStyle"


const useStyles = makeStyles(styles)

function Doors(props) {

	const {
		handleConfirmDoor,
		newDoorValidation,
		enableCreateDoor,
		history,
		newJobId,
		newDoorFormErrors
	} = props

	const classes = useStyles()

	const { lineItems } = useContext(LineItemContext)

	const [netOrLess, setNetOrLess] = useState({})
	const [nettOrDiscountValidation, setNettOrDiscountValidation] = useState(initialNettOrDiscountValidation)

	const handleChangeCheckForm = (e) => {
		setNetOrLess(prev => {
			prev[e.target.name] = e.target.value
			return prev
		})
	}

	const handleAllDone = async (e) => {
		e.preventDefault()

		const { status, verifyResult } = verifyNettOrDiscountForm(netOrLess)

		setNettOrDiscountValidation(verifyResult)
		let isSuccess = true
		if (status && (lineItems.length > 0)) {
			for (var i = 0; i < lineItems.length; i++) {
				for (var j = 0; j < parseInt(lineItems[i].no_doors); j++) {
					let lineItemData = {
						stage: 0,
						costPrice: lineItems[i].costPrice,
						sellPrice: lineItems[i].sellPrice,
						customerRef: lineItems[i].customerDoorRefList[j],
						customDescription: lineItems[i].otherTypeDescription,
						door: {
							type: lineItems[i].type,
							width: lineItems[i].width,
							height: lineItems[i].height,
							colour: lineItems[i].colour
						}
					}
					const res = await ApiServices.addDoors({ jobId: newJobId, lineItem: lineItemData })
					isSuccess ? res.status === 201 ? true : false : false
				}
			}

			let nettOrDiscount = {}
			if (netOrLess.nett_less_discount === "nett") {
				nettOrDiscount = {
					"isNettOrDiscount": 0
				}
			} else if (netOrLess.nett_less_discount === "less") {
				if (netOrLess.discount !== "other") {
					nettOrDiscount = {
						"isNettOrDiscount": 1,
						"percentDiscount": parseFloat(netOrLess.discount)
					}
				}
				else if (netOrLess.discount === "other") {
					nettOrDiscount = {
						"isNettOrDiscount": 1,
						"other": netOrLess.other_discount
					}
				}
			}

			await ApiServices.putJobNettOrDiscount({ jobId: newJobId, nettOrDiscount: nettOrDiscount })

			if (isSuccess) {
				toast.success("Doors added.")
			}
			history.push(`/job/${newJobId}`)
		}
	}

	const cardBodyClasses = classNames({
		[classes.cardBodyPadding]: true,
		[classes.disableForm]: !enableCreateDoor
	})

	return (
		<GridItem xs={12} sm={12} md={12}>
			<Card>
				<CardHeader color="rose" text>
					<CardText color="rose">
						<h4 className={classes.cardTitle}>Doors</h4>
					</CardText>
				</CardHeader>
				<CardBody className={cardBodyClasses}>
					<GridContainer>
						{
							lineItems.map((lineItem, index) => (
								<DoorsForm
									lineItem={lineItem}
									key={index}
									index={index}
									newDoorValidation={newDoorValidation}
									newDoor={(index + 1) === lineItems.length ? true : false}
									newDoorFormErrors={newDoorFormErrors}
								/>
							))
						}
					</GridContainer>

					<GridContainer>
						<GridItem xs={12}>
							<Button
								color="info"
								size="lg"
								className={classes.addDoorBtn}
								onClick={handleConfirmDoor}
							>
								<AddIcon className={classes.icons} /> Confirm door
							</Button>
						</GridItem>
					</GridContainer>

					<CheckDoorsForm
						handleChangeCheckForm={handleChangeCheckForm}
						nettOrDiscountValidation={nettOrDiscountValidation}
					/>

					<GridContainer>
						<GridItem>
							<Button
								color="rose"
								size="lg"
								onClick={(e) => handleAllDone(e)}
							>
								All Done
							</Button>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		</GridItem>
	)
}

export default withRouter(Doors)
