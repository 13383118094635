import React, { useEffect, useState, useContext } from 'react'
import Datetime from "react-datetime"
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'

import { makeStyles } from "@material-ui/core/styles"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import FormLabel from "@material-ui/core/FormLabel"
import CustomInput from "components/CustomInput/CustomInput.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"
import FormHelperText from "@material-ui/core/FormHelperText"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { LineItemContext } from 'context/LineItem/LineItemContext'
import { checkUserPermission } from "utils/utils"
import { areaCodeList } from "variables/dropdown"

import styles from "assets/jss/material-dashboard-pro-react/views/jobStyle"

const useStyles = makeStyles(styles)

const InstallationSection = (props) => {

	const { jobData, handleChangeForm, jobFormValidation, jobFormErrors, handleChangeDocument } = props

	// const [job, setJob] = useState({})
	const [selectedDate, setSelectedDate] = React.useState(null);
	const [areaCode, setAreaCode] = useState("")

	const { userRole, permissions } = useContext(UserRoleContext)
	const { vatCodes } = useContext(LineItemContext)	

	const [disabled, setDisabled] = useState(false)

	const handleDateChange = (date) => {
		setSelectedDate(date)
		handleChangeForm({ target: { name: "installationDateWc", value: date } })
	};

	const handleChangeAreaCode = (e) => {
		setAreaCode(e.target.value)
		handleChangeForm(e)
		if (e.target.value === "EX") {
			handleChangeForm({target: {
				name: "vatCode",
				value: "T14"
			}})
		}
	}

	useEffect(() => {
		if (jobData.id !== null) {		
			setSelectedDate(jobData.installationDateWc)
		}
	}, [jobData.vatCode])

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "RaiseOrder", userRole))
	}, [userRole, permissions])

	const classes = useStyles()

	// if (!job || Object.keys(job).length === 0) {
	// 	return (<></>)
	// }

	return (
		<GridContainer>
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					Date 1*
				</FormLabel>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						format="dd/MM/yyyy"
						margin="normal"
						value={selectedDate}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
						inputVariant="outlined"
						fullWidth
						onChange={disabled ? () => { } : handleDateChange}
						style={{ marginTop: "4px" }}
						inputProps={{
							style: { fontSize: 20 },
							placeholder: "DD/MM/YYYY",
							name: "installationDateWc",
							disabled: disabled
						}}
						error={!jobFormValidation.installationDateWc}
					/>
				</MuiPickersUtilsProvider>
				{jobFormValidation.installationDateWc !== undefined ? (
					<FormHelperText id="installationDateWc" className={classes.errorLabel}>
						{jobFormErrors.installationDateWc}
					</FormHelperText>
				) : null}
			</GridItem>
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					No. Visits*
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.paddingTop
					}}
					inputProps={{
						type: "number",
						style: { fontSize: 20 },
						placeholder: "No. Visits",
						name: "numVisits",
						defaultValue: jobData.numVisits ?? "",
						onChange: (e) => handleChangeForm(e),
						readOnly: disabled
					}}
					error={!jobFormValidation.numVisits}
					helperText={jobFormErrors.numVisits ?? ""}
				/>
			</GridItem>

			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					Area Code*
				</FormLabel>
				<CustomDropdown
					id="ddAreaCode"
					menuList={areaCodeList}
					fullWidth
					name="areaCode"
					classeNames={classes.dropdown}
					defaultValue={jobData.areaCode ?? ""}
					handleDropdown={ handleChangeAreaCode }
					error={!jobFormValidation.areaCode}
					disabled={disabled}
					helperText={jobFormErrors.areaCode ?? ""}
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					VAT Code*
				</FormLabel>
				<CustomDropdown
					id="ddVatCode"
					menuList={ vatCodes }
					fullWidth
					name="vatCode"
					classeNames={ classes.dropdown }
					defaultValue={ jobData.vatCode ?? "T21" }
					handleDropdown={ handleChangeForm }
					disableItems={["T14"]}
					disabled={ (areaCode === "EX" && jobData.areaCode === "EX") ? true : areaCode === "" ? disabled || jobData.areaCode === "EX" : areaCode === "EX" }
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={4}>
				<FormLabel>
					Documents*
				</FormLabel>
				<CustomInput
					formControlProps={{
						fullWidth: true,
						className: classes.paddingTop
					}}
					inputProps={{
						type: "file",
						style: { fontSize: 20 },
						placeholder: "Documents",
						name: "documents",
						disabled: disabled,
						onChange: handleChangeDocument
					}}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default InstallationSection
