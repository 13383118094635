import axios from "axios"

function truncate(str,len=20){return str && str.length > len && str.substr(0, len) + "..." || "(none)"}

const axiosInstance = axios.create({baseURL: `${window.location.origin}/api/`})

axiosInstance.interceptors.request.use(
function(config) {
        const token = localStorage["accessToken"]
        if (token)
        {
            config.headers.common["Authorization"] = `Bearer ${token}`;
            console.log("request interceptor set bearer token:", truncate(token))
        }
        else{console.warn("request interceptor has no token")}

        return config
    },
error => error
);


export default axiosInstance
