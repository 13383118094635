import customerJobStyle from "assets/jss/material-dashboard-pro-react/views/customerJobStyle"
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js"
import { disableEvent, successColor } from "../../material-dashboard-pro-react"

const doorsFormStyle = {
	...customCheckboxRadioSwitch,
	...customerJobStyle,
	dropdown: {
		marginTop: "4px",
		maringBottom: "17px"
	},
	divider: {
		marginBottom: "20px"
	},
	disableEvent: {
		...disableEvent
	},
	deleteBtnWrapper: {
		display: "flex",
		alignItems: "center",
		"& button": {
			height: "fit-content"
		}
	},
	actionBtns: {
		display: "flex",
		alignItems: "center",
	},
	defaultBtnColor: {
		color: "#00acc1"
	},
	editBtnColor: {
		color: successColor[0]
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	editCard: {
		maxWidth: "600px",
		width: "100%",
		maxHeight: "90vh",
		overflowY: "auto",
		"& .MuiCardContent-root": {
			paddingBottom: "0"
		}
	},
	cardAction: {
		paddingLeft: "1rem"
	}
}

export default doorsFormStyle
