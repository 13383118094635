import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormLabel from "@material-ui/core/FormLabel"
import CustomInput from "components/CustomInput/CustomInput.js"
import IconButton from '@material-ui/core/IconButton'
import Button from "components/CustomButtons/Button.js"

import styles from "assets/jss/material-dashboard-pro-react/views/adminSettingsStyles"

const useStyles = makeStyles(styles)

export default function DiscountEditModal(props) {

	const classes = useStyles();

	const { open, toggleModal, discount, handleEditDiscountType } = props

	const [discountKey, setDiscountKey] = useState('')
	const [discountValue, setDiscountValue] = useState('')

	const handleSubmit = () => {
		handleEditDiscountType({ key: discountKey, value: discountValue })
	}

	useEffect(() => {
		!!discount && setDiscountKey(discount.key)
		!!discount && setDiscountValue(discount.value)
	}, [discount])

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={toggleModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card className={classes.editCard} variant="outlined">
					<CardHeader
						title="Edit Door"
						action={
							<IconButton aria-label="settings" onClick={toggleModal}>
								<CloseIcon />
							</IconButton>
						}
					/>
					<CardContent>
						<GridContainer>
							<GridItem xs={12}>
								<FormLabel>
									Amount*
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.inputField
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Amount",
										name: "key",
										value: discountKey,
										onChange: e => setDiscountKey(e.target.value)
									}}
								/>
							</GridItem>
							<GridItem xs={12}>
								<FormLabel>
									Description*
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.inputField
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Description",
										name: "value",
										value: discountValue,
										onChange: e => setDiscountValue(e.target.value)
									}}
								/>
							</GridItem>
						</GridContainer>
					</CardContent>
					<CardActions className={classes.cardAction}>
						<Button color="info" size="lg" onClick={handleSubmit}>
							Submit
						</Button>
					</CardActions>
				</Card>
			</Fade>
		</Modal>
	);
}
