import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { toast } from 'react-toastify'

import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import FormLabel from "@material-ui/core/FormLabel"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import MultiLineInput from "components/CustomInput/MultiLineInput.js"
import InstallationSection from "./InstallationSection"
import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup"
import Button from "components/CustomButtons/Button.js"
import DocumentItem from "components/DocumentItem"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { checkUserPermission } from "utils/utils"

import { attendancesRadios } from "variables/dropdown"
import ApiServices from 'api/Api.js'
import styles from "assets/jss/material-dashboard-pro-react/views/jobStyle.js"

import {
	initialNewJobFormValidation,
	verifyNewJobForm
} from "utils/validations.js"
import { errorMessage } from "utils/utils"

const useStyles = makeStyles(styles)

const JobSection = (props) => {

	const classes = useStyles()

	const { jobData } = props

	const { userRole, permissions } = useContext(UserRoleContext)

	const [jobFormValidation, setJobFormValidation] = useState(initialNewJobFormValidation)
	const [job, setJob] = useState()
	const [jobFormErrors, setJobFormErrors] = useState({})
	const [disabled, setDisabled] = useState(false)
	const [document, setDocument] = useState(null)
	const [jobDocuments, setJobDocuments] = useState([])

	const fetchJobDocuments = () => {
		if (!jobData.id) {
			return
		}
		ApiServices.getJobDocuments(jobData.id.value)
			.then(res => {
				setJobDocuments(res.data)
			})
	}

	const handleChangeForm = (e) => {
		setJob(prev => {
			prev[e.target.name] = e.target.value
			return prev
		})
	}

	const handleChangeDocument = (e) => {
		setDocument(e.target.files[0])
	}

	const handleUpdateJob = async () => {
		const { verifyStatus, verifyResult, errors } = verifyNewJobForm(
			{
				...job,
				address_lines: job.address_lines ?? job.siteAddress.lines,
				city: job.city ?? job.siteAddress.city,
				distribution: job.distribution,
				hoisting: job.hoisting,
				offload: job.offload,
				parkingOnSite: job.parkingOnSite,
				plant: job.plant,
				power: job.power,
				protection: job.protection
			})
		setJobFormErrors(errors)
		if (!verifyStatus) {
			toast.error(errorMessage(errors))
		}
		setJobFormValidation(verifyResult)
		if (verifyStatus) {
			let postData = {
				"customerRef": job.customerRef,
				"siteContact": job.siteContact,
				"vatCode": job.vatCode,
				"siteContactPhone": job.siteContactPhone,
				"siteEmail": job.siteEmail,
				"siteAddress": {
					"lines": job.address_lines ?? job.siteAddress.lines,
					"city": job.city ?? job.siteAddress.city,
					"postcode": {
						"value": job.postcode ?? job.siteAddress.postcode.value ?? ""
					}
				},
				"qsContact": job.qsContact,
				"qsContactPhone": job.qsContactPhone,
				"qsEmail": job.qsEmail,
				"installationDateWc": job.installationDateWc,
				"numVisits": parseInt(job.numVisits),
				"areaCode": job.areaCode,
				"attendanceAgreed": {
					"distribution": parseInt(job.distribution),
					"hoisting": parseInt(job.hoisting),
					"offload": parseInt(job.offload),
					"parkingOnSite": parseInt(job.parkingOnSite),
					"plant": parseInt(job.plant),
					"power": parseInt(job.power),
					"protection": parseInt(job.protection)
				}
			}
			console.log("updateJob",postData)
			ApiServices.updateJob({ jobId: job.id.value, job: postData })
				.then((res) => {
					if (!!document) {
						ApiServices.uploadDocuments({ jobId: res.data.id.value, document: document })
						.then((res) => {
							console.log(res)
							toast.success("Document updated.")
							fetchJobDocuments()
						})
						.catch(() => {
							toast.error("Document upload failed")
						})
						.finally(() => {
							setDocument(null)
							fetchJobDocuments()
						})
					}
					toast.success("Job updated.")
					setJob({
						...res.data,
						distribution: res.data.attendanceAgreed.distribution,
						hoisting: res.data.attendanceAgreed.hoisting,
						offload: res.data.attendanceAgreed.offload,
						parkingOnSite: res.data.attendanceAgreed.parkingOnSite,
						plant: res.data.attendanceAgreed.plant,
						power: res.data.attendanceAgreed.power,
						protection: res.data.attendanceAgreed.protection
					})
				})
				.catch(() => {
					toast.error("Update Failed")
				})
		}
	}

	useEffect(() => {
		if (!!jobData.attendanceAgreed) {
			setJob({
				...jobData,
				distribution: jobData.attendanceAgreed.distribution,
				hoisting: jobData.attendanceAgreed.hoisting,
				offload: jobData.attendanceAgreed.offload,
				parkingOnSite: jobData.attendanceAgreed.parkingOnSite,
				plant: jobData.attendanceAgreed.plant,
				power: jobData.attendanceAgreed.power,
				protection: jobData.attendanceAgreed.protection
			})
		} else {
			setJob({})
		}
		fetchJobDocuments()
	}, [jobData])

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "RaiseOrder", userRole))
	}, [userRole, permissions])


	if (!job || Object.keys(job).length === 0) {
		return (<></>)
	}

	return (
		<GridItem xs={12} sm={12} md={12}>
			<Card>
				<CardHeader color="rose" text>
					<CardText color="rose">
						<h4 className={classes.cardTitle}>Job {job.id.value}</h4>
					</CardText>
				</CardHeader>
				<CardBody className={classes.cardBodyPadding}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={4}>
							<FormLabel>
								Customer Reference*
							</FormLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true,
									className: classes.paddingTop
								}}
								inputProps={{
									type: "text",
									style: { fontSize: 20 },
									placeholder: "Customer Reference",
									name: "customerRef",
									onChange: (e) => handleChangeForm(e),
									defaultValue: job.customerRef ?? "",
									readOnly: disabled
								}}
							/>
						</GridItem>
						<GridItem xs={12}>
							<fieldset className={classes.fieldset}>
								<legend>Site Contact</legend>
								<GridItem xs={12} sm={6} md={4}>
									<FormLabel>
										Site Contact*
									</FormLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true,
											className: classes.paddingTop
										}}
										inputProps={{
											type: "text",
											style: { fontSize: 20 },
											placeholder: "Site Contact",
											name: "siteContact",
											defaultValue: job.siteContact ?? "",
											onChange: (e) => handleChangeForm(e),
											readOnly: disabled
										}}
										error={!jobFormValidation.siteContact}
										helperText={jobFormErrors.siteContact ?? ""}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={4}>
									<FormLabel>
										Site Contact Number*
									</FormLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true,
											className: classes.paddingTop
										}}
										inputProps={{
											type: "text",
											style: { fontSize: 20 },
											placeholder: "Site Contact Number",
											name: "siteContactPhone",
											defaultValue: job.siteContactPhone ?? "",
											onChange: (e) => handleChangeForm(e),
											readOnly: disabled
										}}
										error={!jobFormValidation.siteContactPhone}
										helperText={jobFormErrors.siteContactPhone ?? ""}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={4}>
									<FormLabel>
										Site Contact Email*
									</FormLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true,
											className: classes.paddingTop
										}}
										inputProps={{
											type: "text",
											style: { fontSize: 20 },
											placeholder: "Site Contact Email",
											name: "siteEmail",
											defaultValue: job.siteEmail ?? "",
											onChange: (e) => handleChangeForm(e),
											readOnly: disabled
										}}
										error={!jobFormValidation.siteEmail}
										helperText={jobFormErrors.siteEmail ?? ""}
									/>
								</GridItem>
							</fieldset>
						</GridItem>
						<GridItem xs={12}>
							<fieldset className={classes.fieldset}>
								<legend>QS Contact</legend>
								<GridItem xs={12} sm={6} md={4}>
									<FormLabel>
										QS Contact*
									</FormLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true,
											className: classes.paddingTop
										}}
										inputProps={{
											type: "text",
											style: { fontSize: 20 },
											placeholder: "QS Contact",
											name: "qsContact",
											defaultValue: job.qsContact ?? "",
											onChange: (e) => handleChangeForm(e),
											readOnly: disabled
										}}
										error={!jobFormValidation.qsContact}
										helperText={jobFormErrors.qsContact ?? ""}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={4}>
									<FormLabel>
										QS Contact Number*
									</FormLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true,
											className: classes.paddingTop
										}}
										inputProps={{
											type: "text",
											style: { fontSize: 20 },
											placeholder: "QS Contact Number",
											name: "qsContactPhone",
											defaultValue: job.qsContactPhone ?? "",
											onChange: (e) => handleChangeForm(e),
											readOnly: disabled
										}}
										error={!jobFormValidation.qsContactPhone}
										helperText={jobFormErrors.qsContactPhone ?? ""}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={4}>
									<FormLabel>
										QS Contact Email*
									</FormLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true,
											className: classes.paddingTop
										}}
										inputProps={{
											type: "text",
											style: { fontSize: 20 },
											placeholder: "QS Contact Email",
											name: "qsEmail",
											defaultValue: job.qsEmail ?? "",
											onChange: (e) => handleChangeForm(e),
											readOnly: disabled
										}}
										error={!jobFormValidation.qsEmail}
										helperText={jobFormErrors.qsEmail ?? ""}
									/>
								</GridItem>

							</fieldset>
						</GridItem>
						<GridItem xs={12}>
							<FormLabel>
								Site Address Lines*
							</FormLabel>
							<MultiLineInput
								formControlProps={{
									fullWidth: true,
									className: classes.paddingTop
								}}
								inputProps={{
									type: "text",
									style: { fontSize: 20 },
									placeholder: "Site Address Line",
									name: "address_lines",
									defaultValue: job.siteAddress ? job.siteAddress.lines ?? '' : '',
									onChange: (e) => handleChangeForm(e),
									readOnly: disabled
								}}
								rows={4}
								error={!jobFormValidation.address_lines}
								helperText={jobFormErrors.address_lines ?? ""}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={6}>
							<FormLabel>
								Site Town/City*
							</FormLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true,
									className: classes.paddingTop
								}}
								inputProps={{
									type: "text",
									style: { fontSize: 20 },
									placeholder: "Site Town/City",
									name: "city",
									defaultValue: job.siteAddress ? job.siteAddress.city ?? '' : '',
									onChange: (e) => handleChangeForm(e),
									readOnly: disabled
								}}
								error={!jobFormValidation.city}
								helperText={jobFormErrors.city ?? ""}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={6}>
							<FormLabel>
								Site Postcode
							</FormLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true,
									className: classes.paddingTop
								}}
								inputProps={{
									type: "text",
									style: { fontSize: 20 },
									placeholder: "Site Postcode",
									name: "postcode",
									defaultValue: job.siteAddress ? job.siteAddress.postcode.value ?? '' : '',
									onChange: (e) => handleChangeForm(e),
									readOnly: disabled
								}}
							/>
						</GridItem>
					</GridContainer>

					<Divider />
					<GridContainer className={classes.attendancesWrapper}>
						<GridItem xs={12}>
							<Typography variant="h5" gutterBottom className={classes.groupTitle}>
								Attendances Agreed
							</Typography>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} lg={4} xl={4}>
							<CustomRadioGroup
								title="Offload*"
								name="offload"
								radioItems={attendancesRadios}
								value={job.attendanceAgreed ? job.attendanceAgreed.offload + "" : ""}
								handleRadio={handleChangeForm}
								disabled={disabled}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} lg={4} xl={4}>
							<CustomRadioGroup
								title="Distribution*"
								name="distribution"
								radioItems={attendancesRadios}
								value={job.attendanceAgreed ? job.attendanceAgreed.distribution + "" : ""}
								handleRadio={handleChangeForm}
								disabled={disabled}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} lg={4} xl={4}>
							<CustomRadioGroup
								title="Plant*"
								name="plant"
								radioItems={attendancesRadios}
								value={job.attendanceAgreed ? job.attendanceAgreed.plant + "" : ""}
								handleRadio={handleChangeForm}
								disabled={disabled}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} lg={4} xl={4}>
							<CustomRadioGroup
								title="Protection*"
								name="protection"
								radioItems={attendancesRadios}
								value={job.attendanceAgreed ? job.attendanceAgreed.protection + "" : ""}
								handleRadio={handleChangeForm}
								disabled={disabled}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} lg={4} xl={4}>
							<CustomRadioGroup
								title="Hoisting*"
								name="hoisting"
								radioItems={attendancesRadios}
								value={job.attendanceAgreed ? job.attendanceAgreed.hoisting + "" : ""}
								handleRadio={handleChangeForm}
								disabled={disabled}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} lg={4} xl={4}>
							<CustomRadioGroup
								title="Power*"
								name="power"
								radioItems={attendancesRadios}
								value={job.attendanceAgreed ? job.attendanceAgreed.power + "" : ""}
								handleRadio={handleChangeForm}
								disabled={disabled}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} lg={4} xl={4}>
							<CustomRadioGroup
								title="Parking (on site)*"
								name="parkingOnSite"
								radioItems={attendancesRadios}
								value={job.attendanceAgreed ? job.attendanceAgreed.parkingOnSite + "" : ""}
								handleRadio={handleChangeForm}
								disabled={disabled}
							/>
						</GridItem>
					</GridContainer>

					<GridContainer>
						<GridItem xs={12}>
							<Typography variant="h5" gutterBottom className={classes.groupTitle}>
								Installation Dates(W/C)
							</Typography>
						</GridItem>
						<GridItem xs={12}>
							<InstallationSection
								jobData={job}
								handleChangeForm={handleChangeForm}
								jobFormValidation={jobFormValidation}
								jobFormErrors={jobFormErrors}
								handleChangeDocument={handleChangeDocument}
							/>
						</GridItem>
					</GridContainer>

					<GridContainer>
						<GridItem xs={12}>
							<Button
								onClick={handleUpdateJob}
								color="info"
								size="lg"
								disabled={disabled}
							>
								Update
							</Button>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<Typography variant="h5" gutterBottom className={classes.groupTitle}>
								Job Documents
							</Typography>
						</GridItem>
						{
							jobDocuments.map((item, index) => (
								<GridItem xs={12} sm={6} md={4} lg={3} key={index}>
									<DocumentItem document={ item } />
								</GridItem>
							))
						}
					</GridContainer>
				</CardBody>
			</Card>
		</GridItem>
	)
}

export default JobSection
