import React, { useEffect } from 'react'
import {useMsal} from "@azure/msal-react";

const LogOut = () => {

  console.log("logout requested")

  const { instance,accounts } = useMsal();

  useEffect(() => {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: "https://www.office.com"
    }

    instance.logoutRedirect(logoutRequest)
  }, [])

  return (
    <></>
  )
}

export default LogOut
