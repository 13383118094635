import {
	cardTitle,
	whiteColor,
} from "assets/jss/material-dashboard-pro-react.js"
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js"
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js"

const afpFoldersStyles = theme => ({
	...customCheckboxRadioSwitch,
	cardTitle: {
		...cardTitle,
		color: whiteColor
	},
	dropdown: {
		marginTop: "6px"
	},
	table: {
		"& th": {
			fontSize: "20px"
		},
		"& a": {
			fontSize: "20px",
			cursor: "pointer",
		},
		"& tbody": {
			'& tr:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			}
		}
	},
	dateField: {
		display: "flex",
		"& .MuiSvgIcon-root": {
			marginBottom: "auto",
			marginTop: "auto",
			marginRight: "0.5rem",
			color: "#00acc1"
		}
	},
	searchBtnWrapper: {
		display: "flex",
		"& button": {
			marginTop: "auto",
			marginBottom: "8px",
			marginLeft: "auto"
		}
	},
	badge: {
		"& .MuiBadge-badge": {
			fontSize: "20px",
			height: "unset",
			padding: "0px 6px 2px",
			position: "unset",
			top: "unset",
			transform: "unset"
		}
	},
	badgeLink: {
		display: "block"
	},
	totalCountBadge: {
		display: "flex",
		alignItems: "center",
		"& .MuiBadge-root": {
			marginTop: "25px",
			marginBottom: '8px'
		}
	},
	...buttonStyle
})

export default afpFoldersStyles
