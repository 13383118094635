import React, {useEffect} from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import {withRouter} from 'react-router-dom'
import { useHistory } from "react-router-dom"

import {useMsal} from "@azure/msal-react";

import {makeStyles} from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Box from "@material-ui/core/Box"
import IconButton from '@material-ui/core/IconButton'

import SearchIcon from '@material-ui/icons/Search'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import BallotIcon from '@material-ui/icons/Ballot'
import FolderIcon from '@material-ui/icons/Folder'
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop'
import Button from "components/CustomButtons/Button.js"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js"
import SettingsIcon from '@material-ui/icons/Settings'


const useStyles = makeStyles(styles);

function Navbar(props) {

  const classes = useStyles()
  const history = useHistory();
  const { color } = props
  const appBarClasses = cx({[" " + classes[color]]: color })
  const sidebarMinimize =classes.sidebarMinimize + " " + classes.linksWrapper

  const { instance, accounts} = useMsal();
  if(!accounts)console.warn("Navbar Has No Accounts.", "HasInstance",!!instance)

  const handleLogOut = () => {
    history.push('/logout')
  }

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>

        <Box className={sidebarMinimize} width="100%">
          <Button
            name="navJobList"
            color="info"
            className={classes.jobListBtn}
            onClick={() => props.history.push('/job-list')}
          >
            <FormatListBulletedIcon /> Job List
          </Button>
          <Button
            name="navSearch"
            color="info"
            onClick={() => props.history.push('/')}
            className={classes.jobListBtn}
          >
            <SearchIcon /> Search |  New+
          </Button>
          <Button
            name="navApplicationsEligible"
            color="info"
            className={classes.jobListBtn}
            onClick={() => props.history.push('/applications-eligible')}
          >
            <BallotIcon /> Ready To Apply
          </Button>
          <Button
            name="navApplicationsReady"
            color="info"
            className={classes.jobListBtn}
            onClick={() => props.history.push('/applications-ready')}
          >
            <FolderIcon /> Ready For Download
          </Button>
          <Button
            name="navReports"
            color="info"
            className={classes.jobListBtn}
            onClick={() => props.history.push('/reports')}
          >
            <LocalPrintshopIcon /> Reports
          </Button>
          <Button
            name="navAdmin"
            justIcon
            color="info"
            className={classes.jobListBtn}
            onClick={() => props.history.push('/admin/doors')}
          >
            <SettingsIcon />
          </Button>

          <Box ml="auto" display="flex" alignItems="center">
            <IconButton onClick={ handleLogOut }>
              <ExitToAppIcon />
            </IconButton>
            <span className={classes.username}>{ accounts[0].name||accounts[0].username }</span>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

Navbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  sidebarMinimize: PropTypes.func
};

export default withRouter(Navbar)
