import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormLabel from "@material-ui/core/FormLabel"
import CustomInput from "components/CustomInput/CustomInput.js"
import IconButton from '@material-ui/core/IconButton'
import Button from "components/CustomButtons/Button.js"
import FormHelperText from "@material-ui/core/FormHelperText"

import { verifyVatRateForm, initialAddVatRateFormValidation } from "utils/validations"
import { errorMessage } from "utils/utils"

import styles from "assets/jss/material-dashboard-pro-react/views/adminSettingsStyles"

const useStyles = makeStyles(styles)

const VatRateAddModal = (props) => {

	const classes = useStyles();

	const { open, closeModal, handleSubmit } = props

	const [vatRate, setVatRate] = useState({})
	const [formErrors, setFormErrors] = useState({})
	const [formValidation, setFormValidation] = useState(initialAddVatRateFormValidation)

	const [selectedDate, setSelectedDate] = useState(null);

	const handleChange = (e) => {
		setVatRate({
			...vatRate,
			[e.target.name]: e.target.value
		})
	}

	const handleDateChange = (date) => {
		setSelectedDate(date)
		handleChange({ target: { name: "startDate", value: date } })
	};

	const handleSubmitBtn = () => {
		const { verifyStatus, verifyResult, errors } = verifyVatRateForm(vatRate)

		setFormErrors(errors)
		if (!verifyStatus) {
			toast.error(errorMessage(errors))
		}
		setFormValidation(verifyResult)

		if (verifyStatus) {
			handleSubmit(vatRate)
		}
	}

	useEffect(() => {
		setSelectedDate(null)
		setVatRate({})
	}, [open])

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={closeModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card className={classes.editCard} variant="outlined">
					<CardHeader
						title="Add Vat Rate"
						action={
							<IconButton aria-label="settings" onClick={closeModal}>
								<CloseIcon />
							</IconButton>
						}
					/>
					<CardContent>
						<GridContainer>
							<GridItem xs={12}>
								<FormLabel>
									Code*
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.inputField
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Code",
										name: "code",
										onChange: handleChange
									}}
									error={!formValidation.code}
									helperText={formErrors.code ?? ""}
								/>
							</GridItem>
							<GridItem xs={12}>
								<FormLabel>
									Rate*
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.inputField
									}}
									inputProps={{
										type: "number",
										style: { fontSize: 20 },
										placeholder: "Rate",
										name: "percent",
										onChange: handleChange
									}}
									error={!formValidation.percent}
									helperText={formErrors.percent ?? ""}
								/>
							</GridItem>
							<GridItem xs={12}>
								<FormLabel>
									Start Date*
								</FormLabel>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="dd/MM/yyyy"
										margin="normal"
										value={selectedDate}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										inputVariant="outlined"
										fullWidth
										onChange={ handleDateChange }
										style={{ marginTop: "4px" }}
										inputProps={{
											style: { fontSize: 20 },
											placeholder: "DD/MM/YYYY",
											name: "startDate",
										}}
										error={!formValidation.startDate}
									/>
								</MuiPickersUtilsProvider>
								{formValidation.startDate !== undefined ? (
									<FormHelperText id="startDate" className={classes.errorLabel}>
										{ formErrors.startDate }
									</FormHelperText>
								) : null}
							</GridItem>
							<GridItem xs={12}>
								<FormLabel>
									Description*
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.inputField
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Description",
										name: "description",
										onChange: handleChange
									}}
									error={!formValidation.description}
									helperText={formErrors.description ?? ""}
								/>
							</GridItem>
						</GridContainer>
					</CardContent>
					<CardActions className={classes.cardAction}>
						<Button color="info" size="lg" onClick={handleSubmitBtn}>
							Submit
						</Button>
					</CardActions>
				</Card>
			</Fade>
		</Modal>
	);
}

export default VatRateAddModal
