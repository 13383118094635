import React, { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import 'date-fns'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import ImageSearchIcon from '@material-ui/icons/ImageSearch'

import Divider from '@material-ui/core/Divider'
import FormLabel from "@material-ui/core/FormLabel"
import IconButton from '@material-ui/core/IconButton'

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"
import CustomInput from "components/CustomInput/CustomInput.js"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { checkUserPermission } from "utils/utils"
import { LineItemContext } from 'context/LineItem/LineItemContext'

import styles from "assets/jss/material-dashboard-pro-react/components/doorsFormStyle"

const useStyles = makeStyles(styles)

function DoorLineItem(props) {

	const {
		lineItem,
		removeLineItem,
		creditNumber,
		handleEditAction,
		handleUploadFileBtn
	} = props

	const classes = useStyles()

	const { jobId } = useParams();

	const { userRole, permissions } = useContext(UserRoleContext)
	const { doorTypes } = useContext(LineItemContext)

	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "RaiseOrder", userRole))
	}, [userRole, permissions])


	return (
		<GridItem xs={12}>
			<GridContainer>
				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Door Type *
					</FormLabel>
					<CustomDropdown
						id="dropdown"
						menuList={doorTypes}
						fullWidth
						name="type"
						classeNames={classes.dropdown}
						defaultValue={lineItem.door.type}
						readOnly
					/>
				</GridItem>

				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Width *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Width",
							name: "width",
							readOnly: true,
							value: lineItem.door.width
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Height *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Height",
							name: "height",
							readOnly: true,
							value: lineItem.door.height
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Price(Cost) *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop,
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Price(Cost)",
							name: "costPrice",
							readOnly: true,
							value: lineItem.costPrice
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Price(Sell) *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "number",
							style: { fontSize: 20 },
							placeholder: "Price(Sell)",
							name: "sellPrice",
							readOnly: true,
							value: lineItem.sellPrice
						}}
					/>
				</GridItem>
				{
					lineItem.door.type === 24 ?
						<GridItem xs={12} sm={6} md={4}>
							<FormLabel>
								Other Type Description *
							</FormLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true,
									className: classes.paddingTop
								}}
								inputProps={{
									type: "text",
									style: { fontSize: 20 },
									placeholder: "Other Type Description",
									name: "otherTypeDescription",
									value: lineItem.customDescription
								}}
							/>
						</GridItem>
						: <></>
				}

				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Colour *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "text",
							style: { fontSize: 20 },
							placeholder: "Colour",
							name: "colour",
							readOnly: true,
							value: lineItem.door.colour
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={6} md={2}>
					<FormLabel>
						Customer Door Ref *
					</FormLabel>
					<CustomInput
						formControlProps={{
							fullWidth: true,
							className: classes.paddingTop
						}}
						inputProps={{
							type: "text",
							style: { fontSize: 20 },
							placeholder: "Customer Door Ref",
							name: "customerDoorRef",
							readOnly: true,
							value: lineItem.customerRef ?? ""
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={6} md={2} className={classes.actionBtns}>
					<IconButton className={classes.defaultBtnColor} disabled={disabled} onClick={() => handleUploadFileBtn(lineItem.id)}>
						<CloudUploadIcon />
					</IconButton>
					{
						!!lineItem.design &&
						<IconButton disabled={disabled} onClick={() => window.open(`/api/jobs/door/documents/${jobId}/${lineItem.id}`, "_blank")}>
							<ImageSearchIcon />
						</IconButton>
					}
					{
						!creditNumber && (
							<>
								<IconButton aria-label="Edit" className={classes.editBtnColor} onClick={() => handleEditAction(lineItem.id)} disabled={disabled}>
									<EditIcon />
								</IconButton>
								<IconButton color="secondary" aria-label="Delete" onClick={() => removeLineItem(lineItem.id)} disabled={disabled}>
									<DeleteIcon />
								</IconButton>
							</>
						)
					}
				</GridItem>
			</GridContainer>
			<Divider className={classes.divider} />
		</GridItem>
	)
}

export default DoorLineItem
