import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SweetAlert from "react-bootstrap-sweetalert"

import { makeStyles } from '@material-ui/core/styles';

import FormLabel from "@material-ui/core/FormLabel"

import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import CustomInput from "components/CustomInput/CustomInput.js"

import ApiServices from "api/Api.js"
import { checkUserPermission, errorMessage } from 'utils/utils'
import { UserRoleContext } from 'context/UserRole/UserRoleContext'
import { verifyChangeJobNumberForm, initialChangeJobNumberValidation } from "utils/validations"

import styles from "assets/jss/material-dashboard-pro-react/views/adminSettingsStyles"

const useStyles = makeStyles(styles)

const ChangeJobNumber = () => {

  const classes = useStyles()

  const { userRole, permissions } = useContext(UserRoleContext)
  const [disabled, setDisabled] = useState(false)

  const [jobIds, setJobIds] = useState({})
  const [formErrors, setFormErrors] = useState(initialChangeJobNumberValidation)
  const [errors, setErrors] = useState({})
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    setDisabled(!checkUserPermission(permissions, "AdminScreens", userRole))
  }, [userRole])

  const handleChangeJobNumber = () => {
    const { verifyStatus, verifyResult, errors } = verifyChangeJobNumberForm(jobIds)
    setFormErrors(verifyResult)
    setErrors(errors)
    if (!verifyStatus) {
      toast.error(errorMessage(errors))
    }

    if (verifyStatus) {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={`Please confirm you will change job number ${jobIds.jobId} to job number ${jobIds.newJobId} `}
          onConfirm={changeJobNumber}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.alertButton}
          cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
          confirmBtnText="Yes"
          cancelBtnText="Cancel"
          showCancel
        >
        </SweetAlert>
      )
    }
  }

  const changeJobNumber = () => {
    
  }

  return (
    <GridItem xs={12}>
      <fieldset className={classes.fieldset}>
        <legend>Change Job Number</legend>
        <GridItem xs={12} sm={6} md={4}>
          <FormLabel>
            Job Number*
          </FormLabel>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.paddingTop
            }}
            inputProps={{
              type: "number",
              style: { fontSize: 20 },
              placeholder: "Job Number",
              name: "jobId",
              onChange: (e) => setJobIds({ ...jobIds, jobId: e.target.value }),
              readOnly: disabled
            }}
            error={!formErrors.jobId}
            helperText={errors.jobId ?? ""}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <FormLabel>
            New Job Number*
          </FormLabel>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.paddingTop
            }}
            inputProps={{
              type: "number",
              style: { fontSize: 20 },
              placeholder: "New Job Number",
              name: "newJobId",
              onChange: (e) => setJobIds({ ...jobIds, newJobId: e.target.value }),
              readOnly: disabled
            }}
            error={!formErrors.newJobId}
            helperText={errors.newJobId ?? ""}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4} className={classes.actionBtnWrapper}>
          <Button
            size="lg"
            onClick={handleChangeJobNumber}
            fullWidth
            disabled={disabled}
          >
            Change Job Number
          </Button>
        </GridItem>
      </fieldset>
      {alert}
    </GridItem>
  )
}

export default ChangeJobNumber
